import { useQuery } from '@apollo/client';
import { CircularProgress, Typography } from '@mui/material';
import { Dataset, LineChart } from 'components/Charts/LineChart';
import { TIMELAPSE } from 'graphql/queries/Registration/Timelapse';
import { TIMELAPSE_AVG } from 'graphql/queries/Registration/TimelapseAvg';
import React from 'react';
import { useGlobalContext } from 'state/context/GlobalContext';
import { useUserPageContext } from 'state/context/UserPageContext';
import { parseDateFilter } from 'utils/DateHandler';

type TimelapseData = {
  value: number;
  date: Date;
};

export const TeamComparison: React.FC = () => {
  const { dateFilter, renderDateFilter } = useUserPageContext();
  const { currentUser, currentTeam, currentUnit } = useGlobalContext();

  const { data: userData } = useQuery(TIMELAPSE, {
    variables: {
      teamId: currentTeam?.id,
      userId: currentUser?.id,
      unit: currentUnit,
      dateFrom:
        dateFilter !== 'ALL_TIME'
          ? parseDateFilter(dateFilter).dateFrom
          : parseDateFilter('YEAR').dateFrom,
      dateTo:
        dateFilter !== 'ALL_TIME'
          ? parseDateFilter(dateFilter).dateTo
          : parseDateFilter('YEAR').dateTo,
    },
  });

  const { data: teamData } = useQuery(TIMELAPSE_AVG, {
    variables: {
      teamId: currentTeam?.id,
      unit: currentUnit,
      dateFrom:
        dateFilter !== 'ALL_TIME'
          ? parseDateFilter(dateFilter).dateFrom
          : parseDateFilter('YEAR').dateFrom,
      dateTo:
        dateFilter !== 'ALL_TIME'
          ? parseDateFilter(dateFilter).dateTo
          : parseDateFilter('YEAR').dateTo,
    },
    onError(err) {
      console.error(JSON.stringify(err, null, 2));
    },
  });

  const renderLabels = (data: TimelapseData[]) => {
    return data.map((entry) => {
      return new Date(entry.date).toLocaleDateString();
    });
  };

  const renderChartData = (
    userData: TimelapseData[],
    teamAverageData: TimelapseData[]
  ) => {
    const userDataset = {
      name:
        currentUser?.username ??
        (currentUser?.firstName
          ? `${currentUser?.firstName} ${currentUser?.lastName}`
          : currentUser?.email),
      data: userData.map((x) => x.value),
      color: '#ffe784',
    } as Dataset;

    const teamDataset = {
      name: `${currentTeam?.name} gj. snitt`,
      data: teamAverageData.map((x) => x.value),
      color: 'rgb(53, 162, 235)',
    } as Dataset;
    return [userDataset, teamDataset];
  };
  return (
    <div className='team-comparison'>
      <Typography variant='h4'>Utvikling</Typography>
      {/*       <Typography variant='subtitle1' className='subtitle'>
        utvikling
      </Typography> */}
      {userData && teamData ? (
        <div style={{ height: '100%' }}>
          <LineChart
            datasets={renderChartData(
              userData.timelapse,
              teamData.timelapseAvg
            )}
            labels={renderLabels(userData.timelapse)}
            title={`${currentUnit?.sumLabel ?? 'Verdi'} ${
              currentUnit?.name
            } - ${renderDateFilter()}`}
          />
        </div>
      ) : (
        <div className='loader'>
          <CircularProgress color='primary' />
        </div>
      )}
    </div>
  );
};
