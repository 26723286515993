import { gql } from '@apollo/client';

export const LEADERBOARD = gql`
  query leaderboard(
    $teamId: ID
    $unit: UnitInput
    $category: CategoryInput
    $dateFrom: Date
    $dateTo: Date
    $sortBy: String
    $limit: Int
  ) {
    leaderboard(
      teamId: $teamId
      unit: $unit
      category: $category
      dateFrom: $dateFrom
      dateTo: $dateTo
      sortBy: $sortBy
      limit: $limit
    ) {
      value
      quantity
      userdata {
        id
        username
        firstName
        lastName
        email
        avatar
        sound
        animation
        color
      }
    }
  }
`;

export const PIE_CHART = gql`
  query leaderboard(
    $teamId: ID
    $unit: UnitInput
    $category: CategoryInput
    $dateFrom: Date
    $dateTo: Date
    $sortBy: String
  ) {
    leaderboard(
      teamId: $teamId
      unit: $unit
      category: $category
      dateFrom: $dateFrom
      dateTo: $dateTo
      sortBy: $sortBy
    ) {
      value
      quantity
      userdata {
        _id
        color
      }
    }
  }
`;
