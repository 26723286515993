import { useQuery } from '@apollo/client';
import { PIE_CHART } from 'graphql/queries/Registration/Leaderboard';
import React, { useRef } from 'react';
import { Doughnut } from 'components/Charts/Doughnut';
import { useGlobalContext } from 'state/context/GlobalContext';
import { Paper, Typography } from '@mui/material';
import { capitalizeFirst } from 'utils/Stringparser';
import { Unit } from 'types/team';
import { WidgetProps } from 'components/LayoutGrid/LayoutGrid';

export const TotalProgress: React.FC<WidgetProps> = ({
  teamGoal,
  timeframe,
  competition,
  currentLayout,
  empty,
}) => {
  const { currentTeam } = useGlobalContext();
  let unit = (competition?.unit ?? currentLayout?.unit) as Unit;

  // const { data } = useQuery(TEAM_SUM, {
  //   variables: {
  //     teamId: currentTeam?.id,
  //     unit: unit,
  //     dateFrom: parseDateFilter(dateFilter).dateFrom,
  //     dateTo: parseDateFilter(dateFilter).dateTo,
  //   },
  //   pollInterval: 5000,
  // });

  const { data } = useQuery(PIE_CHART, {
    variables: {
      teamId: currentTeam?.id,
      unit: unit,
      category: competition?.category,
      ...timeframe,
      sortBy: competition?.measurement ?? 'SALES_AMOUNT',
    },
    skip: empty,
    pollInterval: 5000,
    onError(err) {
      console.error(err?.graphQLErrors);
    },
  });

  const ref = useRef<HTMLDivElement>(null);

  return (
    <Paper className='total_progress' ref={ref}>
      <Typography className='total_progress_title'>
        {capitalizeFirst(unit?.name ?? '')}
      </Typography>
      <Doughnut
        width={'100%'}
        height='100%'
        unit={unit ?? {}}
        //title={`${unit.name?.toLocaleUpperCase()}`}
        target={teamGoal}
        current={data}
      />
    </Paper>
  );
};
