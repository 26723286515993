import { gql } from '@apollo/client';

export const AUTHENTICATE = gql`
  query AuthenticateUser {
    authenticateUser {
      id
      firstName
      lastName
      email
      phone
      createdAt
      team
      teams
      username
      avatar
      sound
      animation
      admin
      color
      trophies {
        _id
        name
        competition
        type
        timestamp
      }
    }
  }
`;
