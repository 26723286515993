import { useMutation } from '@apollo/client';
import {
  Grid,
  Typography,
  FormControl,
  Select,
  MenuItem,
  FormControlLabel,
  Switch,
  Button,
  InputLabel,
  FormGroup,
  SelectChangeEvent,
  List,
  ListItem,
  IconButton,
  FilledInput,
  InputAdornment,
  ListItemAvatar,
  Avatar,
} from '@mui/material';
import React from 'react';
import { useGlobalContext } from 'state/context/GlobalContext';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckIcon from '@mui/icons-material/Check';
import { useNotificationContext } from 'state/context/NoticifationContext';

import { MemeGenerator } from 'components/Meme/generator';

import { ArraySettingsType } from 'types/team';

import { EDIT_SETTINGS } from 'graphql/mutations/Team/editSettings';

export const SettingsWidget: React.FC = () => {
  const { currentSettings, setSettings, currentTeam } = useGlobalContext();
  const notifier = useNotificationContext();

  const [editSettings] = useMutation(EDIT_SETTINGS);

  const [addImage, setAddImage] = React.useState<string>('');

  const handleSwitchState = (event: React.ChangeEvent<HTMLInputElement>) => {
    let name: string = event.target.name;

    setSettings({
      [name]: {
        ...currentSettings?.[name as keyof ArraySettingsType],
        ...{ aktiv: event.target.checked },
      },
    });
  };

  const handleLoopSettingsBackground = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    let name: string = event.target.name;
    setSettings({
      background: {
        ...currentSettings?.background,
        ...{ [name]: event.target.checked },
      },
    });
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleSpotifyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let name: string = event.target.name;
    setSettings({
      spotify: {
        ...currentSettings?.spotify,
        ...{ [name]: event.target.value },
      },
    });
  };

  const handleQuoteChange = (event: SelectChangeEvent<string>) => {
    let name: string = event.target.name;
    setSettings({
      quote: {
        ...currentSettings?.quote,
        ...{ [name]: event.target.value },
      },
    });
  };

  const handleBackgroundSettingsTextInput = (event: any) => {
    let name: string = event.target.name;

    setSettings({
      background: {
        ...currentSettings?.background,
        ...{ [name]: parseInt(event.target.value) },
      },
    });
  };

  const removeImageFromCollection = (name: string) => {
    let newArray = currentSettings?.background?.images?.filter(
      (item) => item !== name
    );
    setSettings({
      background: {
        ...currentSettings?.background,
        ...{ images: newArray },
      },
    });
  };

  const addImageFromCollection = () => {
    setAddImage('');
    setSettings({
      background: {
        ...currentSettings?.background,
        ...{
          images: [
            ...(currentSettings?.background?.images ?? []),
            ...[addImage],
          ],
        },
      },
    });
  };

  const handleMouseDown = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const SaveSettings = () => {
    return new Promise((resolve, reject) => {
      editSettings({
        variables: {
          settings: currentSettings,
          teamId: currentTeam?.id,
        },
      })
        .then((res) => {
          notifier.addAlert('settings', {
            type: 'success',
            title: 'Lagret',
            message: 'Innstillinger lagret!',
            onClose: () => notifier.hideAlert('settings'),
            autoHideDuration: 3000,
          });
          resolve(null);
        })
        .catch((error) => {
          console.error(JSON.stringify(error, null, 2));
          reject();
        });
    });
  };

  return (
    <Grid container className='admin-overview' spacing={3}>
      <Grid item xs={12}>
        <Typography variant='h4'>Rediger innstillinger</Typography>
      </Grid>
      <Grid item xs={10}>
        {/* <Divider variant='middle' sx={{ margin: 5, background: '#ebe784' }} /> */}
        {/* <FormControlLabel
              control={
                <Switch
                  checked={currentSettings?.spotify?.aktiv ?? false}
                  onChange={handleSwitchState}
                  name='spotify'
                />
              }
              label='Spotify'
            /> 
            {currentSettings?.spotify?.aktiv && (
              <Box
                component='form'
                sx={{
                  display: 'flex',
                  alignItems: 'flex-start',
                  flexDirection: 'column',
                  p: 1,
                  m: 1,

                  borderRadius: 1,
                }}
                noValidate
                autoComplete='off'
              >
                <TextField
                  id='outlined-basic'
                  label='Outlined'
                  variant='outlined'
                  name='token'
                  value={currentSettings?.spotify?.token}
                  onChange={handleSpotifyChange}
                />
                <TextField
                  id='outlined-basic'
                  label='Outlined'
                  variant='outlined'
                  name='playlist'
                  value={currentSettings?.spotify?.playlist}
                  onChange={handleSpotifyChange}
                />
              </Box>
            )}
            */}
        <FormGroup
          row
          sx={{ justifyContent: 'space-between', marginBottom: 5 }}
        >
          <FormControlLabel
            control={
              <Switch
                checked={currentSettings?.quote?.aktiv ?? false}
                onChange={handleSwitchState}
                name='quote'
              />
            }
            label='Daily Quote'
          />

          <Select
            labelId='demo-simple-select-label'
            id='demo-simple-select'
            value={currentSettings?.quote?.category}
            label='Kategori'
            name='category'
            disabled={!currentSettings?.quote?.aktiv}
            onChange={handleQuoteChange}
          >
            <MenuItem value='inspire'>Inspire</MenuItem>
            <MenuItem value='life'>Life</MenuItem>
            <MenuItem value='management'>Management</MenuItem>
            <MenuItem value='funny'>Funny</MenuItem>
            <MenuItem value='love'>Love</MenuItem>
            <MenuItem value='students'>Students</MenuItem>
            <MenuItem value='art'>Art</MenuItem>
            <MenuItem value='sports'>Sports</MenuItem>
          </Select>
        </FormGroup>
        <FormGroup>
          <FormGroup row sx={{ justifyContent: 'space-between' }}>
            <FormControlLabel
              control={
                <Switch
                  checked={currentSettings?.background?.aktiv ?? false}
                  onChange={handleSwitchState}
                  name='background'
                />
              }
              label='Bakgrunnsbilde'
            />
            <FormControl sx={{ m: 1, width: '25ch' }} variant='filled'>
              <InputLabel style={{ color: '#fff' }} htmlFor='add-image'>
                Url
              </InputLabel>
              <FilledInput
                sx={{ color: '#fff' }}
                id='add-image'
                color='secondary'
                type={'text'}
                value={addImage}
                onChange={(e) => setAddImage(e.target.value)}
                endAdornment={
                  <InputAdornment position='end'>
                    <IconButton
                      disabled={addImage === '' ? true : false}
                      aria-label='add image'
                      onClick={addImageFromCollection}
                      onMouseDown={handleMouseDown}
                      edge='end'
                    >
                      <CheckIcon
                        sx={
                          addImage === ''
                            ? { color: 'rgba(240,45,0,1)' }
                            : { color: 'rgba(0,255,0,1)' }
                        }
                      />
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
          </FormGroup>
          {currentSettings?.background?.aktiv && (
            <List style={{ marginBottom: 15 }}>
              {(currentSettings?.background?.images ?? []).map(
                (item, index) => {
                  return (
                    <ListItem
                      key={item}
                      secondaryAction={
                        <IconButton
                          edge='end'
                          aria-label='delete'
                          onClick={() => removeImageFromCollection(item)}
                        >
                          <DeleteIcon sx={{ color: 'rgba(240,45,0,1)' }} />
                        </IconButton>
                      }
                    >
                      <ListItemAvatar>
                        <Avatar
                          sx={{ width: 56, height: 56 }}
                          alt='backgroundImage'
                          src={item}
                          variant='square'
                        />
                      </ListItemAvatar>
                    </ListItem>
                  );
                }
              )}
            </List>
          )}
        </FormGroup>
        <FormGroup
          row
          sx={{ justifyContent: 'space-between', marginBottom: 5 }}
        >
          <FormControlLabel
            control={
              <Switch
                checked={currentSettings?.background?.loop ?? false}
                onChange={handleLoopSettingsBackground}
                name='loop'
              />
            }
            label='Loop bakgrunnsbilde'
          />
          <Select
            value={currentSettings?.background?.loopTime}
            name='loopTime'
            disabled={!currentSettings?.background?.loop}
            onChange={handleBackgroundSettingsTextInput}
          >
            <MenuItem value={1}>1min</MenuItem>
            <MenuItem value={5}>5min</MenuItem>
            <MenuItem value={15}>15min</MenuItem>
            <MenuItem value={30}>30min</MenuItem>
            <MenuItem value={60}>60min</MenuItem>
          </Select>
        </FormGroup>
        <FormGroup
          row
          sx={{ justifyContent: 'space-between', marginBottom: 5 }}
        >
          <FormControlLabel
            control={
              <Switch
                checked={currentSettings?.meme?.aktiv ?? false}
                onChange={handleSwitchState}
                name='meme'
              />
            }
            label='Meme'
          />
        </FormGroup>
        {currentSettings?.meme?.aktiv && (
          <React.Fragment>
            <Typography variant='h6'>Meme Generator</Typography>
            <MemeGenerator
              margin={5}
              topText={currentSettings.meme.topText}
              bottomText={currentSettings.meme.bottomText}
              image={currentSettings.meme.image}
            />
          </React.Fragment>
        )}

        <Button variant='contained' sx={{ m: 5 }} onClick={SaveSettings}>
          Lagre
        </Button>
      </Grid>
    </Grid>
  );
};
