import {
  Button,
  CircularProgress,
  FormLabel,
  IconButton,
  Modal,
  Paper,
  TextField,
  Typography,
  Select,
  MenuItem,
  SelectChangeEvent,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Close } from '@mui/icons-material';
import React, { useState, useRef } from 'react';
import { useUserPageContext } from '../../../state/context/UserPageContext';
import { useMutation } from '@apollo/client';
import { ADD_REGISTRATION } from '../../../graphql/mutations';
import { useGlobalContext } from '../../../state/context/GlobalContext';
import { useNotificationContext } from '../../../state/context/NoticifationContext';
import NumberFormat from 'react-number-format';
import { Category } from 'types/team';
import { useFrameContext } from 'state/context/FrameContext';

export const RegistrationModal: React.FC = () => {
  const [amount, setAmount] = useState<number>();
  const [category, setCategory] = useState<Category>();
  const [comment, setComment] = useState<string>('');
  const { registerModal, setRegisterModal, refreshData } = useUserPageContext();
  const { currentUser, currentTeam } = useGlobalContext();
  const [addRegistration, { loading, error }] = useMutation(ADD_REGISTRATION);

  const focusRef = useRef<HTMLInputElement>(null);

  const notifier = useNotificationContext();
  const { FrameStyle } = useFrameContext();

  const classes = useStyles();

  if (error) {
    console.error(error);
  }

  const handleKeyPress = () => {
    focusRef?.current?.focus();
  };

  const handleSave = () => {
    setAmount(0);
    setComment('');
    addRegistration({
      variables: {
        registration: {
          value: amount,
          userId: currentUser?.id,
          unit: registerModal,
          name: currentUser?.firstName + ' ' + currentUser?.lastName,
          comment: comment,
          category: category,
          teamId: currentTeam?.id,
        },
      },
    })
      .then(() => {
        setRegisterModal(null);
        refreshData();
        notifier.addAlert(currentUser?.id ?? '', {
          type: 'success',
          message: 'Registreringen ble gjennomført',
          onClose: () => notifier.hideAlert(currentUser?.id ?? ''),
          autoHideDuration: 3000,
        });
      })
      .catch((err) => {
        console.error(err);
      });
  };
  return (
    <Modal
      open={registerModal ? true : false}
      onBackdropClick={() => {
        setAmount(0);
        setRegisterModal(null);
      }}
      style={{ marginLeft: FrameStyle.paddingLeft }}
    >
      <Paper className='register-modal'>
        <IconButton
          className='close-button'
          onClick={() => {
            setAmount(0);
            setRegisterModal(null);
          }}
        >
          <Close />
        </IconButton>
        <Typography variant='h5'>{`Registrer ${registerModal?.name}`}</Typography>

        <FormLabel>{`Kommentar`}</FormLabel>
        <TextField
          autoFocus
          onKeyPress={(e: any) => (e.key === 'Enter' ? handleKeyPress() : null)}
          id='outlined-basic'
          variant='outlined'
          value={comment}
          onChange={(e) => {
            setComment(e.target.value);
          }}
        />

        {currentTeam?.categories && (
          <>
            <FormLabel>{`Kategori`}</FormLabel>
            <Select
              value={category?.id}
              labelId='demo-simple-select-label'
              id='demo-simple-select'
              onChange={(event: SelectChangeEvent<string>) => {
                setCategory(
                  currentTeam?.categories?.find(
                    (c) => c.id === event.target.value
                  )
                );
              }}
            >
              <MenuItem key='undefined' value={undefined}>
                ingen
              </MenuItem>
              {currentTeam?.categories.map((item, index) => {
                return (
                  <MenuItem key={item.id} value={item.id}>
                    {item.name}
                  </MenuItem>
                );
              })}
            </Select>
          </>
        )}
        <FormLabel>{`${registerModal?.sumLabel ?? 'Verdi'}`}</FormLabel>
        <NumberFormat
          value={amount}
          min={1}
          onValueChange={(values) => {
            const { value } = values;
            let val = value.replace(/^0+/, '');
            setAmount(parseInt(val));
          }}
          suffix={registerModal?.suffix}
          onKeyDown={(e: any) =>
            e.key === 'Enter' && amount ? handleSave() : null
          }
          customInput={TextField}
          thousandSeparator=' '
          inputRef={focusRef}
        />
        <Button
          variant='contained'
          disabled={!amount || amount <= 0}
          onClick={handleSave}
          className={classes.button}
          endIcon={loading && <CircularProgress size={'sm'} />}
        >
          Lagre
        </Button>
      </Paper>
    </Modal>
  );
};

const useStyles = makeStyles(() => ({
  modalRoot: {
    padding: 30,
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  modalTitle: {
    textAlign: 'center',
    marginBottom: 30,
  },
  input: {
    marginBottom: 30,
    width: 300,
  },
  button: {
    width: 300,
  },
  closeButton: {
    position: 'absolute',
    right: 10,
    top: 10,
  },
}));
