import { useQuery } from '@apollo/client';
import { Typography } from '@mui/material';
import { USER_SUM } from 'graphql/queries/Registration/UserSum';
import React, { useEffect, useState } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { useGlobalContext } from 'state/context/GlobalContext';
import { DateFilter, StatzVariant } from 'types/leaderboard';
import { Unit } from 'types/team';
import { parseDateFilter } from 'utils/DateHandler';
import NumberFormatter from 'utils/NumberFormatter';

interface GoalProgressProps {
  unit: Unit;
  variant: StatzVariant;
  datefilter: DateFilter;
}

export const GoalProgress: React.FC<GoalProgressProps> = ({
  unit,
  variant,
  datefilter,
}) => {
  const { currentTeam, currentUser } = useGlobalContext();
  const [currentValue, setCurrentValue] = useState<number>(0);
  const [currentGoal] = useState<number | undefined>(
    currentTeam?.goals?.find(
      (goal) =>
        goal.timeframe === datefilter &&
        goal.measurement === variant &&
        goal.unit.name === unit.name &&
        !goal.common &&
        goal.userId === currentUser?.id
    )?.value
  );

  const { data } = useQuery(USER_SUM, {
    variables: {
      teamId: currentTeam?.id,
      userId: currentUser?.id,
      unit: unit,
      ...parseDateFilter(datefilter),
    },
  });

  useEffect(() => {
    setCurrentValue(
      variant === 'SALES_AMOUNT'
        ? data?.userSum?.value ?? 0
        : data?.userSum?.quantity ?? 0
    );
  }, [data, variant]);

  return (
    <div className='goal_progress'>
      <Typography variant='h6'>{`${
        variant === 'SALES_AMOUNT'
          ? unit?.sumLabel ?? 'Verdi'
          : unit?.countLabel ?? 'Antall'
      }`}</Typography>

      <Typography className='current_value'>{`${NumberFormatter.format(
        currentValue
      )} ${
        variant === 'SALES_AMOUNT' ? unit?.suffix : unit?.name
      }`}</Typography>
      <Doughnut
        data={{
          datasets: [
            {
              data: [
                currentGoal && currentGoal > currentValue
                  ? currentGoal - currentValue
                  : 0,
                currentValue,
              ],
              backgroundColor: [
                'rgba(54, 162, 235, 0.2)',
                'rgba(0, 205, 0, .9)',
              ],
              borderColor: ['rgba(54, 162, 235, 0.2)', 'rgba(0, 205, 0, .9)'],
            },
          ],
        }}
      />
    </div>
  );
};
