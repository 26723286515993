import { useQuery } from '@apollo/client';
import { Paper, Typography } from '@mui/material';
import { Dataset, LineChart } from 'components/Charts/LineChart';
import { TIMELAPSE } from 'graphql/queries/Registration/Timelapse';
import React, { useEffect, useState } from 'react';
import { useGlobalContext } from 'state/context/GlobalContext';
import { renderFilterLabel } from 'utils/DateHandler';
import { capitalizeFirst } from 'utils/Stringparser';
import { WidgetProps } from 'components/LayoutGrid/LayoutGrid';

type TimelapseData = {
  value: number;
  date: Date;
};

export const TeamTrend: React.FC<WidgetProps> = ({
  dateFilter,
  competition,
  timeframe,
  currentLayout,
}) => {
  const [layout, setLayout] = useState(currentLayout);
  const [time, setTime] = useState(timeframe);
  const [comp, setComp] = useState(competition);
  const [dateFilt, setDateFilt] = useState(dateFilter);
  const { currentTeam } = useGlobalContext();

  let unit = competition?.unit ?? currentLayout?.unit;
  const { data: userData } = useQuery(TIMELAPSE, {
    variables: {
      teamId: currentTeam?.id,
      //userId: currentUser?.id,
      unit: unit,
      category: comp?.category,
      ...time,
    },
    skip: !layout,
  });

  useEffect(() => {
    setLayout(currentLayout);
    setTime(timeframe);
    setComp(competition);
    setDateFilt(dateFilt);
  }, [currentLayout, timeframe, competition, dateFilt]);

  const renderLabels = (data: TimelapseData[]) => {
    let next = 1;
    let week = 1;
    return data?.map((entry) => {
      let date = new Date(entry.date);
      switch (dateFilter) {
        case 'MONTH': {
          if (date.getDate() === next) {
            let label = `${week}. Uke`;
            week = week + 1;
            next = next + 7;
            return label;
          } else {
            return '';
          }
        }
        case 'WEEK': {
          return date.toLocaleString('NO-no', { weekday: 'short' });
        }
        default: {
          return '';
        }
      }
    });
  };

  return (
    <Paper className='team_trend'>
      <div className='team_trend_header'>
        <Typography className='team_trend_title'>Utvikling</Typography>
        <div className='team_trend_label_container'>
          <div className='label'>
            <span
              className='label_dot'
              style={{ backgroundColor: '#efc862' }}
            />
            <Typography className='label_text'>
              {capitalizeFirst(unit?.name ?? '')}
            </Typography>
          </div>

          <span className='label_dot' />
        </div>
        <div className='team_trend_period_container'>
          <div className='team_trend_period'>
            <Typography className='period_text'>
              {capitalizeFirst(renderFilterLabel(dateFilter))}
            </Typography>
          </div>
        </div>
      </div>
      <div className='team_trend_chart'>
        <LineChart
          datasets={[
            {
              data: userData?.timelapse?.map((x: TimelapseData) => x.value),
              color: '#ffe784',
            } as Dataset,
          ]}
          labels={renderLabels(userData?.timelapse)}
          hideLegends={true}
          disableAutoSkip={true}
          disableAspectRatio={true}
        />
      </div>
    </Paper>
  );
};
