import React, { useState, useRef, useEffect } from 'react';

interface MemeProps {
  topText?: string;
  bottomText?: string;
  image?: string;
}

export const MemeOfTheDay: React.FC<MemeProps> = ({
  topText,
  bottomText,
  image,
}) => {
  const [maxWidth, setMaxWidth] = useState<number>();

  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    let element = ref?.current;
    setMaxWidth((element?.offsetHeight ?? 0) * 1.5);
  }, [ref?.current?.offsetHeight]);
  return (
    <div
      ref={ref}
      className='meme'
      style={{
        height: '95%',
        width: '50%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <img
        src={image}
        alt='meme of the day'
        style={{
          objectFit: 'cover',
          height: '100%',
          width: '100%',
          maxWidth: maxWidth,
          borderRadius: 12,
        }}
      />
      <h2 className='top' style={{ maxWidth: maxWidth }}>
        {topText}
      </h2>
      <h2 className='bottom' style={{ maxWidth: maxWidth }}>
        {bottomText}
      </h2>
    </div>
  );
};
