import { gql } from '@apollo/client';

export const TEAM_REGISTRATIONS = gql`
  query Query(
    $teamId: ID
    $unit: UnitInput
    $category: CategoryInput
    $limit: Int
    $dateFrom: Date
    $dateTo: Date
  ) {
    teamRegistrations(
      teamId: $teamId
      unit: $unit
      category: $category
      limit: $limit
      dateFrom: $dateFrom
      dateTo: $dateTo
    ) {
      _id
      value
      unit {
        name
        id
        suffix
        sumLabel
        countLabel
        newRegistrationLabel
        icon
      }
      category {
        id
        name
        suffix
      }
      userdata {
        _id
        username
        avatar
        sound
        animation
        color
      }
      comment
      timestamp
    }
  }
`;
