import React, { useRef } from 'react';
import Typography from '@mui/material/Typography';
import axios from 'axios';
import { FormatQuote } from '@mui/icons-material';

type QuoteProps = {
  category: string | undefined | null;
};
export const DailyQuotes: React.FC<QuoteProps> = ({ category }) => {
  const [dailyQuote, setDailyQuote] = React.useState<any[]>();

  const ref = useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    axios({
      url: `https://quotes.rest/qod?category=${category}`,
      method: 'GET',
    }).then((response) => {
      setDailyQuote(response.data.contents.quotes);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      ref={ref}
      style={{
        overflow: 'hidden',
        height: '100%',
        width: '50%',
        padding: 10,
        color: '#fff',
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <div
        style={{
          height: '100%',
          width: '80%',
          display: 'flex',
          flexDirection: 'column',
          position: 'relative',
          paddingLeft: 10,
          paddingRight: 10,
        }}
      >
        <div style={{ display: 'flex' }}>
          <FormatQuote fontSize='medium' style={{ marginRight: 5 }} />
          <Typography
            variant='h4'
            style={{
              marginTop: 5,
              fontSize: 16,
              fontFamily: 'Patrick Hand SC',
              fontStyle: 'italic',
              textDecoration: 'underline',
            }}
          >
            {dailyQuote ? dailyQuote[0].title : 'Daily Quote'}
          </Typography>
        </div>
        <Typography
          style={{
            marginTop: 5,
            fontSize: 12,
            fontFamily: 'Patrick Hand SC',
            fontStyle: 'italic',
          }}
        >
          {dailyQuote ? dailyQuote[0].quote : ''}
        </Typography>
        <Typography
          style={{
            marginTop: 5,
            fontSize: 12,
            fontFamily: 'Patrick Hand SC',
            fontStyle: 'italic',
          }}
        >
          {dailyQuote ? `- ${dailyQuote[0].author}` : ''}
        </Typography>
      </div>

      {/* <CardActionArea sx={{ opacity: 0.9 }}>
        <CardMedia
          component='img'
          height='50%'
          image={dailyQuote ? dailyQuote[0].background : ''}
          alt='Daily Quote'
        />
        <CardContent>
          <Typography gutterBottom variant='h5' component='div'>
            {dailyQuote ? dailyQuote[0].title : ''}
          </Typography>
          <Typography variant='body2' color='text.secondary'>
            {dailyQuote ? dailyQuote[0].quote : ''}
          </Typography>
          <Typography variant='caption'>
            {dailyQuote ? dailyQuote[0].author : ''}
          </Typography>
        </CardContent>
      </CardActionArea> */}
    </div>
  );
};
