import { gql } from "@apollo/client";

export const TIMELAPSE = gql`
  query Timelapse(
    $teamId: ID
    $userId: ID
    $unit: UnitInput
    $dateFrom: Date
    $dateTo: Date
  ) {
    timelapse(
      teamId: $teamId
      userId: $userId
      unit: $unit
      dateFrom: $dateFrom
      dateTo: $dateTo
    ) {
      value
      date
    }
  }
`;
