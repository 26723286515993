import { Grid, Typography, Avatar, Tooltip, Box } from '@mui/material';
import React from 'react';
import { useGlobalContext } from 'state/context/GlobalContext';
import _ from 'lodash';
import { Trophy } from 'types/user';
import gold from 'assets/images/gold-medal.png';
import silver from 'assets/images/silver-medal.png';
import bronze from 'assets/images/bronze-medal.png';
import { renderDateString } from 'utils/DateHandler';

const AVAILABLE_TROPHIES: { [key: string]: string } = {
  'gold-medal': gold,
  'silver-medal': silver,
  'bronze-medal': bronze,
};

export const Trophies: React.FC = () => {
  const { currentUser } = useGlobalContext();
  const trophies = _.groupBy(
    [...(currentUser?.trophies ?? [])]?.sort(
      (a: Trophy, b: Trophy) =>
        new Date(a.timestamp ?? '').getMilliseconds() -
        new Date(b.timestamp ?? '').getMilliseconds()
    ),

    'name'
  );

  const renderPos = (key: string) => {
    if (key === 'gold-medal') return 1;
    else if (key === 'silver-medal') return 2;
    else return 3;
  };

  return (
    <div className='trophies_container'>
      <Grid
        container
        className='trophies_content'
        alignItems='center'
        justifyContent='center'
        spacing={3}
      >
        <Grid item xs={12}>
          <Typography variant='h4'>Dine utmerkelser</Typography>
        </Grid>
        <Grid item xs={12} className='avatar-container'>
          <Avatar
            alt='profile-picture'
            src={currentUser?.avatar ?? ''}
            className='avatar'
          />
        </Grid>
        <Grid item xs={12} flex={1}>
          <Grid
            container
            justifyContent={
              currentUser?.trophies?.length && currentUser?.trophies?.length < 6
                ? 'center'
                : 'flex-start'
            }
          >
            {(currentUser?.trophies?.length ?? 0) > 0 ? (
              Object.values(trophies)?.map((medal: Trophy[]) =>
                medal?.map((trophy: Trophy) => (
                  <Tooltip
                    key={trophy?._id}
                    title={`${renderPos(trophy?.name ?? '')}. plass ${
                      trophy?.competition
                    }`}
                  >
                    <Grid
                      item
                      xs={12}
                      md={2}
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                      }}
                    >
                      <Typography variant='h6'>
                        {trophy?.competition}
                      </Typography>
                      <img
                        src={AVAILABLE_TROPHIES[trophy?.name ?? '']}
                        style={{ width: '80%', height: 'auto' }}
                        alt={trophy._id ?? ''}
                      />
                      <Typography className='trophy_timestamp'>
                        {renderDateString(trophy?.timestamp as string)}
                      </Typography>
                    </Grid>
                  </Tooltip>
                ))
              )
            ) : (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '100%',
                  height: 400,
                }}
              >
                <Typography className='trophy_placeholder'>
                  Du har foreløpig ingen utmerkelser
                </Typography>
              </Box>
            )}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};
