import { Avatar, Snackbar, Typography } from '@mui/material';
import React, { useEffect, useMemo } from 'react';
import rocket from 'assets/sounds/notifications/rocket.wav';
import alarm from 'assets/sounds/notifications/alarm.wav';
import dance from 'assets/sounds/notifications/dance.mp3';
import happy from 'assets/sounds/notifications/happy.wav';
import cheer from 'assets/sounds/notifications/cheer.mp3';
import gong from 'assets/sounds/notifications/gong.wav';
import { Animator, Animation } from 'components/Animation/Animation';

export type Sound =
  | 'rocket'
  | 'alarm'
  | 'dance'
  | 'happy'
  | 'cheer'
  | 'gonggong'
  | 'default';

export interface NotificationProps {
  id: string;
  open?: boolean;
  title?: string;
  message?: string;
  avatar?: string | null;
  sound?: Sound;
  animation?: Animation;
}

export const AvailableSounds: { [key in Sound]: any } = {
  rocket: rocket,
  alarm: alarm,
  dance: dance,
  happy: happy,
  cheer: cheer,
  gonggong: gong,
  default: cheer,
};

export const Notification: React.FC<NotificationProps> = ({
  id,
  open,
  title,
  message,
  avatar,
  sound,
  animation,
}) => {
  let audio = useMemo(
    () => new Audio(AvailableSounds[sound ?? 'default']),
    [sound]
  );

  // start sound
  useEffect(() => {
    if (id) {
      audio.currentTime = 0;
      audio.play();
    }

    return () => audio.pause();
  }, [id, audio]);

  return (
    <div className='leaderboard_notification'>
      {/* <Avatar src={avatar ?? undefined} sx={{ width: 100, height: 100 }} /> */}
      <Animator animation={animation ?? 'default'} />
      <Snackbar
        open={open}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={undefined}
      >
        <div className='snackbar'>
          <Avatar src={avatar ?? undefined} sx={{ width: 60, height: 60 }} />
          <div className='notif_content'>
            <Typography variant='h6' className='notif_title'>
              {title}
            </Typography>
            <Typography className='notif_message'>{message}</Typography>
          </div>
        </div>
      </Snackbar>
    </div>
  );
};
