import {
  Paper,
  Typography,
  Box,
  Grid,
  Avatar,
  CircularProgress,
} from '@mui/material';
import { WidgetProps } from 'components/LayoutGrid/LayoutGrid';
import React, { useEffect } from 'react';
import silver from 'assets/images/silver-medal.png';
import gold from 'assets/images/gold-medal.png';
import bronze from 'assets/images/bronze-medal.png';
import { useQuery } from '@apollo/client';
import { LEADERBOARD } from 'graphql/queries/Registration/Leaderboard';
import { useGlobalContext } from 'state/context/GlobalContext';
import { displayName } from 'utils/Stringparser';

export const CompetitionPodium: React.FC<WidgetProps> = ({
  competition,
  timeframe,
  currentLayout,
  empty,
}) => {
  const { currentTeam } = useGlobalContext();
  const {
    loading,
    data: leaderboard,
    refetch: refreshLeaderboard,
  } = useQuery(LEADERBOARD, {
    variables: {
      teamId: currentTeam?.id,
      unit: competition?.unit,
      ...timeframe,
      sortBy: competition?.goal?.goalType ?? 'SALES_AMOUNT',
      limit: 3,
    },
    pollInterval: 5000,
    onError(err) {
      console.error(err?.graphQLErrors);
    },
    skip: empty,
  });

  useEffect(() => {
    //setPreviousFilter(dateFilter);
    refreshLeaderboard();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentLayout]);

  return (
    <Paper className='competition_podium'>
      <Typography className='competition_podium_title'>Topp 3</Typography>
      {!loading ? (
        <Grid container sx={{ height: '90%' }}>
          <Grid
            item
            xs={4}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
              opacity: leaderboard?.leaderboard?.length < 2 ? 0.5 : 1,
              /*  height: '100%', */
            }}
          >
            <Avatar src={leaderboard?.leaderboard?.[1]?.userdata?.avatar} />
            <img
              src={silver}
              alt='Silver'
              style={{ width: '35%', height: 'auto' }}
            />
            <Typography
              className='competition_podium_name'
              color='silver'
              fontSize={12}
            >
              {displayName(leaderboard?.leaderboard?.[1]?.userdata ?? {})}
            </Typography>
          </Grid>
          <Grid
            item
            xs={4}
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              flexDirection: 'column',
              opacity: leaderboard?.leaderboard?.length < 1 ? 0.5 : 1,
            }}
          >
            <Avatar
              src={leaderboard?.leaderboard?.[0]?.userdata?.avatar}
              sx={{ width: 56, height: 56 }}
            />
            <img
              src={gold}
              alt='Gold'
              style={{ width: '40%', height: 'auto' }}
            />
            <Typography
              className='competition_podium_name'
              color='gold'
              fontWeight={'bold'}
              fontSize={14}
            >
              {displayName(leaderboard?.leaderboard?.[0]?.userdata ?? {})}
            </Typography>
          </Grid>
          <Grid
            item
            xs={4}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
              opacity: leaderboard?.leaderboard?.length < 3 ? 0.5 : 1,
            }}
          >
            <Avatar src={leaderboard?.leaderboard?.[2]?.userdata?.avatar} />
            <img
              src={bronze}
              alt='Silver'
              style={{
                width: '35%',
                height: 'auto',
              }}
            />
            <Typography
              className='competition_podium_name'
              sx={{ color: '#CD7F32' }}
              fontSize={12}
            >
              {displayName(leaderboard?.leaderboard?.[2]?.userdata ?? {})}
            </Typography>
          </Grid>
        </Grid>
      ) : (
        <Box
          display='flex'
          justifyContent='center'
          alignItems='center'
          height='100%'
        >
          <CircularProgress />
        </Box>
      )}
    </Paper>
  );
};
