import { gql } from '@apollo/client';

export const TEAM_MEMBERS = gql`
  query TeamMembers($teamId: ID) {
    teamMembers(teamId: $teamId) {
      id
      username
      firstName
      lastName
      email
      phone
      createdAt
      team
      avatar
      sound
      animation
      trophies {
        _id
        name
        competition
        type
        timestamp
      }
    }
  }
`;
