import { gql } from '@apollo/client';

export const REGISTRATION_SUBSCRIPTION = gql`
  subscription Subscription($teamId: ID) {
    latestRegistrations(teamId: $teamId) {
      id
      value
      category {
        id
        name
        suffix
      }
      unit {
        suffix
        name
        id
        sumLabel
        countLabel
        newRegistrationLabel
        icon
      }
      timestamp
      comment
      userdata {
        username
        avatar
      }
    }
  }
`;
