import axios from 'axios';
import React, { useState } from 'react';
import { TextField, IconButton, Grid } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import { useGlobalContext } from 'state/context/GlobalContext';

interface MemeProps {
  topText?: string;
  bottomText?: string;
  image?: string;
  margin: number;
}

interface MemeImage {
  id: string;
  name: string;
  url: string;
}

export const MemeGenerator: React.FC<MemeProps> = ({
  topText,
  bottomText,
  image,
  margin,
}) => {
  const [allMemes, setAllMemes] = useState<MemeImage[]>([]);

  const { setSettings, currentSettings } = useGlobalContext();

  React.useEffect(() => {
    if (!image) {
      Fetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const Fetch = () => {
    axios({
      url: 'https://api.imgflip.com/get_memes',
      method: 'GET',
    }).then((response) => {
      setAllMemes(response.data.data.memes);
      setSettings({
        meme: {
          ...currentSettings?.meme,
          ...{
            image:
              response?.data?.data?.memes[
                Math.floor(Math.random() * response.data.data.memes.length)
              ]?.url,
          },
        },
      });
    });
  };

  const handleMemeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let name: string = event.target.name;
    setSettings({
      meme: {
        ...currentSettings?.meme,
        ...{ [name]: event.target.value },
      },
    });
  };

  const changeImage = () => {
    if (allMemes?.length < 1) {
      Fetch();
    } else {
      const rand = allMemes[Math.floor(Math.random() * allMemes.length)]?.url;
      setSettings({
        meme: {
          ...currentSettings?.meme,
          ...{
            image: rand,
          },
        },
      });
    }
  };

  return (
    <Grid container justifyContent={'center'} sx={{ margin: margin }}>
      <Grid item xs={12}>
        <Grid container justifyContent={'center'} spacing={2}>
          <Grid item xs={12} md={5}>
            <TextField
              placeholder='Topptekst'
              type='text'
              value={currentSettings?.meme?.topText}
              name='topText'
              onChange={handleMemeChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={5}>
            <TextField
              placeholder='Bunntekst'
              type='text'
              value={currentSettings?.meme?.bottomText}
              name='bottomText'
              onChange={handleMemeChange}
              fullWidth
            />
          </Grid>

          <Grid item xs={10}>
            <div className='meme'>
              <IconButton
                className='refresh-button'
                onClick={() => changeImage()}>
                <RefreshIcon />
              </IconButton>
              <img src={image} alt='meme' />
              <h2 className='top'>{topText}</h2>
              <h2 className='bottom'>{bottomText}</h2>
            </div>
          </Grid>
          <Grid item xs={10}></Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
