import { gql } from "@apollo/client";

export const USER_RANK = gql`
  query UserRank(
    $teamId: ID
    $userId: ID
    $unit: UnitInput
    $dateFrom: Date
    $dateTo: Date
  ) {
    userRank(
      teamId: $teamId
      userId: $userId
      unit: $unit
      dateFrom: $dateFrom
      dateTo: $dateTo
    )
  }
`;
