import { gql } from '@apollo/client';

export const EDIT_GOAL = gql`
  mutation EditGoal($teamId: ID, $goal: GoalInput) {
    editGoal(teamId: $teamId, goal: $goal) {
      timeframe
      common
      value
      measurement
      userId
      unit {
        id
        name
        suffix
        sumLabel
        countLabel
        newRegistrationLabel
        icon
      }
    }
  }
`;
