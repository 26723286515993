import { useQuery } from '@apollo/client';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { COMPETITIONS } from '../../graphql/queries/Competition/Competitions';
import { DateFilter } from '../../types/leaderboard';
import { Competition, Unit } from '../../types/team';
import { createGenericContext } from './createGenericContext';
import { useGlobalContext } from './GlobalContext';
import { parseDateFilter } from '../../utils/DateHandler';
import { USER_RANK } from 'graphql/queries/Registration/UserRank';
import { USER_SUM } from 'graphql/queries/Registration/UserSum';

interface UserPageContextType {
  registerModal: Unit | null | undefined;
  rank: number | null | undefined;
  total: number;
  currentCompetition: Competition | null | undefined;
  competitions: Competition[] | null | undefined;
  dateFilter: DateFilter | null | undefined;
  loadingData: boolean;
  currentUnit: Unit | null | undefined;
  setRegisterModal: (unit: Unit | null) => void;
  setCurrentCompetition: (comp: Competition) => void;
  setDateFilter: (filter: DateFilter) => void;
  refreshData: () => void;
  renderDateFilter: () => string;
  setCurrentUnit: (unit: Unit) => void;
}

export const [useUserPageContext, BareUserPageContextProvider] =
  createGenericContext<UserPageContextType>();

export const UserPageContextProvider: React.FC = ({ children }) => {
  const { currentTeam, currentUser, currentUnit, setCurrentUnit } =
    useGlobalContext();

  const [registerModal, setRegisterModal] = useState<Unit | null>();
  const [currentCompetition, setCurrentCompetition] = useState<Competition>();
  const [dateFilter, setDateFilter] = useState<DateFilter>(
    (localStorage.getItem('dateFilter') as DateFilter) ?? 'WEEK'
  );

  const { data, refetch: refetchCompetitions } = useQuery(COMPETITIONS, {
    variables: {
      teamId: currentTeam?.id,
    },
    onError(err) {
      console.error(JSON.stringify(err, null, 2));
    },
    onCompleted(data) {
      setCurrentCompetition(data?.competitions ? data.competitions[0] : null);
    },
  });

  const {
    loading: rankLoading,
    data: rankData,
    refetch: refetchRank,
  } = useQuery(USER_RANK, {
    variables: {
      teamId: currentTeam?.id,
      userId: currentUser?.id,
      unit: currentUnit,
      ...parseDateFilter(dateFilter),
    },
    onError(error) {
      console.error(JSON.stringify(error, null, 2));
    },
  });

  const {
    loading: sumLoading,
    data: sumData,
    refetch: refetchSum,
  } = useQuery(USER_SUM, {
    variables: {
      teamId: currentTeam?.id,
      userId: currentUser?.id,
      unit: currentUnit,
      ...parseDateFilter(dateFilter),
    },
  });

  const handleSetRegisterModal = useCallback(
    (unit: Unit | null | undefined) => {
      setRegisterModal(unit);
    },
    []
  );

  const handleRefreshData = useCallback(() => {
    refetchRank();
    refetchCompetitions();
    refetchSum();
  }, [refetchCompetitions, refetchRank, refetchSum]);

  const handleSetCurrentCompetition = useCallback((comp: Competition) => {
    setCurrentCompetition(comp);
  }, []);

  const handleSetDateFilter = useCallback((filter: DateFilter) => {
    setDateFilter(filter);
    localStorage.setItem('dateFilter', filter);
  }, []);

  const renderDateFilter = useCallback(() => {
    switch (dateFilter) {
      case 'MONTH':
        return 'Denne måned';
      case 'WEEK':
        return 'Denne uken';
      case 'YEAR':
        return 'Inneværende år';
      case 'LAST_WEEK':
        return 'Forrige uke';
      case 'LAST_MONTH':
        return 'Forrige måned';
      case 'ALL_TIME':
        return 'Alle tider';
      default:
        return 'Alle tider';
    }
  }, [dateFilter]);

  useEffect(() => {
    handleRefreshData();
  }, [dateFilter, handleRefreshData]);

  const value: UserPageContextType = useMemo(
    () => ({
      registerModal: registerModal,
      rank: rankData?.userRank,
      total: sumData?.userSum?.value ?? 0,
      currentCompetition: currentCompetition,
      competitions: data?.competitions,
      dateFilter: dateFilter,
      loadingData: sumLoading || rankLoading,
      currentUnit: currentUnit,
      setRegisterModal: handleSetRegisterModal,
      refreshData: handleRefreshData,
      setCurrentCompetition: handleSetCurrentCompetition,
      setDateFilter: handleSetDateFilter,
      renderDateFilter: renderDateFilter,
      setCurrentUnit: setCurrentUnit,
    }),
    [
      currentCompetition,
      currentUnit,
      data?.competitions,
      dateFilter,
      handleRefreshData,
      handleSetCurrentCompetition,
      setCurrentUnit,
      handleSetDateFilter,
      handleSetRegisterModal,
      rankData?.userRank,
      rankLoading,
      registerModal,
      renderDateFilter,
      sumData?.userSum?.value,
      sumLoading,
    ]
  );

  return (
    <BareUserPageContextProvider value={value}>
      {children}
    </BareUserPageContextProvider>
  );
};
