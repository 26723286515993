import React from 'react';
import { SideBar } from 'components/layout/Sidebar/SideBar';

import { useFrameContext } from 'state/context/FrameContext';
import useMediaQuery from '@mui/material/useMediaQuery';

type Props = {
  children: JSX.Element;
};

const Frame: React.FC<Props> = ({ children }: Props) => {
  const { FrameStyle } = useFrameContext();

  // MEDIA QUERIES
  const device_sm = useMediaQuery('(min-width:1000px)');

  return (
    <>
      <SideBar
        drawerWidth={!device_sm ? 0 : FrameStyle.paddingLeft ?? 180}
        navbarHeight={FrameStyle.paddingTop ?? 0}
        hideSideBar={device_sm}
      />
      <div
        style={{
          /*   paddingLeft: !device_sm ? 0 : FrameStyle.paddingLeft ?? 180,
          paddingTop: FrameStyle.paddingTop ?? 0, */
          flexGrow: 1,
        }}
      >
        {children}
      </div>
    </>
  );
};

export default Frame;
