import { useQuery } from '@apollo/client';
import { ContentCopy } from '@mui/icons-material';
import {
  Grid,
  List,
  ListItem,
  Typography,
  Avatar,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  tableCellClasses,
  Tooltip,
  IconButton,
  Pagination,
} from '@mui/material';
import { TEAM_MEMBERS } from 'graphql/queries/Team/TeamMembers';
import React, { useState } from 'react';
import { useGlobalContext } from 'state/context/GlobalContext';
import { useNotificationContext } from 'state/context/NoticifationContext';
import { User } from 'types/user';

export const MyTeam: React.FC = () => {
  const [startIndex, setStartIndex] = useState<number>(0);
  const { currentTeam } = useGlobalContext();
  const notifier = useNotificationContext();
  const { data } = useQuery(TEAM_MEMBERS, {
    variables: {
      teamId: currentTeam?.id,
    },
  });

  const copyToClipboard = () => {
    navigator.clipboard
      .writeText(`https://leaderboard.statz.no/signup?team=${currentTeam?.id}`)
      .then(() => {
        notifier.addAlert('copy-success', {
          type: 'success',
          message: 'Linken er kopiert til utklippstavlen',
          onClose: () => notifier.hideAlert('copy-success'),
          autoHideDuration: 3000,
        });
      })
      .catch((err) => {
        notifier.addAlert('copy-error', {
          type: 'success',
          title: 'Noe gikk galt',
          message: 'Kunne ikke kopiere linken, prøv igjen',
          onClose: () => notifier.hideAlert('copy-error'),
          autoHideDuration: 3000,
        });
      });
  };

  return (
    <Grid
      container
      spacing={3}
      className='my-team-container'
      justifyContent={'center'}
    >
      <Grid item xs={12}>
        <Typography variant='h4'>Mitt team</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant='subtitle1'>Påmeldingslink</Typography>
        <div className='register-link-container'>
          <Typography
            variant='body1'
            className='register-link'
          >{`leaderboard.statz.no/signup?team=${currentTeam?.id}`}</Typography>
          <Tooltip title='Kopier'>
            <IconButton color='primary' onClick={copyToClipboard}>
              <ContentCopy />
            </IconButton>
          </Tooltip>
        </div>
      </Grid>
      <Grid item xs={12}>
        {data?.teamMembers
          ?.slice(startIndex, startIndex + 5)
          .map((teamMember: User) => {
            return (
              <List key={teamMember?.id}>
                <ListItem className='member-body'>
                  <div className='member-container'>
                    <div className='member-profile'>
                      <div className='user--bg'>
                        <Avatar alt='avatar' src={teamMember.avatar ?? ''} />
                      </div>
                    </div>

                    <div className='member-div'>
                      <Table
                        className='member-info'
                        size='small'
                        padding='none'
                        sx={{
                          [`& .${tableCellClasses.root}`]: {
                            borderBottom: 'none',
                          },
                        }}
                      >
                        <TableHead>
                          <TableRow>
                            <TableCell align='left' width='25%'>
                              Brukernavn
                            </TableCell>
                            <TableCell align='left' width='25%'>
                              Fornavn
                            </TableCell>
                            <TableCell align='left' width='25%'>
                              Etternavn
                            </TableCell>
                            <TableCell align='left' width='25%'>
                              Epost
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow>
                            <TableCell align='left' width='25%'>
                              {teamMember.username ?? '-'}
                            </TableCell>
                            <TableCell align='left' width='25%'>
                              {teamMember.firstName ?? '-'}
                            </TableCell>
                            <TableCell align='left' width='25%'>
                              {teamMember.lastName ?? '-'}
                            </TableCell>
                            <TableCell align='left' width='25%'>
                              {teamMember.email}
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                      {/*  <div className="member-info">
                      <Typography variant="h6">
                        {displayName(teamMember)}
                      </Typography>
                    </div> */}
                    </div>
                  </div>
                </ListItem>
              </List>
            );
          })}
      </Grid>
      <Grid item>
        <Pagination
          color='secondary'
          count={Math.ceil((data?.teamMembers.length ?? 0) / 5)}
          onChange={(e, value) => setStartIndex((value - 1) * 5)}
        />
      </Grid>
    </Grid>
  );
};
