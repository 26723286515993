import { gql } from "@apollo/client";

export const TIMELAPSE_AVG = gql`
  query TimelapseAvg(
    $teamId: ID
    $unit: UnitInput
    $dateFrom: Date
    $dateTo: Date
  ) {
    timelapseAvg(
      teamId: $teamId
      unit: $unit
      dateFrom: $dateFrom
      dateTo: $dateTo
    ) {
      date
      value
    }
  }
`;
