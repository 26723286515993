import React from 'react';

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut as ReactDoughnut } from 'react-chartjs-2';
import NumberFormatter from 'utils/NumberFormatter';
import { Unit } from 'types/team';
import { Typography } from '@mui/material';
const _ = require('lodash');

ChartJS.register(ArcElement, Tooltip, Legend);

type DoughnutOptions = {
  unit: Unit;
  margin?: string | number;
  height?: string | number;
  width?: string | number;
  labels?: string[];
  target: number | null | undefined;
  current: any | undefined;
  title?: string | null | undefined;
  subtitle?: string | null | undefined;
};

export const Doughnut: React.FC<DoughnutOptions> = ({
  margin,
  height,
  width,
  labels,
  target,
  current,
  title,
  subtitle,
  unit,
}) => {
  let data: number[] = [];
  let userColors: string[] = [];

  current?.leaderboard.forEach((item: any) => {
    data.push(item.value);
    userColors.push(item?.userdata?.color ? item?.userdata?.color : '#ffe784');
  });

  const sum = _.sum(data);

  const left = (target ?? 0) - sum < 0 ? 0 : (target ?? 0) - sum;

  userColors.unshift('rgba(255, 255, 255, 0.5)');

  let newArray = data && data.length > 0 ? [left, ...data] : [1];

  return (
    <div
      className='doughnut_container'
      style={{ height: height, width: width }}
    >
      <ReactDoughnut
        options={{
          cutout: 50,
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            title: {
              display: title ? true : false,
              text: title ?? '',
              font: { size: 20 },
            },
            subtitle: {
              display: subtitle ? true : false,
              text: subtitle ?? '',
            },
          },
        }}
        data={{
          labels: labels,
          datasets: [
            {
              data: newArray,
              backgroundColor: userColors,
              borderWidth: 1,
              borderColor: userColors,
            },
          ],
        }}
      />
      <Typography className='centered-text'>
        {current ? `${NumberFormatter.format(sum)}` : ''}
        {target && (
          <Typography className='total'>
            {`/ ${NumberFormatter.format(target ?? 0)}`}
          </Typography>
        )}
      </Typography>
    </div>
  );
};
