import { gql } from '@apollo/client';

export const REGISTRATION = gql`
  query registration($id: ID) {
    registration(id: $id) {
      id
      value
      unit {
        name
        id
        suffix
        sumLabel
        countLabel
        newRegistrationLabel
        icon
      }
      category {
        id
        name
        suffix
      }
      userdata {
        _id
        username
        avatar
        sound
        animation
        color
      }
      comment
      timestamp
    }
  }
`;
