import { useMutation } from '@apollo/client';
import {
  CircularProgress,
  Grid,
  Typography,
  Table,
  TableContainer,
  TableRow,
  TableCell,
  TableBody,
  TableHead,
  Paper,
} from '@mui/material';
import React from 'react';
import {
  DELETE_COMPETITION,
  EDIT_COMPETITION,
} from '../../../graphql/mutations';

import { useAdminContext } from '../../../state/context/AdminContext';
import { useGlobalContext } from '../../../state/context/GlobalContext';
import { useNotificationContext } from '../../../state/context/NoticifationContext';
import { Competition } from '../../../types/team';
import { CompetitionRow } from './CompetitionRow';

interface MyCompProps {
  editCompetition: (e: Competition) => void;
}

export const MyCompetitions: React.FC<MyCompProps> = ({ editCompetition }) => {
  const { currentTeam, refetchTeam } = useGlobalContext();
  const notifier = useNotificationContext();
  const { competitions, refreshCompetitions, loading } = useAdminContext();

  const [setStatus] = useMutation(EDIT_COMPETITION);
  const [deleteCompetition] = useMutation(DELETE_COMPETITION);

  const handleStatusCompetition = (compId: string, status: string) => {
    setStatus({
      variables: {
        competition: { id: compId, status: status },
      },
    })
      .then(() => {
        refreshCompetitions();
        refetchTeam();
        notifier.addAlert(compId, {
          type: 'info',
          message: `Konkurransen er nå ${status}`,
          onClose: () => notifier.hideAlert(compId),
          autoHideDuration: 3000,
        });
      })
      .catch((err) => {
        console.error(err);
        notifier.addAlert(compId, {
          type: 'error',
          title: 'Noe gikk galt',
          message: 'Kunne ikke avslutte konkurranse',
          onClose: () => notifier.hideAlert(compId),
          autoHideDuration: 3000,
        });
      });
  };

  const handleDelete = (compId: string) => {
    deleteCompetition({
      variables: {
        teamId: currentTeam?.id,
        compId: compId,
      },
    })
      .then(() => {
        refreshCompetitions();
        refetchTeam();
        notifier.addAlert(compId, {
          type: 'info',
          message: 'Konkurransen ble slettet',
          onClose: () => notifier.hideAlert(compId),
          autoHideDuration: 3000,
        });
      })
      .catch((err) => {
        console.error(err);
        notifier.addAlert(compId, {
          type: 'error',
          title: 'Noe gikk galt',
          message: 'Kunne ikke slette konkurransen',
          onClose: () => notifier.hideAlert(compId),
          autoHideDuration: 3000,
        });
      });
  };

  const changeStatus = (row: any, status: string) => () => {
    handleStatusCompetition(row.id, status);
  };

  return (
    <Grid container className='admin-overview' spacing={3}>
      <Grid item xs={12}>
        <Typography variant='h4'>Konkurranser</Typography>
      </Grid>
      <Grid item xs={12}>
        {!loading ? (
          <TableContainer component={Paper}>
            <Table aria-label='collapsible table' style={{ borderRadius: 0 }}>
              <TableHead>
                <TableRow>
                  <TableCell />
                  <TableCell>Navn</TableCell>
                  <TableCell align='right'>Status</TableCell>
                  <TableCell align='right'>Endre</TableCell>
                  <TableCell align='right'>Slett</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {competitions?.map((comp: Competition, i: number) => (
                  <CompetitionRow
                    key={comp.name}
                    row={comp}
                    changeStatus={changeStatus}
                    handleDelete={(e: string) => handleDelete(e)}
                    editCompetition={(e: Competition) => editCompetition(e)}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <CircularProgress />
        )}
      </Grid>
    </Grid>
  );
};
