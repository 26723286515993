import React, { useCallback } from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { EmojiEvents, Logout, Person, Settings } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { LOGOUT } from 'graphql/mutations';
import { useGlobalContext } from 'state/context/GlobalContext';
import logo from 'assets/images/logo-colored.png';
import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import DashboardIcon from '@mui/icons-material/Dashboard';
import LoginIcon from '@mui/icons-material/Login';

import { useNotificationContext } from 'state/context/NoticifationContext';
import { routes } from 'pages/routes';
import {
  FormControl,
  FormLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import { TEAMS } from 'graphql/queries/Team/Teams';
import { Team } from 'types/team';

type Props = {
  open: boolean;
  close: () => void;
};

export const NavbarCollapse: React.FC<Props> = ({ open, close }: Props) => {
  let navigate = useNavigate();
  const notifier = useNotificationContext();

  const {
    loggedIn,
    clearAuth,
    currentUser,
    currentTeam,
    setTeam,
    setSettings,
  } = useGlobalContext();

  const { data: teamsData } = useQuery(TEAMS, {
    variables: { ids: currentUser?.teams },
    skip: !currentUser?.teams || currentUser?.teams?.length < 2,
  });

  const handleLogout = () => {
    logout()
      .then((res) => {
        if (res.data.logout) {
          clearAuth();
          close();
        } else {
          // handle this
        }
      })
      .catch((err) => {
        let notifId = 'logout-error';
        notifier.addAlert(notifId, {
          type: 'error',
          title: 'Noe gikk galt',
          message: 'Kunne ikke logge ut',
          onClose: () => notifier.hideAlert(notifId),
          autoHideDuration: 3000,
        });
        console.error(err);
      });
  };

  const [logout] = useMutation(LOGOUT, {
    onCompleted({ logout }) {
      if (logout) {
        clearAuth();
      } else {
        //TODO: handle something
      }
    },
    onError(error) {
      // TODO: alert error
      console.error(error);
    },
  });

  const login = useCallback(() => {
    navigate(routes.login, { replace: true });
    close();
  }, [navigate, close]);
  const leaderboard = useCallback(() => {
    navigate(routes.leaderboard, { replace: true });
    close();
  }, [navigate, close]);
  const dashboard = useCallback(() => {
    navigate(routes.dashboard, { replace: true });
    close();
  }, [navigate, close]);
  const Admin = useCallback(() => {
    navigate(routes.admin, { replace: true });
    close();
  }, [navigate, close]);
  const Profile = useCallback(() => {
    navigate(routes.profile, { replace: true });
    close();
  }, [navigate, close]);
  const Trophies = useCallback(() => {
    navigate(routes.trophies, { replace: true });
    close();
  }, [navigate, close]);

  const handleTeamChange = (e: SelectChangeEvent) => {
    let team = teamsData?.teams?.find(
      (x: Team) => x.id === e.target.value
    ) as Team;
    setTeam(team);
    setSettings(team.settings);
    localStorage.setItem('currentTeam', team.id);
  };

  return (
    <div>
      <Drawer anchor={'left'} open={open} onClose={() => close()}>
        <Box className='nav-drawer' role='presentation'>
          <div style={{ width: '100%', textAlign: 'center' }}>
            <a href='https://statz.no'>
              <img
                src={logo}
                className='navbar-drawer-logo-header'
                alt='statz'
              />
            </a>
          </div>
          {teamsData?.teams && teamsData?.teams?.length > 1 && (
            <FormControl sx={{ p: 2 }} fullWidth>
              <FormLabel>Velg team</FormLabel>
              <Select
                value={currentTeam?.id}
                fullWidth
                size='small'
                onChange={handleTeamChange}
              >
                {teamsData?.teams?.map((team: Team) => {
                  return <MenuItem value={team.id}>{team?.name}</MenuItem>;
                })}
              </Select>
            </FormControl>
          )}
          <List className='nav-drawer-content-holder'>
            {!loggedIn ? (
              <ListItem
                button
                key={'Login'}
                onClick={login}
                className='nav-drawer-item'
              >
                <ListItemIcon>
                  <LoginIcon className='nav-drawer-item-icons' />
                </ListItemIcon>
                <ListItemText primary={'Login'} />
              </ListItem>
            ) : (
              <>
                <ListItem
                  button
                  key={'Leaderboard'}
                  onClick={leaderboard}
                  className='nav-drawer-item'
                >
                  <ListItemIcon>
                    <LeaderboardIcon className='nav-drawer-item-icons' />
                  </ListItemIcon>
                  <ListItemText primary={'Leaderboard'} />
                </ListItem>
                <ListItem
                  button
                  key={'Dashboard'}
                  onClick={dashboard}
                  className='nav-drawer-item'
                >
                  <ListItemIcon>
                    <DashboardIcon className='nav-drawer-item-icons' />
                  </ListItemIcon>
                  <ListItemText primary={'Dashboard'} />
                </ListItem>
                <ListItem
                  button
                  key={'Profile'}
                  onClick={Profile}
                  className='nav-drawer-item'
                >
                  <ListItemIcon>
                    <Person className='nav-drawer-item-icons' />
                  </ListItemIcon>
                  <ListItemText primary={'Brukerinstillinger'} />
                </ListItem>

                <ListItem
                  button
                  key={'Utmerkelser'}
                  onClick={Trophies}
                  className='nav-drawer-item'
                >
                  <ListItemIcon>
                    <EmojiEvents className='nav-drawer-item-icons' />
                  </ListItemIcon>
                  <ListItemText primary={'Utmerkelser'} />
                </ListItem>
                {currentUser?.admin && (
                  <ListItem
                    button
                    key={'Admin'}
                    onClick={Admin}
                    className='nav-drawer-item'
                  >
                    <ListItemIcon>
                      <Settings className='nav-drawer-item-icons' />
                    </ListItemIcon>
                    <ListItemText primary={'Adminstrator'} />
                  </ListItem>
                )}
              </>
            )}
          </List>
          <Divider />
          {loggedIn && (
            <List className='nav-drawer-tools'>
              <ListItem button onClick={handleLogout}>
                <Tooltip title='Logg ut'>
                  <IconButton className='icon_button nav-drawer-tools-items'>
                    <Logout className='nav-drawer-item-icons' />
                  </IconButton>
                </Tooltip>
              </ListItem>
            </List>
          )}
        </Box>
      </Drawer>
    </div>
  );
};
