import { gql } from '@apollo/client';

export const USER_SUM = gql`
  query UserSum(
    $teamId: ID
    $userId: ID
    $unit: UnitInput
    $dateFrom: Date
    $dateTo: Date
  ) {
    userSum(
      teamId: $teamId
      userId: $userId
      unit: $unit
      dateFrom: $dateFrom
      dateTo: $dateTo
    ) {
      value
      quantity
    }
  }
`;
