import { gql } from '@apollo/client';

export const EDIT_USER = gql`
  mutation EditUser($id: ID, $user: UserInput) {
    editUser(id: $id, user: $user) {
      id
      firstName
      lastName
      email
      phone
      team
      teams
      createdAt
      username
      avatar
      sound
      animation
      admin
      color
      trophies {
        _id
        name
        competition
        type
        timestamp
      }
    }
  }
`;
