import { gql } from '@apollo/client';

export const TEAMS = gql`
  query Teams($ids: [ID]) {
    teams(ids: $ids) {
      id
      name
      units {
        id
        name
        suffix
        sumLabel
        countLabel
        newRegistrationLabel
        icon
      }
      goals {
        unit {
          id
          name
          suffix
          sumLabel
          countLabel
          newRegistrationLabel
          icon
        }
        value
        common
        timeframe
        userId
        measurement
      }
      categories {
        id
        name
        suffix
      }
      settings {
        spotify {
          aktiv
          token
          playlist
        }
        meme {
          aktiv
          topText
          bottomText
          image
        }
        layouts {
          _id
          name
          dateFilter
          unit {
            name
            id
            suffix
            sumLabel
            countLabel
            newRegistrationLabel
            icon
          }
          widgets {
            i
            x
            y
            w
            h
          }
          cols
          rows
          competition
        }
        leaderboard {
          defaultLayout
          loop
          loopTime
        }
        background {
          aktiv
          loopTime
          loop
          images
        }
        quote {
          aktiv
          category
        }
      }
    }
  }
`;
