import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { parseDateFilter } from 'utils/DateHandler';
import { COMPETITIONS } from '../../graphql/queries/Competition/Competitions';
import { DateFilter, DateRange } from '../../types/leaderboard';
import {
  Competition,
  Goal,
  LatestTeamRegistrations,
  Layout,
  Team,
} from '../../types/team';
import { createGenericContext } from './createGenericContext';
import { useGlobalContext } from './GlobalContext';
import { useFrameContext } from 'state/context/FrameContext';
import { NotificationProps } from 'components/Notification/Notification';
import { displayName } from 'utils/Stringparser';
import NumberFormatter from 'utils/NumberFormatter';
import { SETTINGS } from 'graphql/queries/Team/Settings';
import { TEAM } from 'graphql/queries/Team/Team';
import { COMPETITION_SUBSCRIPTION } from 'graphql/subscriptions/CompetitionSubscription';
import { GOALS_SUBSCRIPTION } from 'graphql/subscriptions/GoalsSubscription';
import { REGISTRATION_SUBSCRIPTION } from 'graphql/subscriptions/RegistrationSubscription';
import { SETTINGS_SUBSCRIPTION } from 'graphql/subscriptions/SettingsSubscription';
import { TEAM_REGISTRATIONS } from 'graphql/queries/Registration/TeamRegistrations';
import { REGISTRATION } from 'graphql/queries/Registration/Registration';
import { EDIT_SETTINGS } from 'graphql/mutations';

interface LeaderboardContextType {
  dateFilter: DateFilter | null | undefined;
  currentLayout: Layout | null | undefined;
  currentGoal: number | null | undefined;
  currentTeamGoal: number | null | undefined;
  competitions: Competition[] | null | undefined;
  currentCompetition: Competition | null | undefined;
  lastRegistrations: any[];
  timeframe: DateRange | null | undefined;
  notifications: NotificationProps[];
  setCurrentLayout: (layout: Layout | undefined) => void;
  addRegistrationNotification: (
    id: string,
    title: string,
    message: string,
    open: boolean,
    avatar: string,
    sound: string,
    animation: string
  ) => void;
  deleteNotification: (id: string) => void;
}

export type View = 'COMPETITIONS' | 'PERIODS';
export const [useLeaderboardContext, BareLeaderboardContextProvider] =
  createGenericContext<LeaderboardContextType>();

export const LeaderboardContextProvider: React.FC = ({ children }) => {
  const { currentTeam, currentSettings, setSettings, setTeam } =
    useGlobalContext();
  const [currentLayout, setCurrentLayout] = useState(
    currentSettings?.layouts?.find(
      (x: Layout) =>
        x._id === localStorage.getItem('currentLayout') ??
        currentSettings?.leaderboard?.loop?.[0]
    )
  );

  const { Fullscreen } = useFrameContext();
  const [timeframe, setTimeframe] = useState<DateRange>({
    dateFrom: null,
    dateTo: null,
  });
  const [notifications, setNotifications] = useState<NotificationProps[]>([]);
  const notificationStateRef = useRef<NotificationProps[]>();
  notificationStateRef.current = notifications;

  const { data, refetch: refetchCompetitions } = useQuery(COMPETITIONS, {
    variables: {
      teamId: currentTeam?.id,
    },
    onError(err) {
      console.error(JSON.stringify(err, null, 2));
    },
  });

  const currentCompetition = data?.competitions?.find(
    (comp: Competition) => comp.id === currentLayout?.competition
  );

  const currentGoal = renderGoal(
    currentTeam?.goals ?? [],
    currentLayout?.dateFilter ?? 'MONTH'
  );
  const currentTeamGoal = renderCommonGoal(
    currentTeam?.goals ?? [],
    currentLayout?.dateFilter
  );

  // Render timeframe
  useEffect(() => {
    if (currentCompetition) {
      setTimeframe({
        dateFrom: currentCompetition.startDate,
        dateTo: currentCompetition.endDate,
      });
    } else if (currentLayout?.dateFilter) {
      setTimeframe(parseDateFilter(currentLayout?.dateFilter ?? 'MONTH'));
    }
  }, [currentCompetition, setTimeframe, Fullscreen, currentLayout?.dateFilter]);

  const { subscribeToMore: SettingsSubscribeToMore, refetch: refetchSettings } =
    useQuery(SETTINGS, {
      variables: { teamId: currentTeam?.id },
      onCompleted(data) {
        setSettings(data.team.settings);
      },
    });

  const { subscribeToMore: GoalsSubscribeToMore } = useQuery(TEAM, {
    variables: { id: currentTeam?.id },
  });

  const { subscribeToMore: CompetitionSubscribeToMore } = useQuery(
    COMPETITIONS,
    {
      variables: { teamId: currentTeam?.id },
    }
  );
  const [fetchRegistration] = useLazyQuery(REGISTRATION);

  const currentTimeframe = currentCompetition
    ? {
        dateFrom: currentCompetition?.startDate,
        dateTo: currentCompetition?.endDate,
      }
    : parseDateFilter(currentLayout?.dateFilter);
  const {
    data: lastRegistrationsData,
    subscribeToMore: RegistrationsSubscribeToMore,
    refetch: refetchRegistrations,
  } = useQuery(TEAM_REGISTRATIONS, {
    variables: {
      teamId: currentTeam?.id,
      unit: currentCompetition?.unit ?? currentLayout?.unit,
      category:
        data?.competitions?.find(
          (comp: Competition) => currentLayout?.competition === comp.id
        )?.category ?? undefined,
      limit: 3,
      ...currentTimeframe,
    },
  });

  const handleDeleteNotification = useCallback((id: string) => {
    setNotifications(
      notificationStateRef.current?.filter((not) => not.id !== id) ?? []
    );
  }, []);

  const handleAddNotification = useCallback(
    (
      id: string,
      title: string,
      message: string,
      open: boolean,
      avatar: string,
      sound: string,
      animation: string
    ) => {
      let newNotification = {
        id,
        title,
        message,
        open,
        avatar,
        sound,
        animation,
      } as NotificationProps;
      setNotifications([...notifications, newNotification]);
    },
    [notifications]
  );

  const handleSetCurrentLayout = useCallback((layout: Layout | undefined) => {
    setCurrentLayout(layout);
    if (layout) {
      localStorage.setItem('currentLayout', layout._id ?? null);
    } else {
      localStorage.removeItem('currentLayout');
    }
  }, []);

  const [editSettings] = useMutation(EDIT_SETTINGS);

  useEffect(() => {
    if (currentSettings?.leaderboard?.refresh) {
      editSettings({
        variables: {
          teamId: currentTeam?.id,
          settings: {
            leaderboard: {
              ...currentSettings?.leaderboard,
              refresh: false,
            },
          },
        },
      });
    }

    let settingsSubscribe: any;
    let goalSubscribe: any;
    let competitionSubscribe: any;
    let registrationSubscribe: any;
    if (Fullscreen) {
      settingsSubscribe = SettingsSubscribeToMore({
        document: SETTINGS_SUBSCRIPTION,
        updateQuery: (prev, { subscriptionData }) => {
          if (!subscriptionData.data) return prev;
          let refreshFlag =
            subscriptionData?.data?.settings?.leaderboard?.refresh;
          if (!currentSettings?.leaderboard?.refresh && refreshFlag) {
            window.history.go(0);
            return;
          }
          setSettings(subscriptionData?.data?.settings);
        },
      });
      goalSubscribe = GoalsSubscribeToMore({
        document: GOALS_SUBSCRIPTION,
        updateQuery: (prev, { subscriptionData }) => {
          if (!subscriptionData.data) return prev;
          let newTeam = {
            ...currentTeam,
            ...{ goals: subscriptionData?.data?.goal as any },
          } as Team;

          setTeam(newTeam);
        },
      });
      competitionSubscribe = CompetitionSubscribeToMore({
        document: COMPETITION_SUBSCRIPTION,
        variables: { teamId: currentTeam?.id },
        updateQuery: (prev, { subscriptionData }) => {
          let competition = subscriptionData?.data?.competition;
          if (!competition) return prev;
          refetchCompetitions();
        },
      });

      registrationSubscribe = RegistrationsSubscribeToMore({
        document: REGISTRATION_SUBSCRIPTION,
        variables: {
          teamId: currentTeam?.id,
        },
        updateQuery: (prev, { subscriptionData }) => {
          let latestRegs = subscriptionData?.data?.latestRegistrations;
          if (latestRegs) {
            fetchRegistration({ variables: { id: latestRegs.id } }).then(
              ({ data }) => {
                let registration = data.registration as LatestTeamRegistrations;
                handleAddNotification(
                  registration.id,
                  `${
                    registration?.unit?.newRegistrationLabel ??
                    registration?.unit?.name
                  } - ${NumberFormatter.format(registration?.value ?? 0)}${
                    registration.unit?.suffix
                  }`,
                  displayName(registration.userdata),
                  true,
                  registration?.userdata?.avatar ?? '',
                  registration?.userdata?.sound ?? '',
                  registration?.userdata?.animation ?? ''
                );
              }
            );
            refetchRegistrations();
          } else {
            return prev;
          }
        },
      });
    }
    if (
      settingsSubscribe &&
      goalSubscribe &&
      competitionSubscribe &&
      registrationSubscribe
    )
      return () => {
        settingsSubscribe();
        goalSubscribe();
        competitionSubscribe();
        registrationSubscribe();
      };
  }, [
    Fullscreen,
    SettingsSubscribeToMore,
    GoalsSubscribeToMore,
    CompetitionSubscribeToMore,
    RegistrationsSubscribeToMore,
    notifications,
    currentTeam,
    setSettings,
    setTeam,
    refetchCompetitions,
    fetchRegistration,
    refetchRegistrations,
    handleAddNotification,
    currentSettings?.leaderboard?.refresh,
    editSettings,
    currentSettings?.leaderboard,
  ]);

  useEffect(() => {
    refetchRegistrations();
  }, [currentLayout, refetchRegistrations]);

  useEffect(() => {
    refetchCompetitions();
    refetchSettings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let initialLayout = currentSettings?.layouts?.find(
      (x: Layout) =>
        x._id === localStorage.getItem('currentLayout') ??
        currentSettings?.leaderboard?.loop?.[0]
    );
    handleSetCurrentLayout(initialLayout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTeam?.id]);

  const value: LeaderboardContextType = useMemo(
    () => ({
      dateFilter: currentLayout?.dateFilter,
      currentLayout: currentLayout,
      competitions: data?.competitions,
      currentGoal: currentGoal,
      currentTeamGoal: currentTeamGoal,
      currentCompetition: currentCompetition,
      timeframe: timeframe,
      lastRegistrations: lastRegistrationsData?.teamRegistrations,
      notifications: notifications,
      setCurrentLayout: handleSetCurrentLayout,
      addRegistrationNotification: handleAddNotification,
      deleteNotification: handleDeleteNotification,
    }),
    [
      currentLayout,
      data?.competitions,
      currentGoal,
      currentTeamGoal,
      currentCompetition,
      timeframe,
      lastRegistrationsData,
      notifications,
      handleAddNotification,
      handleDeleteNotification,
      handleSetCurrentLayout,
    ]
  );
  return (
    <BareLeaderboardContextProvider value={value}>
      {children}
    </BareLeaderboardContextProvider>
  );
};

const renderGoal = (goals: Goal[], timeframe: DateFilter) => {
  return (
    goals.find((goal) => goal.timeframe === timeframe && !goal.common)?.value ??
    null
  );
};

const renderCommonGoal = (
  goals: Goal[],
  timeframe: DateFilter | null | undefined
) => {
  return (
    goals.find((goal) => goal.timeframe === timeframe && goal.common)?.value ??
    null
  );
};
