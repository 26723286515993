import { gql } from '@apollo/client';

export const GOALS_SUBSCRIPTION = gql`
  subscription Subscription($teamId: ID) {
    goal(teamId: $teamId) {
      common
      timeframe
      value
      unit {
        id
        name
        createdAt
        suffix
        sumLabel
        countLabel
        newRegistrationLabel
        icon
      }
      createdAt
      measurement
    }
  }
`;
