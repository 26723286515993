import { useQuery } from '@apollo/client';
import { useCallback, useMemo, useState } from 'react';
import { COMPETITIONS } from '../../graphql/queries/Competition/Competitions';
import { Competition } from '../../types/team';
import { createGenericContext } from './createGenericContext';
import { useGlobalContext } from './GlobalContext';

interface AdminContextType {
  loading: Boolean;
  bottomView: string | null | undefined;
  currentCompetition: Competition | null | undefined;
  competitions: Competition[] | null | undefined;
  refreshCompetitions: () => void;
  setBottomView: (view: string | null) => void;
  setCurrentCompetition: (competition: Competition | null) => void;
}

export const [useAdminContext, BareAdminContextProvider] =
  createGenericContext<AdminContextType>();

export const AdminContextProvider: React.FC = ({ children }) => {
  const { currentTeam } = useGlobalContext();
  const [bottomView, setBottomView] = useState<string | null>();
  const [currentCompetition, setCurrentCompetition] =
    useState<Competition | null>();

  const { loading, data, refetch } = useQuery(COMPETITIONS, {
    variables: {
      teamId: currentTeam?.id,
    },
    onError(err) {
      console.error(JSON.stringify(err, null, 2));
    },
  });

  const handleRefreshCompetitions = useCallback(() => {
    refetch();
  }, [refetch]);

  const handleBottomView = useCallback((view: string | null) => {
    setBottomView(view);
  }, []);

  const handleSetCompetition = useCallback(
    (competition: Competition | null) => {
      setCurrentCompetition(competition);
    },
    []
  );

  const value: AdminContextType = useMemo(
    () => ({
      loading: loading,
      bottomView: bottomView,
      competitions: data?.competitions,
      currentCompetition: currentCompetition,
      refreshCompetitions: handleRefreshCompetitions,
      setBottomView: handleBottomView,
      setCurrentCompetition: handleSetCompetition,
    }),
    [
      bottomView,
      currentCompetition,
      data?.competitions,
      handleBottomView,
      handleRefreshCompetitions,
      handleSetCompetition,
      loading,
    ]
  );

  return (
    <BareAdminContextProvider value={value}>
      {children}
    </BareAdminContextProvider>
  );
};
