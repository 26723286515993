/* eslint-disable react-hooks/exhaustive-deps */
import { Grid, Fab, Typography, IconButton, Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useLeaderboardContext } from 'state/context/LeaderboardContext';
import { useGlobalContext } from 'state/context/GlobalContext';
import { useFrameContext } from 'state/context/FrameContext';

import BackgroundSlider from 'react-background-slider';
import { environment } from 'utils/environment';

import { FullScreen, useFullScreenHandle } from 'react-full-screen';

import logo from 'assets/images/logo-colored.png';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';

import DefaultBackground from 'assets/images/default-background.jpg';
import { capitalizeFirst } from 'utils/Stringparser';

import { NotificationQueue } from './NotificationQueue';
import { DEFAULT_GRID, LayoutGrid } from 'components/LayoutGrid/LayoutGrid';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import { Layout } from 'types/team';

export const LeaderboardPage: React.FC = () => {
  let {
    dateFilter,
    currentLayout,
    currentCompetition,
    setCurrentLayout,
    timeframe,
    currentTeamGoal,
    lastRegistrations,
  } = useLeaderboardContext();

  const { Fullscreen, handleFullscreen } = useFrameContext();
  const [leaderboardSize, setLeaderboardSize] = useState<{
    height: string | number;
    width: string | number;
  }>();

  let { currentSettings } = useGlobalContext();

  const handle = useFullScreenHandle();

  const renderNextLayout = () => {
    let currentIndex =
      currentSettings?.leaderboard?.loop?.findIndex(
        (lay: string) => lay === currentLayout?._id
      ) ?? 0;
    let loopSize = currentSettings?.leaderboard?.loop?.length ?? 0;
    if (currentIndex + 1 <= loopSize - 1) {
      let nextLayout = currentSettings?.leaderboard?.loop?.[currentIndex + 1];
      setCurrentLayout(
        currentSettings?.layouts?.find(
          (layout) => layout._id === nextLayout
        ) as Layout
      );
    } else {
      let nextLayout = currentSettings?.leaderboard?.loop?.[0];
      setCurrentLayout(
        currentSettings?.layouts?.find(
          (layout) => layout._id === nextLayout
        ) as Layout
      );
    }
  };

  // Layout automatic looping
  useEffect(() => {
    let interval: NodeJS.Timer;
    let time = (currentSettings?.leaderboard?.loopTime ?? 30) * 1000;
    if (Fullscreen && currentSettings?.leaderboard?.loop) {
      interval = setTimeout(() => {
        renderNextLayout();
      }, time);
    }
    return () => clearInterval(interval);
  }, [
    Fullscreen,
    currentSettings?.leaderboard?.loop,
    currentSettings?.leaderboard?.loopTime,
    currentLayout,
  ]);

  const setFullscreen = () => {
    if (!Fullscreen) {
      environment === 'production' && handle.enter();
      setCurrentLayout(
        currentSettings?.layouts?.find(
          (x: Layout) => x._id === currentSettings?.leaderboard?.loop?.[0]
        )
      );
      handleFullscreen(true);
    } else {
      environment === 'production' && handle.exit();
      handleFullscreen(false);
    }
  };

  const handlePreviousLayoutClick = () => {
    let currentIndex =
      currentSettings?.layouts?.findIndex(
        (lay) => lay._id === currentLayout?._id
      ) ?? 0;
    let nextLayout =
      currentSettings?.layouts?.[
        currentIndex !== 0
          ? currentIndex - 1
          : currentSettings?.layouts?.length - 1
      ];
    setCurrentLayout(nextLayout as Layout);
  };

  const handleNextLayoutClick = () => {
    let currentIndex =
      currentSettings?.layouts?.findIndex(
        (lay) => lay._id === currentLayout?._id
      ) ?? 0;
    let nextLayout =
      currentSettings?.layouts?.[
        currentIndex < currentSettings?.layouts?.length - 1
          ? currentIndex + 1
          : 0
      ];
    setCurrentLayout(nextLayout as Layout);
  };

  let Header: string = capitalizeFirst(currentLayout?.name ?? '');

  useEffect(() => {
    const observer = new ResizeObserver((entries) => {
      let height = (entries?.[0]?.contentRect?.height ?? 0) * 0.85;
      let width = height * 1.167;
      if (height && width) {
        setLeaderboardSize({ height: height, width: width });
      }
    });
    const leaderboardContainer = document.getElementById('leaderboard_root');
    observer.observe(leaderboardContainer as Element);

    return () => observer.unobserve(leaderboardContainer as Element);
  }, []);

  return (
    <>
      <FullScreen handle={handle}>
        <Box className='leaderboard_root' id='leaderboard_root'>
          <NotificationQueue />

          {leaderboardSize && (
            <Grid
              className='leaderboard'
              container
              style={{
                width: leaderboardSize?.width,
                maxWidth: leaderboardSize?.width,
              }}
            >
              {Fullscreen && (
                <Grid item xs={12} className='leaderboard_header_container'>
                  <Grid container spacing={1}>
                    <Grid item xs={12} className='leaderboard_header'>
                      <div className='logo_container'>
                        <img src={logo} alt='logo' className='logo'></img>
                        <div className='underline'></div>
                        <Typography className='leaderboard_info'>
                          {Header.charAt(0).toUpperCase() + Header.slice(1)}
                        </Typography>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              )}
              {!Fullscreen && (
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    zIndex: 200,
                    marginTop: 10,
                    borderRadius: '12px',
                    paddingX: 5,
                    paddingY: 1,
                    background:
                      'transparent linear-gradient(180deg, #313132 0%, #161618 100%) 0%',
                    marginX: 1.2,
                  }}
                >
                  <IconButton
                    color='primary'
                    onClick={handlePreviousLayoutClick}
                  >
                    <KeyboardArrowLeft fontSize='medium' />
                  </IconButton>
                  <Typography variant='h6' color={'#fff'}>
                    {currentCompetition?.name ?? currentLayout?.name}
                  </Typography>
                  <IconButton color='primary' onClick={handleNextLayoutClick}>
                    <KeyboardArrowRight fontSize='medium' />
                  </IconButton>
                </Grid>
              )}
              <Grid
                id='leaderboard_container'
                item
                xs={12}
                style={{
                  height: leaderboardSize?.height,

                  maxHeight: leaderboardSize?.height,
                  padding: 8,
                }}
              >
                <Box
                  id='layout-container'
                  sx={{ height: '100%', width: '100%' }}
                >
                  <LayoutGrid
                    cols={currentLayout?.cols ?? 8}
                    rows={currentLayout?.rows}
                    widgets={
                      currentLayout?.widgets &&
                      currentLayout?.widgets?.length > 0
                        ? currentLayout?.widgets
                        : DEFAULT_GRID
                    }
                    data={{
                      dateFilter: dateFilter,
                      competition: currentCompetition,
                      timeframe: timeframe,
                      currentLayout: currentLayout,
                      teamGoal: currentTeamGoal,
                      lastRegistrations: lastRegistrations,
                    }}
                  />
                </Box>
              </Grid>

              <Fab
                sx={{
                  position: 'absolute',
                  bottom: 16,
                  right: 16,
                  zIndex: 999999,
                }}
                color='primary'
                aria-label='add'
                onClick={() => setFullscreen()}
              >
                {Fullscreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
              </Fab>
            </Grid>
          )}
        </Box>
        {true && (
          <>
            <BackgroundSlider
              images={
                currentSettings?.background?.loop
                  ? currentSettings?.background?.images &&
                    currentSettings.background.aktiv
                    ? currentSettings.background.images
                    : [DefaultBackground]
                  : [
                      (currentSettings?.background?.images &&
                      currentSettings.background.aktiv
                        ? currentSettings.background.images
                        : [DefaultBackground])[0],
                    ]
              }
              duration={
                currentSettings?.background?.images?.length === 1 ||
                !currentSettings?.background?.loop
                  ? 9999999999999
                  : (currentSettings?.background?.loopTime ?? 10) * 60
              }
              transition={2}
            />
          </>
        )}
      </FullScreen>
    </>
  );
};
