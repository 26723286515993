import { Add } from '@mui/icons-material';
import { Box, Button, Tab, Modal } from '@mui/material';
import React, { useState } from 'react';
import { MyCompetitions } from '../../components/widgets/AdminWidgets/MyCompetitions';
import { MyGoals } from '../../components/widgets/AdminWidgets/MyGoals';
import { SettingsWidget } from 'components/widgets/AdminWidgets/Settings';
import Backdrop from '@mui/material/Backdrop';

import { MyTeam } from 'components/widgets/AdminWidgets/MyTeam';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { CompetitionStepper } from 'components/widgets/AdminWidgets/CompetitionStepper';
import { Competition } from 'types/team';
import { MyLayouts } from 'components/widgets/AdminWidgets/Layout';

type TabType =
  | 'SETTINGS'
  | 'LAYOUT'
  | 'MY_TEAM'
  | 'MY_COMPETITIONS'
  | 'MY_GOALS';

type EditCompType = {
  open: boolean;
  competition: Competition | null | undefined;
  edit: boolean;
};
export const Admin: React.FC = () => {
  const [CompetitionSettings, setCompetitionSettings] = useState<EditCompType>({
    open: false,
    edit: false,
    competition: null,
  });

  const [currentTab, setCurrentTab] = useState<TabType>('SETTINGS');

  return (
    <div className='admin-container'>
      <div className='admin-content'>
        <TabContext value={currentTab}>
          <Box sx={{ borderBottom: 1, borderColor: '#52565a' }}>
            <TabList
              onChange={(e, value) => setCurrentTab(value as TabType)}
              aria-label='basic tabs example'
            >
              <Tab label='Innstillinger' value={'SETTINGS'} />
              {/* <Tab label='Layout' value={'LAYOUT'} /> */}
              <Tab label='Team' value={'MY_TEAM'} />
              <Tab label='Konkurranser' value={'MY_COMPETITIONS'} />
              <Tab label='Mål' value={'MY_GOALS'} />
              <Tab label='Layout' value={'LAYOUT'} />
            </TabList>
          </Box>
          {currentTab === 'SETTINGS' && (
            <TabPanel value={'SETTINGS'} className='tab'>
              <SettingsWidget />
            </TabPanel>
          )}
          {currentTab === 'LAYOUT' && (
            <TabPanel value={'LAYOUT'} className='tab'>
              <MyLayouts />
            </TabPanel>
          )}
          {currentTab === 'MY_TEAM' && (
            <TabPanel value={'MY_TEAM'} className='tab'>
              <MyTeam />
            </TabPanel>
          )}
          {currentTab === 'MY_COMPETITIONS' && (
            <TabPanel value={'MY_COMPETITIONS'} className='tab'>
              <MyCompetitions
                editCompetition={(e) => {
                  setCompetitionSettings({
                    competition: e,
                    open: true,
                    edit: true,
                  });
                }}
              />

              <Modal
                open={CompetitionSettings.open}
                onClose={() => {
                  setCompetitionSettings({
                    open: false,
                    edit: false,
                    competition: null,
                  });
                }}
                aria-labelledby='modal-modal-title'
                aria-describedby='modal-modal-description'
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                  timeout: 500,
                }}
              >
                <Box
                  sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    bgcolor: '#474444',
                    border: '2px solid #000',
                    borderRadius: 4,
                    boxShadow: 24,
                    p: 4,
                    width: '80%',
                    maxWidth: 800,
                    marginLeft: 10,
                  }}
                >
                  <CompetitionStepper
                    edit={CompetitionSettings?.edit}
                    competition={CompetitionSettings?.competition}
                    close={() =>
                      setCompetitionSettings({
                        open: false,
                        edit: false,
                        competition: null,
                      })
                    }
                  />
                </Box>
              </Modal>
              <div>
                <Button
                  className='add-button'
                  endIcon={<Add />}
                  onClick={() =>
                    setCompetitionSettings({
                      open: true,
                      edit: false,
                      competition: null,
                    })
                  }
                >
                  Legg til konkurranse
                </Button>
              </div>
            </TabPanel>
          )}
          {currentTab === 'MY_GOALS' && (
            <TabPanel value={'MY_GOALS'} className='tab'>
              <MyGoals />
            </TabPanel>
          )}
        </TabContext>
      </div>
    </div>
  );
};
