import { useQuery } from '@apollo/client';
import {
  Avatar,
  Box,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';
import { LEADERBOARD } from 'graphql/queries/Registration/Leaderboard';
import React, { useEffect } from 'react';
import { useFrameContext } from 'state/context/FrameContext';
import { User } from 'types/user';
import { useGlobalContext } from '../../../state/context/GlobalContext';
import { TeamMember, Unit } from '../../../types/team';
import NumberFormatter from '../../../utils/NumberFormatter';
import { WidgetProps } from 'components/LayoutGrid/LayoutGrid';

export const Leaderboard: React.FC<WidgetProps> = ({
  dateFilter,
  competition,
  timeframe,
  currentLayout,
  empty,
  widget,
}) => {
  const { currentTeam, currentUnit } = useGlobalContext();
  const { Fullscreen } = useFrameContext();

  const getLimit = () => {
    let height = widget?.h ?? 0;
    let rows = currentLayout?.rows ?? 0;
    let heightFraction = height / rows;
    if (heightFraction < 0.3) {
      return 3;
    } else if (heightFraction < 0.4) {
      return 5;
    } else if (heightFraction < 0.6) {
      return 6;
    } else if (heightFraction < 0.65) {
      return 8;
    } else {
      return 10;
    }
  };

  let unit = competition?.unit ?? currentLayout?.unit;
  const limit = getLimit();
  const {
    loading,
    data: leaderboard,
    refetch: refreshLeaderboard,
  } = useQuery(LEADERBOARD, {
    variables: {
      teamId: currentTeam?.id,
      unit: unit,
      category: competition?.category,
      ...timeframe,
      sortBy: competition?.goal?.goalType ?? 'SALES_AMOUNT',
      limit: limit,
    },
    pollInterval: 5000,

    onError(err) {
      console.error(err?.graphQLErrors);
    },
    skip: empty,
  });

  useEffect(() => {
    //setPreviousFilter(dateFilter);
    refreshLeaderboard();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeframe, competition, Fullscreen]);

  const displayName = (user: User) => {
    if (user.username) {
      return user.username;
    } else if (user.firstName && user.lastName) {
      return `${user.firstName} ${user.lastName}`;
    } else {
      return user.email;
    }
  };

  const renderGoal = (userId: string) => {
    if (!currentTeam?.goals) {
      return null;
    }

    let currentGoal = currentTeam.goals.find(
      (goal) =>
        goal.userId === userId &&
        goal.timeframe === dateFilter &&
        goal.measurement === 'SALES_AMOUNT'
    );

    return currentGoal
      ? ` / ${NumberFormatter.format(currentGoal.value)}`
      : undefined;
  };

  const renderPercentage = (
    value: number | null | undefined,
    userId: string,
    limit?: number
  ) => {
    if (!currentTeam?.goals && !competition?.goal) {
      return null;
    }

    let goal =
      competition?.goal?.amount ??
      currentTeam?.goals?.find(
        (goal) =>
          goal.userId === userId &&
          goal.timeframe === dateFilter &&
          goal.measurement === 'SALES_AMOUNT' &&
          goal.unit.name === currentUnit?.name
      )?.value;

    if (!goal || goal === 0) return undefined;

    const percentage = parseInt((((value ?? 0) / goal) * 100).toFixed(2));

    return !limit
      ? `${percentage}%`
      : `${percentage > limit ? limit : percentage}%`;
  };

  return (
    <Box
      style={{
        width: '100%',
        height: '100%',
        justifyContent: 'center',
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <Paper className='leaderboard_card'>
        <TableContainer className='alo'>
          <Table
            size='small'
            stickyHeader
            aria-label='sticky table'
            className='LeaderBoardTable'
          >
            <TableBody className='leaderboard_body'>
              {!loading ? (
                <>
                  {leaderboard?.leaderboard?.map(
                    (teamMember: TeamMember, i: number) => {
                      return (
                        <TableRow key={i} className='user_row'>
                          <TableCell
                            component='th'
                            scope='row'
                            className='user__body'
                          >
                            <Typography
                              variant='body1'
                              className='user__body--nr'
                            >
                              <span>{i + 1}</span>
                            </Typography>
                            <div className='user__body--bg-color'>
                              <div className='user__body--profile'>
                                <div className='user--bg'>
                                  <Avatar
                                    alt='avatar'
                                    src={teamMember.userdata.avatar ?? ''}
                                    className='avatar'
                                    sx={{
                                      borderColor: `${
                                        teamMember.userdata.color
                                          ? teamMember.userdata.color
                                          : '#ffe784'
                                      }`,
                                    }}
                                  />
                                </div>
                              </div>
                              <div className='user_content'>
                                <div className='user__div'>
                                  <div className='user__results'>
                                    <Typography variant='h6'>
                                      {displayName(teamMember.userdata)}
                                    </Typography>
                                    <Typography className='user__amount'>
                                      {`(${teamMember.quantity}) `}
                                      {`${renderValue(
                                        teamMember.value,
                                        currentUnit ?? {}
                                      )}${
                                        renderGoal(
                                          teamMember.userdata.id ?? ''
                                        ) ?? ''
                                      }`}
                                    </Typography>
                                  </div>
                                  <div className='user_bottom'>
                                    <div
                                      className='progress-bar'
                                      style={{
                                        display: renderPercentage(
                                          teamMember.value,
                                          teamMember.userdata.id ?? ''
                                        )
                                          ? 'unsert'
                                          : 'none',
                                      }}
                                    >
                                      <div
                                        className='line__style'
                                        style={{
                                          backgroundColor: `${
                                            teamMember.userdata.color
                                              ? teamMember.userdata.color
                                              : '#ffe784'
                                          }`,
                                          opacity: 0.6,
                                          width: `${
                                            renderPercentage(
                                              teamMember.value,
                                              teamMember.userdata.id ?? '',
                                              100
                                            )
                                              ? renderPercentage(
                                                  teamMember.value,
                                                  teamMember.userdata.id ?? '',
                                                  100
                                                )
                                              : '0%'
                                          }`,
                                        }}
                                      ></div>
                                    </div>
                                    <Typography className='user_percentage'>
                                      {renderPercentage(
                                        teamMember.value,
                                        teamMember.userdata.id ?? ''
                                      ) ?? ''}
                                    </Typography>
                                  </div>
                                </div>
                                {/*  <div className='user_quantity_container'>
                                  <Typography className='user_quantity'>
                                    {teamMember.quantity}
                                  </Typography>
                                  <Typography className='quantity_suffix'>
                                    {unit?.name}
                                  </Typography>
                                </div> */}
                              </div>
                            </div>
                          </TableCell>
                        </TableRow>
                      );
                    }
                  )}
                  {limit - (leaderboard?.leaderboard?.length ?? 0) > 0 &&
                    Array.from(
                      Array(
                        limit -
                          (leaderboard?.leaderboard?.length > limit
                            ? limit
                            : leaderboard?.leaderboard?.length ?? 0)
                      )
                    ).map((_, i) => {
                      return (
                        <TableRow key={`${i}2`} className='user_row'>
                          <TableCell
                            component='th'
                            scope='row'
                            className='user__body'
                            sx={{ opacity: 0.5 }}
                          >
                            <Typography
                              variant='body1'
                              className='user__body--nr'
                            >
                              <span>-</span>
                            </Typography>
                            <div className='user__body--bg-color'>
                              <div className='user__body--profile'>
                                <div className='user--bg'>
                                  <Avatar
                                    alt='?'
                                    src={undefined}
                                    className='avatar'
                                  />
                                </div>
                              </div>
                              <div className='user_content'>
                                <div className='user__div'>
                                  <div className='user__results'></div>
                                  <div className='user_bottom'></div>
                                </div>
                                <div className='user_quantity_container'></div>
                              </div>
                            </div>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </>
              ) : (
                <TableRow>
                  <TableCell
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: '100%',
                      width: '100%',
                    }}
                  >
                    <CircularProgress />
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  );
};

const renderValue = (value: number | null | undefined, unit: Unit) => {
  return `${NumberFormatter.format(value ?? 0)}`;
};
