import { User } from 'types/user';

export const displayName = (user: User) => {
  if (user.username && user.username.length > 0) {
    return user.username;
  } else if (user.firstName && user.lastName) {
    return `${user.firstName} ${user.lastName}`;
  } else if (user.email) {
    return user.email;
  } else {
    return '';
  }
};

export const capitalizeFirst = (input: string) => {
  return input.charAt(0).toUpperCase() + input.slice(1);
};
