import { Avatar, Card, Paper, Typography } from '@mui/material';
import React from 'react';
import { Unit } from 'types/team';
import NumberFormatter from 'utils/NumberFormatter';

import { capitalizeFirst, displayName } from 'utils/Stringparser';
import { User } from 'types/user';
import { WidgetProps } from 'components/LayoutGrid/LayoutGrid';

export const LastRegistrations: React.FC<WidgetProps> = ({
  lastRegistrations,
  competition,
  currentLayout,
}) => {
  const unit = competition?.unit ??
    currentLayout?.unit ?? { name: 'salg', suffix: 'kr' };

  const renderTimeSince = (timestamp: string) => {
    const today = new Date().getTime();
    let startDate = new Date(timestamp).getTime();

    const days = (today - startDate) / (1000 * 60 * 60 * 24);
    const hours = (Math.abs(today - startDate) / (1000 * 60 * 60)) % 24;
    const minutes = (Math.abs(today - startDate) / (1000 * 60)) % 60;

    if (days > 1) {
      return `${Math.floor(days)} days ago`;
    } else if (hours > 1) {
      return `${Math.floor(hours)} hours ago`;
    } else if (minutes > 30) {
      return '< 1 hour ago';
    } else if (minutes > 10) {
      return '< 30 min ago';
    } else if (minutes > 1) {
      return '< 10 min ago';
    } else {
      return '< 1 min ago';
    }
  };

  return (
    <Paper className='last_registrations'>
      <Typography className='last_registrations_title'>{`Siste ${unit?.name}`}</Typography>
      <div className='last_registrations_content'>
        {lastRegistrations && lastRegistrations.length > 0 ? (
          lastRegistrations.map((reg, i) => {
            return (
              <Card
                key={reg._id}
                className='registration_card'
                sx={{
                  background: i % 2 === 0 ? '#161618' : 'transparent',
                  borderBottomLeftRadius:
                    i + 1 === lastRegistrations?.length
                      ? '12px !important'
                      : undefined,
                  borderBottomRightRadius:
                    i + 1 === lastRegistrations?.length
                      ? '12px !important'
                      : undefined,
                }}
              >
                <div className='registration_left'>
                  <Avatar
                    className='registration_avatar'
                    src={reg?.userdata?.avatar}
                  />
                  <div
                    className='reg_category_container'
                    style={{
                      backgroundColor: reg?.userdata?.color ?? '#efc862',
                    }}
                  >
                    <Typography className='reg_category'>
                      {capitalizeFirst(reg?.category?.name ?? 'ingen')}
                    </Typography>
                  </div>
                </div>

                <div className='registration_info'>
                  <div className='reg_info_top'>
                    <Typography className='reg_name'>
                      {displayName(reg?.userdata as User)}
                    </Typography>
                    <Typography className='time_since'>
                      {renderTimeSince(reg?.timestamp)}
                    </Typography>
                  </div>

                  <Typography className='reg_amount'>
                    {renderValue(reg?.value ?? 0, unit ?? {})}
                  </Typography>
                  {/* <Typography className='reg_comment'>
                    {reg?.comment}
                  </Typography> */}
                </div>
              </Card>
            );
          })
        ) : (
          <div className='placeholder-container'>
            <Typography className='placeholder-text'>
              {`Ingen nylige ${unit?.name}`}
            </Typography>
          </div>
        )}
      </div>
    </Paper>
  );
};

const renderValue = (value: number | null | undefined, unit: Unit) => {
  return `${NumberFormatter.format(value ?? 0)} ${unit?.suffix}`;
};
