import { useQuery, useMutation } from '@apollo/client';
import {
  CircularProgress,
  List,
  ListItem,
  Typography,
  IconButton,
  Pagination,
  Tooltip,
} from '@mui/material';
import { REGISTRATIONS } from 'graphql/queries/Registration/Registrations';
import { DELETE_REGISTRATION } from 'graphql/mutations/Registration/deleteRegistration';
import React, { useEffect, useState } from 'react';
import { useGlobalContext } from 'state/context/GlobalContext';
import { useNotificationContext } from 'state/context/NoticifationContext';
import { useUserPageContext } from 'state/context/UserPageContext';
import { Registration } from 'types/team';
import NumberFormatter from 'utils/NumberFormatter';

import HouseIcon from '@mui/icons-material/House';
import PersonIcon from '@mui/icons-material/Person';
import DeleteIcon from '@mui/icons-material/Delete';

import * as Icons from '@mui/icons-material';

export const RecentSales: React.FC = () => {
  const [startIndex, setStartIndex] = useState(0);

  const { currentTeam, currentUser } = useGlobalContext();
  const { total, refreshData, currentUnit } = useUserPageContext();
  const notifier = useNotificationContext();
  const { loading, data, refetch } = useQuery(REGISTRATIONS, {
    variables: {
      teamId: currentTeam?.id,
      userId: currentUser?.id,
      unit: currentUnit,
      limit: 10,
    },
    onError(err) {
      console.error(err);
    },
  });

  // @ts-ignore
  const Icon = Icons[currentUnit?.icon ?? 'Paid'];

  const [deleteRegistration] = useMutation(DELETE_REGISTRATION);

  const deleteLastSale = (id: string) => {
    deleteRegistration({
      variables: {
        deleteRegistrationId: id,
      },
    });
    refreshData();
    notifier.addAlert(id, {
      type: 'success',
      message: 'Registrering slettet !',
      onClose: () => notifier.hideAlert(id),
      autoHideDuration: 3000,
    });
  };

  useEffect(() => {
    refetch();
  }, [total, refetch]);
  return (
    <div className='recent-sales'>
      <Typography variant='h4'>{`Siste ${currentUnit?.name}`}</Typography>
      {!loading ? (
        <List>
          {data?.registrations && data.registrations.length > 0 ? (
            data.registrations
              .slice(startIndex, startIndex + 5)
              .map((registration: Registration, i: number) => {
                return (
                  <ListItem className='user__body' key={i}>
                    <Tooltip
                      open={!registration?.comment ? false : undefined}
                      placement='top-start'
                      title={<span>{registration?.comment}</span>}
                    >
                      <div className='user__body--bg-color'>
                        <div className='user__body--profile'>
                          <Typography variant='body1' className='user--bg'>
                            {registration?.category ? (
                              registration?.category?.name === 'person' ? (
                                <PersonIcon color='primary' />
                              ) : registration?.category?.name === 'hus' ? (
                                <HouseIcon color='primary' />
                              ) : (
                                <Icon color='primary' />
                              )
                            ) : (
                              <Icon color='primary' />
                            )}
                          </Typography>
                        </div>
                        <div className='user__div'>
                          <div className='user__results'>
                            <div className='registration_date_container'>
                              <Typography className='registration_time'>
                                {registration.timestamp &&
                                  new Date(
                                    registration.timestamp
                                  ).toLocaleString('no-NO', {
                                    hour: '2-digit',
                                    minute: '2-digit',
                                  })}
                              </Typography>
                              <Typography className='registration_date'>
                                {registration.timestamp &&
                                  new Date(
                                    registration.timestamp
                                  ).toLocaleString('no-NO', {
                                    month: '2-digit',
                                    day: '2-digit',
                                    year: 'numeric',
                                  })}
                              </Typography>
                            </div>
                            <div className='registration_comment_container'>
                              <Typography className='registration_comment'>
                                {registration?.comment}
                              </Typography>
                              <Typography className='registration_category'>
                                {registration?.category?.name}
                              </Typography>
                            </div>
                            <div className='registration_value_container'>
                              <Typography className='registration_value'>{`${NumberFormatter.format(
                                registration.value ?? 0
                              )} ${currentUnit?.suffix}`}</Typography>
                            </div>
                            <div className='delete_button_container'>
                              <IconButton
                                className='delete_button'
                                aria-label='delete'
                                onClick={() => deleteLastSale(registration.id)}
                              >
                                <DeleteIcon color='error' />
                              </IconButton>
                            </div>
                          </div>
                          {/* <div className="line__style"></div> */}
                        </div>
                      </div>
                    </Tooltip>
                  </ListItem>
                );
              })
          ) : (
            <Typography className='placeholder-text'>
              Foreløpig ingen tall
            </Typography>
          )}
        </List>
      ) : (
        <div className='loader'>
          <CircularProgress color='primary' />
        </div>
      )}
      <Pagination
        className='pagination'
        color='primary'
        variant='outlined'
        count={Math.ceil((data?.registrations?.length ?? 0) / 5)}
        onChange={(_, value) => setStartIndex((value - 1) * 5)}
      />
    </div>
  );
};
