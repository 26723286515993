import React from 'react';

import Lottie from 'react-lottie';
import dog from 'assets/animations/dancing_dog.json';
import arrow from 'assets/animations/notification.json';
import giraffe from 'assets/animations/giraffe.json';
import gonggong from 'assets/animations/gonggong.json';
import snoop from 'assets/animations/snoop.json';
import gold_car from 'assets/animations/gold_car.json';
import runner from 'assets/animations/runner.json';

export interface AnimatorProps {
  animation: Animation;
}

export type Animation =
  | 'dog'
  | 'giraffe'
  | 'arrow'
  | 'gonggong'
  | 'snoop'
  | 'gold_car'
  | 'runner'
  | 'default';

export const AvailableAnimations: { [key in Animation]: any } = {
  dog: dog,
  arrow: arrow,
  giraffe: giraffe,
  gonggong: gonggong,
  snoop: snoop,
  gold_car: gold_car,
  runner: runner,
  default: snoop,
};

export const Animator: React.FC<AnimatorProps> = ({ animation }) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: AvailableAnimations[animation],
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return <Lottie width={450} height={450} options={defaultOptions} />;
};
