import * as Icons from '@mui/icons-material';
import { Grid, Paper, Typography, Box } from '@mui/material';
import { WidgetProps } from 'components/LayoutGrid/LayoutGrid';
import React from 'react';
import { renderDateString } from 'utils/DateHandler';
import { capitalizeFirst } from 'utils/Stringparser';
import NumberFormatter from 'utils/NumberFormatter';

export const CompetitionInfo: React.FC<WidgetProps> = ({ competition }) => {
  // @ts-ignore
  const SumIcon = Icons[competition?.unit?.icon ?? 'Paid'];
  return (
    <Paper className='competition_info'>
      <Box className='competition_info_header'>
        <Typography className='competition_info_title'>
          Konkurranseinfo
        </Typography>
        {competition?.status === 'FINISHED' && (
          <>
            <Typography
              color='primary'
              className='competition_info_finished_label'
            >
              Fullført
            </Typography>
            <Icons.DoneOutline
              color='primary'
              className='competition_info_finished_icon'
            />
          </>
        )}
      </Box>

      <Grid container className='competition_info_grid'>
        <Grid item xs={6} display='flex'>
          <Grid
            container
            direction={'column'}
            justifyContent='space-between'
            flex={1}
          >
            <Grid item xs={4} display='flex' alignItems='center'>
              <Icons.Info color='primary' />
              <Typography className='competition_info_text'>
                {competition?.name ?? 'Navn'}
              </Typography>
            </Grid>
            <Grid item xs={4} display='flex' alignItems='center'>
              {competition?.competitionType === 'INDIVIDUAL' ? (
                <Icons.Person color='primary' />
              ) : (
                <Icons.Groups color='primary' />
              )}
              <Typography className='competition_info_text'>
                {competition?.competitionType === 'INDIVIDUAL'
                  ? 'Individuell'
                  : 'Gruppe'}
              </Typography>
            </Grid>
            <Grid item xs={4} display='flex' alignItems='center'>
              {competition?.variant === 'TIME_BASED' ? (
                <Icons.AccessTime color='primary' />
              ) : (
                <Icons.SportsScore color='primary' />
              )}
              <Typography className='competition_info_text'>
                {capitalizeFirst(
                  competition?.variant === 'TIME_BASED'
                    ? 'Tidsbasert'
                    : 'Målbasert'
                )}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6} display='flex'>
          <Grid
            container
            direction={'column'}
            justifyContent='space-between'
            flex={1}
          >
            <Grid item xs={4} display='flex' alignItems='center'>
              <Icons.Sell color='primary' />
              <Typography className='competition_info_text'>
                {capitalizeFirst(competition?.unit?.name ?? '')}
              </Typography>
            </Grid>
            <Grid item xs={4} display='flex' alignItems='center'>
              {competition?.goal?.goalType === 'SALES_AMOUNT' ? (
                <SumIcon color='primary' />
              ) : (
                <Icons.Numbers color='primary' />
              )}
              <Typography className='competition_info_text'>
                {competition?.goal?.goalType === 'SALES_AMOUNT'
                  ? competition?.unit?.sumLabel ?? 'Verdi'
                  : competition?.unit?.countLabel ?? 'Antall'}
              </Typography>
            </Grid>
            <Grid item xs={4} display='flex' alignItems='center'>
              {competition?.variant === 'TIME_BASED' ? (
                <Icons.DateRange color='primary' />
              ) : (
                <Icons.CheckCircle color='primary' />
              )}
              <Typography className='competition_info_text'>
                {competition?.variant === 'TIME_BASED'
                  ? `${renderDateString(
                      competition?.startDate as string
                    )} - ${renderDateString(competition?.endDate as string)}`
                  : `${NumberFormatter.format(
                      competition?.goal?.amount ?? 0
                    )} ${competition?.goal?.suffix}`}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};
