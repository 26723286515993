import { DailyQuotes } from 'components/Quotes/DailyQuotes';
import { MemeOfTheDay } from 'components/Quotes/MemeOfTheDay';
import React from 'react';
import { useGlobalContext } from 'state/context/GlobalContext';

export const LeaderboardFooter: React.FC = () => {
  const { currentSettings } = useGlobalContext();
  return (
    <div className='leaderboard_footer'>
      {currentSettings?.quote?.aktiv && (
        <DailyQuotes category={currentSettings?.quote?.category} />
      )}
      {currentSettings?.meme?.aktiv && (
        <MemeOfTheDay
          image={currentSettings?.meme?.image}
          topText={currentSettings?.meme?.topText}
          bottomText={currentSettings?.meme?.bottomText}
        />
      )}
    </div>
  );
};
