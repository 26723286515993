import React, { useEffect, useState } from 'react';
import {
  Notification,
  NotificationProps,
} from 'components/Notification/Notification';
import { useLeaderboardContext } from 'state/context/LeaderboardContext';

export const NotificationQueue: React.FC = () => {
  const [currentOptions, setCurrentOptions] = useState<
    NotificationProps | undefined
  >();

  const { notifications, deleteNotification } = useLeaderboardContext();

  useEffect(() => {
    if (
      notifications.length > 0 &&
      currentOptions?.id !== notifications[0].id
    ) {
      setCurrentOptions(notifications[0]);
      setTimeout(() => deleteNotification(notifications[0].id), 15000);
    } else if (!notifications[0]) {
      setCurrentOptions(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notifications]);

  return <>{currentOptions && <Notification {...currentOptions} />}</>;
};
