export type DateFilter =
  | 'MONTH'
  | 'WEEK'
  | 'YEAR'
  | 'ALL_TIME'
  | 'LAST_MONTH'
  | 'LAST_WEEK';

export const TranslateDatefilterMap: { [key in DateFilter]: string } = {
  WEEK: 'Ukentlig',
  MONTH: 'Månedlig',
  YEAR: 'Årlig',
  ALL_TIME: 'Alle tider',
  LAST_MONTH: 'Forrige måned',
  LAST_WEEK: 'Forrige uke',
};

export type DateRange = {
  dateFrom?: Date | string | number | null | undefined;
  dateTo?: Date | string | number | null | undefined;
};

export type StatzVariant = 'SALES_AMOUNT' | 'SALES_COUNT' | 'CUSTOM';
export type MeasurementVariant = 'CATEGORY' | 'UNIT' | string;
export const TranslateVariantMap: { [key in StatzVariant]: string } = {
  SALES_AMOUNT: 'Verdi',
  SALES_COUNT: 'Antall',
  CUSTOM: '',
};

export type CompetitionVariant = 'TIME_BASED' | 'GOAL_BASED' | 'MANUAL';
