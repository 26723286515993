import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export type Dataset = {
  data?: number[];
  name: string;
  color: string;
};

interface LineChartProps {
  labels: string[];
  datasets: Dataset[];
  title?: string;
  hideLegends?: boolean;
  disableAutoSkip?: boolean;
  disableAspectRatio?: boolean;
}

export const LineChart: React.FC<LineChartProps> = ({
  datasets,
  labels,
  title,
  hideLegends,
  disableAutoSkip,
  disableAspectRatio,
}) => {
  const options = {
    responsive: true,
    maintainAspectRatio: !disableAspectRatio,
    elements: {
      point: {
        radius: 0,
      },
      line: {
        tension: 0.4,
      },
    },
    scales: {
      x: {
        ticks: {
          autoSkip: !disableAutoSkip,
          minRotation: disableAutoSkip ? 0 : 30,
          maxRotation: disableAutoSkip ? 0 : 50,
        },
      },
    },
    plugins: {
      legend: {
        position: 'top' as const,
        display: !hideLegends,
      },
      title: {
        display: title ? true : false,
        text: title ?? '',
      },
    },
  };

  const chartData = {
    labels,
    datasets: datasets.map((set) => ({
      label: set.name ?? undefined,
      data: set.data,
      borderColor: set.color,
      backgroundColor: set.color,
    })),
  };
  return <Line options={options} data={chartData} />;
};
