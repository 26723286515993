import { gql } from '@apollo/client';

export const SETTINGS = gql`
  query Query($teamId: ID) {
    team(id: $teamId) {
      settings {
        meme {
          aktiv
          topText
          bottomText
          image
        }
        layouts {
          _id
          name
          dateFilter
          unit {
            name
            id
            suffix
            sumLabel
            countLabel
            newRegistrationLabel
            icon
          }
          widgets {
            i
            x
            y
            w
            h
          }
          cols
          rows
          competition
        }
        leaderboard {
          defaultLayout
          loop
          loopTime
          refresh
        }
        background {
          aktiv
          loopTime
          loop
          images
        }
        quote {
          aktiv
          category
        }
        spotify {
          aktiv
          token
          playlist
        }
      }
    }
  }
`;
