import React from 'react';
import logo from '../../../assets/images/logo-colored.png';
import { IconButton, Tooltip } from '@mui/material';
import { EmojiEvents, Logout, Person, Settings } from '@mui/icons-material';
import { useGlobalContext } from 'state/context/GlobalContext';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';

import { useNavigate } from 'react-router-dom';
import { Navbar } from 'components/layout/Navbar/Navbar';

import { useMutation } from '@apollo/client';
import { LOGOUT } from '../../../graphql/mutations';
import { useNotificationContext } from '../../../state/context/NoticifationContext';
import { useFrameContext } from 'state/context/FrameContext';
import { routes } from 'pages/routes';

type Props = {
  drawerWidth: string | number;
  navbarHeight: string | number;
  hideSideBar: boolean;
};

export const SideBar: React.FC<Props> = ({
  drawerWidth,
  navbarHeight,
  hideSideBar,
}: Props) => {
  const { loggedIn, clearAuth, currentUser } = useGlobalContext();
  const notifier = useNotificationContext();
  let navigate = useNavigate();
  const Admin = () => navigate(routes.admin, { replace: true });
  const Profile = () => navigate(routes.profile, { replace: true });
  const Trophies = () => navigate(routes.trophies, { replace: true });

  const { SidebarInjection, Fullscreen } = useFrameContext();

  const handleLogout = () => {
    logout()
      .then((res) => {
        if (res.data.logout) {
          clearAuth();
        } else {
          // handle this
        }
      })
      .catch((err) => {
        let notifId = 'logout-error';
        notifier.addAlert(notifId, {
          type: 'error',
          title: 'Noe gikk galt',
          message: 'Kunne ikke logge ut',
          onClose: () => notifier.hideAlert(notifId),
          autoHideDuration: 3000,
        });
        console.error(err);
      });
  };

  const [logout] = useMutation(LOGOUT);

  const userIsIncomplete = () => {
    if (
      (!currentUser?.username || currentUser?.username === '') &&
      (!currentUser?.firstName || currentUser?.firstName === '') &&
      !Fullscreen
    ) {
      return true;
    } else {
      return false;
    }
  };
  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <Navbar
        drawerWidth={drawerWidth}
        navbarHeight={navbarHeight}
        hideSideBar={hideSideBar}
      />
      {hideSideBar && (
        <Drawer
          sx={{
            width: drawerWidth,
            position: 'absolute',
            flexShrink: 0,

            '& .MuiDrawer-paper': {
              //border: 0,
              borderRightStyle: 'solid',
              borderRightColor: '#707070',
              overflow: 'hidden',
              borderWidth: 1,
              width: drawerWidth,
              boxSizing: 'border-box',
              background:
                'linear-gradient(121deg, #313132 0%, #000000 100%) 0% 0% no-repeat padding-box',
            },
          }}
          variant='permanent'
          anchor='left'
        >
          <header className='header'>
            <a href='https://statz.no'>
              <img src={logo} className='logo-header' alt='statz' />
            </a>
          </header>

          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
              justifyContent: 'space-evenly',
            }}
          >
            {SidebarInjection}
          </div>

          {loggedIn && (
            <div className='header__icons'>
              <Tooltip
                arrow={true}
                placement='top'
                title={
                  userIsIncomplete()
                    ? 'Fullfør din brukerprofil for en best mulig brukeropplevelse'
                    : 'Brukerinnstillinger'
                }
                open={userIsIncomplete() ?? undefined}
              >
                <IconButton className='icon_button' onClick={Profile}>
                  <Person />
                </IconButton>
              </Tooltip>
              <Tooltip title='Dine utmerkelser'>
                <IconButton className='icon_button' onClick={Trophies}>
                  <EmojiEvents />
                </IconButton>
              </Tooltip>
              {currentUser?.admin && (
                <Tooltip title='Administrator'>
                  <IconButton className='icon_button' onClick={Admin}>
                    <Settings />
                  </IconButton>
                </Tooltip>
              )}
              <Tooltip title='Logg ut'>
                <IconButton className='icon_button' onClick={handleLogout}>
                  <Logout />
                </IconButton>
              </Tooltip>
            </div>
          )}
        </Drawer>
      )}
    </Box>
  );
};
