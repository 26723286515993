import React, { useEffect, useState, useRef } from 'react';
import { ADD_COMPETITION } from 'graphql/mutations/Competition/addCompetition';
import { EDIT_COMPETITION } from 'graphql/mutations';
import { useGlobalContext } from 'state/context/GlobalContext';
import { useNotificationContext } from 'state/context/NoticifationContext';
import { CompetitionVariant } from 'types/leaderboard';
import { Competition, Prize, Settings } from 'types/team';
import DateAdapter from '@mui/lab/AdapterDateFns';
import { useAdminContext } from '../../../state/context/AdminContext';
import NumberFormat from 'react-number-format';

import { useMutation } from '@apollo/client';
import { Add, Delete } from '@mui/icons-material';
import { LocalizationProvider, DateTimePicker } from '@mui/lab';
import Fab from '@mui/material/Fab';
import CloseIcon from '@mui/icons-material/Close';
import nbLocale from 'date-fns/locale/nb';
import { Category, Unit } from 'types/team';

import {
  Grid,
  Typography,
  TextField,
  FormControl,
  Select,
  MenuItem,
  FormLabel,
  RadioGroup,
  Tooltip,
  FormControlLabel,
  Radio,
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  IconButton,
  CircularProgress,
  StepLabel,
  Step,
  Stepper,
  Box,
  Stack,
  ButtonGroup,
  useTheme,
} from '@mui/material';
import { capitalizeFirst } from 'utils/Stringparser';

type AddCompetitionTypes = {
  competition: Competition | null | undefined;
  close: () => void;
  edit: boolean;
};

interface SUMPROPS {
  item?: string;
  value?: Date | string | undefined | null;
}

export const CompetitionStepper: React.FC<AddCompetitionTypes> = ({
  competition,
  close,
  edit,
}) => {
  const notifier = useNotificationContext();
  const { setBottomView, refreshCompetitions } = useAdminContext();
  const { currentTeam, currentUnit, currentSettings, refetchTeam } =
    useGlobalContext();
  const theme = useTheme();
  const settingsRef = useRef<Settings | null | undefined>();
  settingsRef.current = currentSettings;

  // REFS

  const startTimeRef = useRef<HTMLInputElement>(null);

  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set<number>());
  const [compData, setCompData] = useState<Competition>(
    competition
      ? competition
      : {
          variant: 'TIME_BASED',
          unit: currentUnit,
          category: undefined,
          name: '',
          competitionType: 'TEAM',
          goal: {
            goalType: 'SALES_AMOUNT',
            suffix: 'Kr',
            amount: 0,
          },
          startDate: new Date(),
          endDate: new Date(),
          status: 'ONGOING',
          prizes: [],
          teamId: currentTeam?.id,
        }
  );
  const [newPrize, setNewPrize] = useState<Prize>({
    position: 1,
    description: '',
  });
  const [availablePositions, setAvailablePositions] = useState<number[]>([
    1, 2, 3, 4, 5,
  ]);

  let steps = ['Informasjon', 'Varighet', 'Måleenhet', 'Sammendrag'];

  const isStepOptional = (step: number) => {
    return false;
  };

  const isStepSkipped = (step: number) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const [addCompetition, { loading }] = useMutation(ADD_COMPETITION);
  const [editCompetition] = useMutation(EDIT_COMPETITION);

  const addPrize = (prize: Prize) => {
    let newData = { ...compData };
    let newPrizes = newData.prizes ? [...newData.prizes] : [];
    newPrizes.push(prize);
    newData.prizes = newPrizes;
    setCompData(newData);
    let newPositions = [...availablePositions].filter(
      (pos) => pos !== prize.position
    );

    setAvailablePositions(newPositions);
  };

  const removePrize = (prize: Prize) => {
    let newData = { ...compData };
    newData.prizes = newData.prizes?.filter(
      (prz) => prz.position !== prize.position
    );
    setCompData(newData);
    setAvailablePositions([...availablePositions, prize.position].sort());
  };

  const handleSave = () => {
    if (edit) {
      editCompetition({
        variables: {
          competition: compData,
        },
      })
        .then((res: any) => {
          notifier.addAlert(compData.id ?? 'EDIT_COMP', {
            type: 'success',
            message: 'Konkurransen ble endret',
            onClose: () => notifier.hideAlert(compData.id ?? 'EDIT_COMP'),
            autoHideDuration: 3000,
          });
          setBottomView(null);
          refreshCompetitions();
          close();
        })
        .catch((error) => {
          console.error(JSON.stringify(error, null, 2));
        });
    } else {
      addCompetition({
        variables: {
          competition: compData,
          includeInLayouts: true,
        },
      })
        .then((res: any) => {
          const comp = res.data.addCompetition;
          notifier.addAlert(comp.id, {
            type: 'success',
            message: 'Konkurransen ble lagt til',
            onClose: () => notifier.hideAlert(comp.id),
            autoHideDuration: 3000,
          });
          setCompData({
            variant: 'TIME_BASED',
            measurement: currentUnit,
            measurementType: 'UNIT',
            competitionType: 'INDIVIDUAL',
            goal: {
              goalType: 'SUM',
            },
            name: '',
            startDate: new Date(),
            endDate: new Date(),
            prizes: [],
            teamId: currentTeam?.id,
          });
          setAvailablePositions([1, 2, 3, 4, 5]);
          refreshCompetitions();
          refetchTeam();
          setBottomView(null);
          close();
        })
        .catch((error) => {
          console.error(JSON.stringify(error, null, 2));
        });
    }
  };

  useEffect(() => {
    setNewPrize({
      position: availablePositions[0],
      description: '',
    });
  }, [availablePositions]);

  const Summary: React.FC<SUMPROPS> = ({ item, value }) => {
    return (
      <Stack
        direction='row'
        justifyContent='space-between'
        alignItems='center'
        spacing={2}
      >
        <Typography className='placeholder-text'>{item} : </Typography>
        <Typography className='placeholder-text'>{value}</Typography>
      </Stack>
    );
  };

  return (
    <Grid
      container
      direction='row'
      justifyContent='space-between'
      alignItems='center'
      className='admin-overview addCompetition'
      spacing={2}
      style={{
        backgroundColor: 'transparent',
        padding: 20,
      }}
    >
      <Fab
        sx={{ position: 'fixed', right: 10, top: 10 }}
        size='small'
        aria-label='add'
        onClick={() => close()}
      >
        <CloseIcon />
      </Fab>
      <Grid item xs={12}>
        <Typography variant='h5'>
          {edit ? 'Endre' : 'Opprett'} konkurranse
        </Typography>
        <Typography
          style={{ color: theme.palette.primary.main, opacity: 0.7 }}
          fontStyle={'italic'}
        >
          {steps[activeStep]}
        </Typography>
      </Grid>
      <Grid item xs={12} md={3}>
        <Stepper orientation='vertical' activeStep={activeStep}>
          {steps.map((label, index) => {
            const stepProps: { completed?: boolean } = {};
            const labelProps: {
              optional?: React.ReactNode;
            } = {};
            if (isStepOptional(index)) {
              labelProps.optional = (
                <Typography variant='caption'>Optional</Typography>
              );
            }
            if (isStepSkipped(index)) {
              stepProps.completed = false;
            }
            return (
              <Step last key={label} {...stepProps}>
                <StepLabel {...labelProps}>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
      </Grid>
      <Grid item xs={9} height='80%'>
        <Grid container alignItems='center' justifyContent='center'>
          <Grid
            direction='row'
            justifyContent='space-between'
            alignItems='center'
            container
            style={{
              width: '100%',
              margin: 15,
              textAlign: 'center',
              minHeight: 300,
            }}
          >
            {activeStep === 0 && (
              <Grid item xs={12}>
                <Stack
                  direction='column'
                  justifyContent='center'
                  alignItems='center'
                  spacing={8}
                >
                  <FormControl style={{ textAlign: 'left' }}>
                    <FormLabel>Navn på konkurransen</FormLabel>
                    <TextField
                      placeholder='eks: ukeskonkurranse'
                      value={compData.name}
                      onChange={(e) => {
                        let newData = { ...compData };
                        newData.name = e.target.value;
                        setCompData(newData);
                      }}
                    />
                  </FormControl>
                  <FormControl>
                    <RadioGroup
                      row={true}
                      value={compData?.competitionType}
                      onChange={(e) => {
                        let newData = { ...compData };
                        newData.competitionType = e.target.value as
                          | 'TEAM'
                          | 'INDIVIDUAL';
                        setCompData(newData);
                      }}
                      name='radio-buttons-group'
                    >
                      <Tooltip title='Teamet konkurrerer sammen mot et mål'>
                        <FormControlLabel
                          value='TEAM'
                          control={<Radio color='secondary' />}
                          label='Lagkonkurranse'
                        />
                      </Tooltip>
                      <Tooltip title='Teammedlemmene konkurrerer mot hverandre'>
                        <FormControlLabel
                          value='INDIVIDUAL'
                          control={<Radio color='secondary' />}
                          label='Individuell konkurranse'
                        />
                      </Tooltip>
                    </RadioGroup>
                  </FormControl>
                </Stack>
              </Grid>
            )}
            {activeStep === 1 && (
              <Grid item xs={12}>
                <Grid
                  container
                  direction='row'
                  justifyContent='center'
                  alignItems='center'
                  spacing={3}
                >
                  <Grid item xs={12}>
                    <FormControl>
                      <FormLabel color='secondary'>Konkurransetype</FormLabel>
                      <RadioGroup
                        row
                        value={compData?.variant}
                        onChange={(e) => {
                          let newData = { ...compData };
                          newData.variant = e.target
                            .value as CompetitionVariant;
                          if (newData.variant === 'GOAL_BASED')
                            newData.endDate = null;
                          setCompData(newData);
                        }}
                        name='radio-buttons-group'
                      >
                        <Tooltip title='Konkurransen pågar en gitt tidsperiode'>
                          <FormControlLabel
                            value='TIME_BASED'
                            control={<Radio color='secondary' />}
                            label='Tidsbasert'
                          />
                        </Tooltip>
                        <Tooltip title='Konkurransen pågår til førstemann har oppnådd et gitt mål'>
                          <FormControlLabel
                            value='GOAL_BASED'
                            control={<Radio color='secondary' />}
                            label='Målbasert'
                          />
                        </Tooltip>
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl ref={startTimeRef}>
                      <LocalizationProvider
                        dateAdapter={DateAdapter}
                        locale={nbLocale}
                      >
                        <FormLabel>Starttidspunkt</FormLabel>
                        <DateTimePicker
                          renderInput={(props) => <TextField {...props} />}
                          value={compData?.startDate}
                          onChange={(newValue) => {
                            let newData = { ...compData };
                            newData.startDate = newValue;
                            setCompData(newData);
                          }}
                        />
                      </LocalizationProvider>
                    </FormControl>
                  </Grid>

                  {compData?.variant === 'TIME_BASED' && (
                    <Grid item xs={6}>
                      <FormControl>
                        <LocalizationProvider
                          dateAdapter={DateAdapter}
                          locale={nbLocale}
                        >
                          <FormLabel>Sluttidspunkt</FormLabel>
                          <DateTimePicker
                            renderInput={(props) => <TextField {...props} />}
                            value={compData?.endDate}
                            onChange={(newValue) => {
                              let newData = { ...compData };
                              newData.endDate = newValue;
                              setCompData(newData);
                            }}
                          />
                        </LocalizationProvider>
                      </FormControl>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            )}
            {activeStep === 2 && (
              <>
                <Grid item xs={12}>
                  <Stack
                    direction='column'
                    justifyContent='center'
                    alignItems='center'
                    spacing={3}
                  >
                    <FormControl>
                      {/*  <FormLabel>Måleenhet</FormLabel> */}
                      <RadioGroup
                        row
                        value={compData?.goal?.goalType}
                        onChange={(e) => {
                          let newData = { ...compData };
                          let data = {
                            ...compData.goal,
                            goalType: e.target.value,
                          };
                          newData.goal = data as any;
                          setCompData(newData);
                        }}
                        name='radio-buttons-group'
                      >
                        <Tooltip
                          title={`Konkurransen måler ${
                            compData?.unit?.sumLabel?.toLowerCase() ??
                            'total verdi'
                          }`}
                        >
                          <FormControlLabel
                            value='SALES_AMOUNT'
                            control={<Radio color='secondary' />}
                            label={compData?.unit?.sumLabel ?? 'Verdi'}
                          />
                        </Tooltip>
                        <Tooltip
                          title={`Konkurransen måler ${
                            compData?.unit?.countLabel?.toLowerCase() ??
                            'antall'
                          }`}
                        >
                          <FormControlLabel
                            value='SALES_COUNT'
                            control={<Radio color='secondary' />}
                            label={compData?.unit?.countLabel ?? 'Antall'}
                          />
                        </Tooltip>
                      </RadioGroup>
                    </FormControl>
                    <Grid
                      container
                      flexDirection='row'
                      alignItems='center'
                      justifyContent='space-evenly'
                    >
                      <Grid item xs={12} md={4}>
                        <FormControl fullWidth>
                          <FormLabel>Måleenhet</FormLabel>
                          <Select
                            value={compData?.unit?.name}
                            placeholder='Målepunkt'
                            sx={{ minWidth: 30 }}
                            onChange={(event) => {
                              let newData = { ...compData };
                              let nextUnit: Unit | null | undefined;

                              nextUnit = currentTeam?.units?.find(
                                (unit) => unit.name === event.target.value
                              );
                              newData.unit = nextUnit ?? null;

                              setCompData(newData);
                            }}
                          >
                            {currentTeam?.units?.map((item) => {
                              return (
                                <MenuItem key={item.name} value={item.name}>
                                  {item.name}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <FormControl fullWidth>
                          <FormLabel>Kategori</FormLabel>
                          <Select
                            value={compData?.category?.name ?? 'Alle'}
                            placeholder='Målepunkt'
                            sx={{ minWidth: 30 }}
                            onChange={(event) => {
                              let newData = { ...compData };
                              let nextUnit: Category | null | undefined;

                              nextUnit = currentTeam?.categories?.find(
                                (unit) => unit.name === event.target.value
                              );
                              newData.category = nextUnit ?? null;
                              setCompData(newData);
                            }}
                          >
                            <MenuItem value={'Alle'}>Alle</MenuItem>
                            {currentTeam?.categories?.map((item) => {
                              return (
                                <MenuItem key={item.name} value={item.name}>
                                  {item.name}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>

                    {compData?.variant === 'GOAL_BASED' ? (
                      <FormControl>
                        <FormLabel>Mål</FormLabel>
                        <NumberFormat
                          value={compData?.goal?.amount}
                          onValueChange={(values) => {
                            let newData = { ...compData };
                            let data = {
                              ...compData.goal,
                              amount: parseInt(values.value),
                              suffix:
                                compData?.goal?.goalType === 'QUANTITY'
                                  ? 'stk'
                                  : 'Kr',
                            };
                            newData.goal = data as any;
                            setCompData(newData);
                          }}
                          suffix={
                            compData?.goal?.goalType === 'QUANTITY'
                              ? 'stk'
                              : 'Kr'
                          }
                          customInput={TextField}
                          thousandSeparator=' '
                        />
                      </FormControl>
                    ) : null}
                  </Stack>
                </Grid>
              </>
            )}

            {activeStep === 99 && (
              <>
                <Grid item xs={6}>
                  <Grid container spacing={3} direction='column'>
                    <Grid item xs={12}>
                      <FormLabel>Velg premier</FormLabel>
                    </Grid>
                    <Grid item xs={4}>
                      <FormControl fullWidth>
                        <Select
                          value={newPrize.position}
                          onChange={(e) => {
                            let next = { ...newPrize };
                            next.position = e.target.value as number;
                            setNewPrize(next);
                          }}
                        >
                          {availablePositions.map((pos) => {
                            let alreadyExists = compData.prizes?.find(
                              (prize) => prize.position === pos
                            );
                            if (alreadyExists) {
                              return null;
                            }
                            return (
                              <MenuItem
                                key={pos}
                                value={pos}
                              >{`${pos}. plass`}</MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={8}>
                      <TextField
                        type='text'
                        placeholder='Beskrivelse'
                        value={newPrize.description}
                        onChange={(e) => {
                          let next = { ...newPrize };
                          next.description = e.target.value;
                          setNewPrize(next);
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Button
                        startIcon={<Add />}
                        onClick={() => addPrize(newPrize)}
                      >
                        Legg til premie
                      </Button>
                    </Grid>
                  </Grid>
                  <FormControl></FormControl>
                </Grid>
                <Grid item xs={6}>
                  <Grid container spacing={3} style={{ borderStyle: 'solid' }}>
                    <Grid item xs={12}>
                      <FormLabel>Valgte premier</FormLabel>
                    </Grid>
                    {compData.prizes && compData.prizes.length > 0 ? (
                      <List>
                        {compData.prizes.map((prize, index) => {
                          return (
                            <ListItem key={index}>
                              <ListItemIcon
                                style={{ paddingLeft: 20, paddingRight: 20 }}
                              >
                                <Typography>{`${prize.position}. plass`}</Typography>
                              </ListItemIcon>
                              <ListItemText>
                                <Typography>{prize.description}</Typography>
                              </ListItemText>
                              <ListItemIcon>
                                <IconButton
                                  color={'error'}
                                  onClick={() => removePrize(prize)}
                                >
                                  <Delete />
                                </IconButton>
                              </ListItemIcon>
                            </ListItem>
                          );
                        })}
                      </List>
                    ) : (
                      <Grid item xs={12}>
                        <Typography className='placeholder-text'>
                          Ingen premier lagt til
                        </Typography>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </>
            )}
            {activeStep === 3 && (
              <>
                <Grid item xs={12}>
                  <Stack
                    direction='column'
                    justifyContent='center'
                    alignItems='center'
                    spacing={2}
                  >
                    <Summary item='Navn' value={compData.name} />
                    <Summary
                      item='Format'
                      value={
                        compData.competitionType === 'TEAM'
                          ? 'Lagkonkurranse'
                          : 'Individuell konkurranse'
                      }
                    />
                    <Summary
                      item='Konkurransetype'
                      value={
                        compData.variant === 'TIME_BASED'
                          ? 'Tidsbasert'
                          : 'Målbasert'
                      }
                    />

                    <Summary
                      item='Kategori'
                      value={capitalizeFirst(
                        compData?.category?.name ?? 'Alle'
                      )}
                    />
                    <Summary
                      item='Målemetode'
                      value={
                        compData?.goal?.goalType === 'SALES_AMOUNT'
                          ? compData?.unit?.sumLabel ?? 'Verdi'
                          : compData?.unit?.countLabel ?? 'Antall'
                      }
                    />
                    <Summary
                      item='Måleenhet'
                      value={capitalizeFirst(compData?.unit?.name ?? '')}
                    />
                    {compData?.variant === 'GOAL_BASED' && (
                      <Summary
                        item='Mål'
                        value={
                          compData?.goal?.amount + ' ' + compData?.goal?.suffix
                        }
                      />
                    )}
                    <Summary
                      item='Starttidspunkt'
                      value={new Date(
                        compData?.startDate as Date
                      ).toLocaleString('no-NO', {
                        hour: '2-digit',
                        minute: '2-digit',
                        month: '2-digit',
                        day: '2-digit',
                        year: 'numeric',
                      })}
                    />
                    {compData?.variant === 'GOAL_BASED' ? null : (
                      <Summary
                        item='Sluttidspunkt'
                        value={new Date(
                          compData?.endDate as Date
                        ).toLocaleString('no-NO', {
                          hour: '2-digit',
                          minute: '2-digit',
                          month: '2-digit',
                          day: '2-digit',
                          year: 'numeric',
                        })}
                      />
                    )}
                  </Stack>
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          {activeStep === steps.length ? (
            <React.Fragment>
              <Typography sx={{ mt: 2, mb: 1 }}>Ferdig</Typography>
              <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                <Box sx={{ flex: '1 1 auto' }} />
                <Button onClick={handleReset}>Reset</Button>
              </Box>
            </React.Fragment>
          ) : (
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              {/* <Typography sx={{ mt: 2, mb: 1 }}>Steg {activeStep + 1}</Typography> */}
              <ButtonGroup
                color='primary'
                variant='text'
                style={{ alignSelf: 'flex-end' }}
              >
                <Button
                  /* color='info' */
                  disabled={activeStep === 0}
                  onClick={handleBack}
                >
                  Tilbake
                </Button>
                <Button
                  style={{
                    color:
                      activeStep === steps.length - 1 ? '#7ffd3f' : undefined,
                  }}
                  disabled={compData?.competitionType === '' ? true : false}
                  endIcon={loading && <CircularProgress size='small' />}
                  onClick={
                    activeStep === steps.length - 1 ? handleSave : handleNext
                  }
                >
                  {activeStep === steps.length - 1 ? 'Lagre' : 'Neste'}
                </Button>
              </ButtonGroup>
            </div>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};
