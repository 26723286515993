import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { useGlobalContext } from '../../../state/context/GlobalContext';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { NavbarCollapse } from 'components/layout/Navbar/NavbarCollapse';
import MenuIcon from '@mui/icons-material/Menu';
import IconButton from '@mui/material/IconButton';
import FastfoodIcon from '@mui/icons-material/Fastfood';

import { useFrameContext } from 'state/context/FrameContext';
import { routes } from 'pages/routes';

import logo from 'assets/images/logo-colored.png';
import { Box } from '@mui/material';

type Props = {
  drawerWidth: string | number;
  navbarHeight: string | number;
  hideSideBar: boolean;
};

export const Navbar: React.FC<Props> = ({
  drawerWidth,
  navbarHeight,
  hideSideBar,
}: Props) => {
  const { loggedIn } = useGlobalContext();
  const { Fullscreen } = useFrameContext();

  const { NavbarInjection } = useFrameContext();
  const [openCollapsed, setOpenCollapsed] = React.useState<boolean>(false);
  const location = useLocation();

  return (
    <AppBar
      position='static'
      sx={{
        width: `calc(100%)`,
        height: 0,
        boxShadow: 'none',
      }}
    >
      {!Fullscreen ? (
        <Toolbar>
          <NavbarCollapse
            open={openCollapsed}
            close={() => setOpenCollapsed(false)}
          />
          {NavbarInjection}

          <nav className={'nav'}>
            {/* {location.pathname === routes.dashboard && (
            <div className='nav-dashboard-background'></div>
          )} */}

            <ul className='nav-links'>
              {hideSideBar ? (
                <>
                  <li className={`nav-link ${loggedIn ? 'closed' : ''}`}>
                    <a href='https://statz.no'>Hjem</a>
                  </li>
                  <li
                    className={`nav-link login-link ${
                      loggedIn ? 'closed' : ''
                    }`}
                  >
                    <NavLink to={routes.login}>Login</NavLink>
                  </li>

                  <div
                    className={`dashboard__menu ${loggedIn ? '' : 'closed'}`}
                  >
                    <li
                      className='nav-link'
                      style={{
                        backgroundColor:
                          location.pathname === routes.leaderboard
                            ? 'rgba(0, 0, 0, 0.3)'
                            : undefined,
                        padding: 10,
                        borderRadius: 10,
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <NavLink to={routes.dashboard}>Dashboard </NavLink>
                    </li>
                    <li
                      className='nav-link'
                      style={{
                        backgroundColor:
                          location.pathname === routes.leaderboard
                            ? 'rgba(0, 0, 0, 0.3)'
                            : undefined,
                        padding: 10,
                        borderRadius: 10,
                      }}
                    >
                      <NavLink to={routes.leaderboard}>Leaderboard</NavLink>
                    </li>
                    <li
                      className={`nav-link `}
                      style={{
                        backgroundColor:
                          location.pathname === routes.leaderboard
                            ? 'rgba(0, 0, 0, 0.3)'
                            : undefined,
                        /*   padding: 10, */
                        borderRadius: 10,
                      }}
                    >
                      <IconButton
                        color='primary'
                        onClick={() => setOpenCollapsed(true)}
                        size='small'
                      >
                        {openCollapsed ? <FastfoodIcon /> : <MenuIcon />}
                      </IconButton>
                    </li>
                  </div>
                </>
              ) : (
                <>
                  <li className={`nav-link `}>
                    <IconButton
                      color='primary'
                      onClick={() => setOpenCollapsed(true)}
                    >
                      {openCollapsed ? <FastfoodIcon /> : <MenuIcon />}
                    </IconButton>
                  </li>
                </>
              )}
            </ul>
            <Box flex={1} height='100%'>
              <a href='https://statz.no'>
                <img
                  src={logo}
                  style={{ width: 'auto', height: '100%' }}
                  alt='logo'
                />
              </a>
            </Box>
          </nav>
        </Toolbar>
      ) : null}
    </AppBar>
  );
};
