import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { createGenericContext } from './createGenericContext';
import { User } from 'types/user';
import { AUTHENTICATE } from 'graphql/queries';
import { ApolloError, useLazyQuery, useQuery } from '@apollo/client';
import { Team, Settings } from 'types/team';
import { TEAM } from 'graphql/queries/Team/Team';
import { Unit } from '../../types/team';
import { client } from 'graphql/config';

interface GlobalContextType {
  loggedIn: boolean;
  loading: boolean;
  currentUser: User | null | undefined;
  currentTeam: Team | null | undefined;
  currentSettings: Settings | null | undefined;
  currentUnit: Unit | null | undefined;
  clearAuth: () => void;
  setUser: (user: User | null | undefined) => void;
  setTeam: (team: Team | null | undefined) => void;
  setSettings: (settings: Settings | null | undefined) => void;
  setLoggedIn: (loggedIn: boolean) => void;
  setLoading: (loading: boolean) => void;
  setCurrentUnit: (unit: Unit) => void;
  refetchTeam: () => void;
  displayName: string | undefined;
}

export const [useGlobalContext, BareGlobalContextProvider] =
  createGenericContext<GlobalContextType>();

export const GlobalContextProvider: React.FC = ({ children }) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [loggedIn, setLoggedIn] = useState<boolean>(false);
  const [currentUnit, setCurrentUnit] = useState<Unit | null>();
  const [user, setUser] = useState<User | null>();
  const [team, setTeam] = useState<Team | null>();
  const [settings, setSettings] = useState<Settings | null | undefined>();
  const [fetchTeam, { refetch }] = useLazyQuery(TEAM);

  // Reauthenticate user on every render
  useQuery(AUTHENTICATE, {
    onCompleted(data) {
      const { authenticateUser: userData } = data;
      if (userData) {
        setUser(userData);
      } else {
        handleClearAuth();
        setLoading(false);
      }
    },
    onError(error) {
      setLoggedIn(false);
      setUser(null);
      setLoading(false);
      console.error(JSON.stringify(error));
    },
  });

  const handleFetchTeam = useCallback(() => {
    fetchTeam({
      variables: {
        id:
          localStorage.getItem('currentTeam') ?? user?.team ?? user?.teams?.[0],
      },
    })
      .then((res: any) => {
        setTeam(res.data.team as Team);
        setSettings(res.data.team.settings as Settings);
        setCurrentUnit(
          res.data.team.units?.find(
            (x: Unit) => x.name === localStorage.getItem('currentUnit')
          ) ?? res.data.team.units[0]
        );
      })
      .catch((err: ApolloError) => {
        console.error(err);
        console.error(JSON.stringify(err, null, 2));
      });
  }, [fetchTeam, user?.team, user?.teams]);

  const handleRefetchTeam = useCallback(() => {
    refetch().then((res: any) => {
      setTeam(res?.data?.team as Team);
      setSettings(res?.data?.team?.settings as Settings);
    });
  }, [refetch]);

  // On user change, fetch team and log in
  useEffect(() => {
    if (user) {
      handleFetchTeam();
    }
  }, [fetchTeam, handleFetchTeam, user]);

  useEffect(() => {
    if (user && team && currentUnit && settings) {
      setLoggedIn(true);
      setLoading(false);
    }
  }, [team, user, currentUnit, settings]);

  const handleClearAuth = useCallback(() => {
    setLoggedIn(false);
    setUser(null);
    setTeam(null);
    setSettings(null);
    client.clearStore().then(() => {
      console.log('store cleared');
    });
  }, []);

  const handleSetUser = useCallback((user: User | null | undefined) => {
    setUser(user);
  }, []);

  const handleSetTeam = useCallback((team: Team | null | undefined) => {
    setTeam(team);
  }, []);

  const handleSetSettings = useCallback(
    (newSettings: Settings | null | undefined) => {
      setSettings((prevState: any) => {
        return {
          ...prevState,
          ...newSettings,
        };
      });
    },
    []
  );

  const handleSetLoggedIn = useCallback((loggedIn: boolean) => {
    setLoggedIn(loggedIn);
  }, []);

  const handleSetLoading = useCallback((loading: boolean) => {
    setLoading(loading);
  }, []);

  const handleSetCurrentUnit = useCallback((unit: Unit) => {
    setCurrentUnit(unit);
    if (unit.name) {
      localStorage.setItem('currentUnit', unit.name);
    } else {
      localStorage.removeItem('currentUnit');
    }
  }, []);

  const displayName = useCallback(() => {
    if (user?.username) {
      return user?.username;
    } else if (user?.firstName && user?.lastName) {
      return `${user?.firstName} ${user?.lastName}`;
    } else {
      return user?.email;
    }
  }, [user?.email, user?.firstName, user?.lastName, user?.username]);

  const value: GlobalContextType = useMemo(
    () => ({
      loggedIn,
      loading: loading,
      currentUser: user,
      currentTeam: team,
      currentSettings: settings,
      currentUnit: currentUnit,
      clearAuth: handleClearAuth,
      setUser: handleSetUser,
      setTeam: handleSetTeam,
      setSettings: handleSetSettings,
      setLoggedIn: handleSetLoggedIn,
      setLoading: handleSetLoading,
      setCurrentUnit: handleSetCurrentUnit,
      refetchTeam: handleRefetchTeam,
      displayName: displayName(),
    }),
    [
      loggedIn,
      loading,
      user,
      team,
      settings,
      currentUnit,
      handleClearAuth,
      handleSetUser,
      handleSetTeam,
      handleSetSettings,
      handleSetLoggedIn,
      handleSetLoading,
      handleSetCurrentUnit,
      handleRefetchTeam,
      displayName,
    ]
  );
  return (
    <BareGlobalContextProvider value={value}>
      {children}
    </BareGlobalContextProvider>
  );
};
