import { DateFilter, DateRange } from '../types/leaderboard';

export const parseDateFilter = (filter: DateFilter | null | undefined) => {
  let today = new Date();
  switch (filter) {
    case 'MONTH': {
      let dateFrom = new Date(today.getFullYear(), today.getMonth(), 1);
      let dateTo = new Date(today.getFullYear(), today.getMonth() + 1, 1);
      return { dateFrom, dateTo } as DateRange;
    }
    case 'WEEK': {
      let day = today.getDay();
      let diffStart = today.getDate() - day + (day === 0 ? -6 : 1);
      let diffEnd = diffStart + 7;

      let dateFrom = new Date();
      let dateTo = new Date();
      dateFrom.setDate(diffStart);
      dateFrom.setHours(0, 0, 0, 0);
      dateTo.setDate(diffEnd);
      dateTo.setHours(0, 0, 0, 0);

      return {
        dateFrom: dateFrom.toISOString(),
        dateTo: dateTo.toISOString(),
      } as DateRange;
    }
    case 'YEAR': {
      let dateFrom = new Date(today.getFullYear(), 0, 1).toISOString();
      let dateTo = new Date(today.getFullYear() + 1, 0, 1).toISOString();
      return { dateFrom, dateTo } as DateRange;
    }
    case 'ALL_TIME': {
      let dateFrom = null;
      let dateTo = null;
      return { dateFrom, dateTo } as DateRange;
    }
    case 'LAST_MONTH': {
      let dateFrom = new Date(today.getFullYear(), today.getMonth() - 1, 1);
      let dateTo = new Date(today.getFullYear(), today.getMonth(), 1);
      return { dateFrom: dateFrom.toISOString(), dateTo: dateTo.toISOString() };
    }
    case 'LAST_WEEK': {
      let day = today.getDay();
      let diffStart = today.getDate() - day - 7 + (day === 0 ? -6 : 1);
      let diffEnd = diffStart + 7;

      let dateFrom = new Date();
      let dateTo = new Date();
      dateFrom.setDate(diffStart);
      dateFrom.setHours(0, 0, 0, 0);
      dateTo.setDate(diffEnd);
      dateTo.setHours(0, 0, 0, 0);

      return {
        dateFrom: dateFrom.toISOString(),
        dateTo: dateTo.toISOString(),
      } as DateRange;
    }
    default: {
      return {
        dateFrom: null,
        dateTo: null,
      } as DateRange;
    }
  }
};

export const renderFilterLabel = (filter: DateFilter | null | undefined) => {
  switch (filter) {
    case 'MONTH':
      return 'måned';
    case 'WEEK':
      return 'uke';
    case 'YEAR':
      return 'år';
    case 'LAST_WEEK':
      return 'forrige uke';
    case 'LAST_MONTH':
      return 'forrige måned';
    case 'ALL_TIME':
      return 'alle';
    default:
      return 'alle';
  }
};

export const renderDateString = (timestamp: string | null | undefined) => {
  let date = new Date(timestamp ?? 0);
  let day = date.getDate();
  let month = date.getMonth() + 1;
  let year = date.getFullYear().toString().substring(-2);
  return `${day > 9 ? day : `0${day}`}.${
    month > 9 ? month : `0${month}`
  }.${year}`;
};
