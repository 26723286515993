import { gql } from '@apollo/client';

export const GET_USER = gql`
  query GetUser($email: String) {
    getUser(email: $email) {
      id
      firstName
      lastName
      email
      phone
      createdAt
      team
      teams
      username
      avatar
      sound
      animation
      admin
      color
      trophies {
        _id
        name
        competition
        type
        timestamp
      }
    }
  }
`;
