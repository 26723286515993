import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { LeaderboardPage, LoginRegister, DashboardPage } from '.';
import { AdminContextProvider } from '../state/context/AdminContext';
import { LeaderboardContextProvider } from '../state/context/LeaderboardContext';
import { useGlobalContext } from '../state/context/GlobalContext';
import { UserPageContextProvider } from '../state/context/UserPageContext';
import { Admin } from './Settings/Admin';
import { routes } from './routes';
import { Profile } from './Profile/Profile';
import { ResetPassword } from './ResetPassword/ResetPassword';
import { Trophies } from './Trophies/Trophies';

export const Router: React.FC = () => {
  const { loggedIn, currentUser } = useGlobalContext();

  return (
    <Routes>
      <Route path={routes.signup} element={<LoginRegister />} />
      <Route path={routes.login} element={<LoginRegister />} />
      <Route path={routes.resetPassword} element={<ResetPassword />} />
      {loggedIn && (
        <>
          <Route path={routes.profile} element={<Profile />} />

          {currentUser?.admin && (
            <Route
              path={routes.admin}
              element={
                <AdminContextProvider>
                  <Admin />
                </AdminContextProvider>
              }
            />
          )}
          <Route
            path={routes.dashboard}
            element={
              <UserPageContextProvider>
                <DashboardPage />
              </UserPageContextProvider>
            }
          />
          <Route
            path={routes.leaderboard}
            element={
              <LeaderboardContextProvider>
                <LeaderboardPage />
              </LeaderboardContextProvider>
            }
          />
          <Route path={routes.trophies} element={<Trophies />} />
        </>
      )}

      <Route
        path='*'
        element={<Navigate to={loggedIn ? routes.dashboard : routes.login} />}
      />
    </Routes>
  );
};
