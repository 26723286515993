import { gql } from '@apollo/client';

export const ADD_COMPETITION = gql`
  mutation AddCompetition(
    $competition: CompetitionInput
    $includeInLayouts: Boolean
  ) {
    addCompetition(
      competition: $competition
      includeInLayouts: $includeInLayouts
    ) {
      id
      teamId
      name
      startDate
      endDate
      prizes {
        position
        description
      }
      category {
        id
        name
        suffix
      }
      competitionType
      goal {
        goalType
        suffix
        amount
      }
      status
      unit {
        id
        name
        suffix
        sumLabel
        countLabel
        newRegistrationLabel
        icon
      }
    }
  }
`;
