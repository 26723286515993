import { gql } from '@apollo/client';

export const SETTINGS_SUBSCRIPTION = gql`
  subscription settings($teamId: ID) {
    settings(teamId: $teamId) {
      meme {
        aktiv
        topText
        bottomText
        image
      }
      layouts {
        _id
        name
        dateFilter
        unit {
          name
          id
          suffix
          sumLabel
          countLabel
          newRegistrationLabel
          icon
        }
        widgets {
          i
          x
          y
          w
          h
        }
        cols
        rows
        competition
      }
      leaderboard {
        defaultLayout
        loop
        loopTime
        refresh
      }
      background {
        aktiv
        loopTime
        loop
        images
      }
      quote {
        aktiv
        category
      }
      spotify {
        aktiv
        token
        playlist
      }
    }
  }
`;
