export const routes = {
  leaderboard: '/',
  login: '/login',
  signup: '/signup',
  dashboard: '/dashboard',
  admin: '/admin',
  profile: '/profile',
  resetPassword: '/tilbakestill',
  trophies: '/trofeer',
};
