import { Leaderboard } from 'components/widgets/LeaderboardWidgets/Leaderboard';
import { LastRegistrations } from 'components/widgets/LeaderboardWidgets/LastRegistrations';
import { LeaderboardFooter } from 'components/widgets/LeaderboardWidgets/LeaderboardFooter';
import { TeamTrend } from 'components/widgets/LeaderboardWidgets/TeamTrend';
import { TotalProgress } from 'components/widgets/LeaderboardWidgets/TotalProgress';
import React, { useState, useEffect } from 'react';
import GridLayout from 'react-grid-layout';
import { Competition, Layout, LayoutWidget } from 'types/team';
import { DateFilter, DateRange } from 'types/leaderboard';
import { CompetitionProgress } from 'components/widgets/LeaderboardWidgets/CompetitionProgress';
import { CompetitionInfo } from 'components/widgets/LeaderboardWidgets/CompetitionInfo';
import { CompetitionPodium } from 'components/widgets/LeaderboardWidgets/CompetitionPodium';
import { Box } from '@mui/material';

interface LayoutGridProps {
  /*   header: string | null | undefined; */
  widgets: LayoutWidget[] | undefined;
  cols?: number;
  rows?: number;
  rowHeight?: number;
  data?: WidgetProps;
}

export interface WidgetProps {
  widget?: LayoutWidget;
  dateFilter?: DateFilter | null | undefined;
  competition?: Competition | null | undefined;
  timeframe?: DateRange | null | undefined;
  currentLayout?: Layout | null | undefined;
  teamGoal?: number | null | undefined;
  lastRegistrations?: any[] | null | undefined;
  empty?: boolean;
}

const AVAILABLE_WIDGETS: { [key: string]: React.FC<WidgetProps> } = {
  leaderboard: Leaderboard,
  totalProgress: TotalProgress,
  lastRegistrations: LastRegistrations,
  teamTrend: TeamTrend,
  footer: LeaderboardFooter,
  competitionProgress: CompetitionProgress,
  competitionInfo: CompetitionInfo,
  competitionPodium: CompetitionPodium,
};

export const DEFAULT_GRID = [
  {
    i: 'leaderboard',
    x: 0,
    y: 0,
    w: 4,
    h: 11,
  },
  {
    i: 'totalProgress',
    x: 4,
    y: 0,
    w: 2,
    h: 6,
  },
  {
    i: 'lastRegistrations',
    x: 6,
    y: 0,
    w: 2,
    h: 6,
  },
  {
    i: 'teamTrend',
    x: 4,
    y: 3,
    w: 4,
    h: 5,
  },
  {
    i: 'footer',
    x: 0,
    y: 5,
    w: 8,
    h: 5,
  },
];

export const LayoutGrid: React.FC<LayoutGridProps> = ({
  cols,
  rows,
  widgets,
  data,
}) => {
  const [rowHeight, setRowHeight] = useState(
    (document?.getElementById('layout-container')?.clientHeight ?? 1) /
      (rows ?? 16)
  );
  const [width, setWidth] = useState(
    document?.getElementById('layout-container')?.offsetWidth ?? 1200
  );

  const renderWidget = (
    layout: LayoutWidget,
    props: WidgetProps | undefined
  ) => {
    let Widget = AVAILABLE_WIDGETS[layout.i];
    return <Widget {...props} widget={layout} />;
  };

  useEffect(() => {
    const observer = new ResizeObserver((entries) => {
      let height = entries?.[0]?.contentRect?.height ?? 1;
      let width = entries?.[0]?.contentRect?.width ?? 1200;
      setRowHeight(height / (rows ?? 16));
      setWidth(width);
    });

    const container = document?.getElementById('layout-container') as Element;
    observer.observe(container);

    return () => observer.unobserve(container);
  }, [rows]);

  return (
    <GridLayout
      margin={[0, 0]}
      className='react-grid-layout'
      cols={cols ?? 8}
      rowHeight={rowHeight}
      width={width}
      style={{ width: '100%', height: '100%' }}
      layout={widgets}
      isDraggable={false}
      maxRows={rows ?? 16}
      isResizable={false}
    >
      {widgets?.map((widget) => (
        <Box style={{ padding: 5 }} key={widget.i}>
          {renderWidget(widget, data)}
        </Box>
      ))}
    </GridLayout>
  );
};
