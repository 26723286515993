import { gql } from '@apollo/client';

export const COMPETITION_SUBSCRIPTION = gql`
  subscription Subscription($teamId: ID) {
    competition(teamId: $teamId) {
      _id
      id
      variant
      teamId
      name
      startDate
      endDate
      competitionType
      category {
        id
        name
        suffix
      }
      prizes {
        position
        description
      }
      goal {
        goalType
        suffix
        amount
      }
      status
      unit {
        id
        name
        suffix
        sumLabel
        countLabel
        newRegistrationLabel
        icon
      }
    }
  }
`;
