import { ApolloError, useMutation } from '@apollo/client';
import { Edit, PlayArrow, VolumeUp } from '@mui/icons-material';
import {
  Avatar,
  Badge,
  Box,
  Button,
  FormControl,
  FormLabel,
  Grid,
  IconButton,
  ListItemText,
  MenuItem,
  Modal,
  Select,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import Compressor from 'compressorjs';
import React, { useMemo, useState } from 'react';
import placeholder from '../../assets/images/user.jpg';
import { UPLOAD_FILE } from '../../graphql/mutations';
import { EDIT_USER } from '../../graphql/mutations/User/editUser';
import { useGlobalContext } from '../../state/context/GlobalContext';
import { useNotificationContext } from '../../state/context/NoticifationContext';
import { server_url } from '../../utils/environment';

import { ColorPicker } from 'mui-color';
import { AvailableSounds, Sound } from 'components/Notification/Notification';
import {
  Animation,
  Animator,
  AvailableAnimations,
} from 'components/Animation/Animation';

export const Profile: React.FC = () => {
  const { currentUser, setUser } = useGlobalContext();
  const notifier = useNotificationContext();

  const [username, setUsername] = useState<string>(currentUser?.username ?? '');
  const [firstName, setFirstName] = useState<string>(
    currentUser?.firstName ?? ''
  );
  const [lastName, setLastName] = useState<string>(currentUser?.lastName ?? '');
  const [email, setEmail] = useState<string>(currentUser?.email ?? '');
  const [phone, setPhone] = useState<string>(currentUser?.phone ?? '');
  const [avatarFile, setAvatarFile] = useState<File>();
  const [avatarUrl, setAvatarUrl] = useState<string | null>();
  const [avatarPreview, setAvatarPreview] = useState<string>();

  const [emailError, setEmailError] = useState<string | null>();
  const [failedAttempt, setFailedAttempt] = useState<boolean>(false);
  const [color, setColor] = useState<string>(
    currentUser?.color ??
      '#' + Math.floor(Math.random() * 16777215).toString(16)
  );

  const [sound, setSound] = useState<Sound>(currentUser?.sound ?? 'default');
  const [soundIsPlaying, setSoundIsPlaying] = useState<boolean>(false);
  const [animation, setAnimation] = useState<Animation>(
    currentUser?.animation ?? 'default'
  );
  const [animationPreview, setAnimationPreview] = useState<boolean>(false);

  const [editUser] = useMutation(EDIT_USER);
  const [uploadFile] = useMutation(UPLOAD_FILE);

  const getEmailError = (email: string) => {
    if (
      email
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
    ) {
      return null;
    } else if (email.length === 0) {
      return 'Påkrevd';
    } else {
      return 'Ugyldig format';
    }
  };

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const target = event.target;

    if (target?.files) {
      let file = target?.files[0];
      let size = file.size / 1024;

      if (size <= 5000) {
        new Compressor(file, {
          quality: 0.9,
          maxWidth: 300,
          maxHeight: 300,
          mimeType: 'image/jpeg',
          success(compressedFile: File) {
            let filetype = compressedFile.type.split('/')[1];
            let url = `${server_url}/${currentUser?.id}/avatar.${filetype}`;
            setAvatarFile(compressedFile);
            setAvatarUrl(url);
            setAvatarPreview(URL.createObjectURL(compressedFile));
          },
        });
      } else {
        notifier.addAlert(file.name, {
          type: 'error',
          title: 'Ugyldig filstørrelse',
          message: 'Maksimal filstørrelse er 5Mb',
          onClose: () => notifier.hideAlert(file.name),
          autoHideDuration: 3000,
        });
      }
    }
  };

  const handleEditUser = async () => {
    let filetype = avatarFile?.type.split('/')[1];

    if (!emailError) {
      if (avatarFile) {
        await uploadFile({
          variables: {
            file: avatarFile,
            filename: `avatar.${filetype}`,
            path: currentUser?.id,
          },
        }).catch((err) => {
          console.error(JSON.stringify(err));
          return;
        });
      }

      editUser({
        variables: {
          id: currentUser?.id,
          user: {
            username: username,
            firstName: firstName,
            lastName: lastName,
            email: email,
            phone: phone,
            avatar: avatarUrl,
            color: color,
            sound: sound,
            animation: animation,
          },
        },
      })
        .then((res) => {
          notifier.addAlert(currentUser?.id ?? '', {
            type: 'success',
            message: 'Endringene ble lagret',
            onClose: () => notifier.hideAlert(currentUser?.id ?? ''),
            autoHideDuration: 4000,
          });
          setUser(res.data.editUser);
        })
        .catch((err: ApolloError) => {
          let message = err.message.includes('duplicate')
            ? 'Eposten er allerede i bruk'
            : 'Kunne ikke lagre endringer';
          notifier.addAlert(currentUser?.id ?? '', {
            type: 'error',
            title: 'Noe gikk galt',
            message: message,
            onClose: () => notifier.hideAlert(currentUser?.id ?? ''),
            autoHideDuration: 3000,
          });
        });
    } else {
      setFailedAttempt(true);
    }
  };

  const handleColorChange = (color: any) => {
    setColor(
      'rgba(' + color.rgb[0] + ',' + color.rgb[1] + ',' + color.rgb[2] + ',.6)'
    );
  };

  let audio = useMemo(
    () => new Audio(AvailableSounds[sound ?? 'alarm']),
    [sound]
  );

  audio.onplay = () => setSoundIsPlaying(true);
  audio.onended = () => setSoundIsPlaying(false);
  audio.onpause = () => setSoundIsPlaying(false);

  const previewSound = () => {
    audio.currentTime = 0;
    audio.play();
    setTimeout(() => {
      audio.pause();
    }, 6000);
  };

  const previewAnimation = () => {
    setAnimationPreview(true);
    setTimeout(() => setAnimationPreview(false), 6000);
  };

  return (
    <div className='profile-container'>
      <Grid
        container
        className='profile-content'
        alignItems='center'
        justifyContent={'center'}
        spacing={3}
      >
        <Modal open={animationPreview}>
          <Box
            justifyContent={'center'}
            alignItems='center'
            display={'flex'}
            width='100%'
            height='100%'
          >
            <Animator animation={animation ?? 'default'} />
          </Box>
        </Modal>
        <Grid item xs={12}>
          <Typography variant='h4'>Rediger bruker</Typography>
        </Grid>
        <Grid item xs={12} className='avatar-container'>
          <Badge
            overlap='circular'
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            badgeContent={
              <>
                <input
                  id='image-upload'
                  accept='image/*'
                  type='file'
                  hidden
                  onChange={(e) => handleImageChange(e)}
                />
                <label htmlFor='image-upload'>
                  <IconButton
                    color='primary'
                    className='edit-avatar-button'
                    size='small'
                    component='span'
                  >
                    <Edit className='button-icon' />
                  </IconButton>
                </label>
              </>
            }
          >
            <Avatar
              alt='profile-picture'
              src={avatarPreview ?? currentUser?.avatar ?? placeholder}
              className='avatar'
            />
          </Badge>
        </Grid>
        <Grid item xs={12} md={6}>
          <Grid container justifyContent='center' spacing={3}>
            <Grid item xs={12} md={10}>
              <FormControl fullWidth>
                <FormLabel>Brukernavn</FormLabel>
                <TextField
                  id='outlined-basic name'
                  placeholder='Brukernavn'
                  variant='outlined'
                  type='text'
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6} md={5}>
              <FormControl fullWidth>
                <FormLabel>Fornavn</FormLabel>
                <TextField
                  id='outlined-basic name'
                  placeholder='Fornavn'
                  variant='outlined'
                  type='text'
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6} md={5}>
              <FormControl fullWidth>
                <FormLabel>Etternavn</FormLabel>
                <TextField
                  id='outlined-basic name'
                  placeholder='Etternavn'
                  variant='outlined'
                  value={lastName}
                  type='text'
                  onChange={(e) => setLastName(e.target.value)}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={10}>
              <FormControl fullWidth required={true}>
                <FormLabel>Epost</FormLabel>
                <TextField
                  error={failedAttempt && emailError ? true : false}
                  helperText={emailError && failedAttempt ? emailError : null}
                  id='outlined-basic email'
                  placeholder='Epost'
                  variant='outlined'
                  value={email}
                  type='email'
                  required={true}
                  onChange={(e) => {
                    setEmail(e.target.value);
                    setEmailError(getEmailError(e.target.value));
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={10}>
              <FormControl fullWidth>
                <FormLabel>Telefon</FormLabel>
                <TextField
                  /* error={failedAttempt && emailError ? true : false}
                    helperText={emailError && failedAttempt ? emailError : null} */
                  id='outlined-basic email'
                  placeholder='xxx xx xxx'
                  variant='outlined'
                  type='tel'
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <Typography className='country-code'>+47</Typography>
                    ),
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={10}>
              <FormControl fullWidth>
                <FormLabel>Farge</FormLabel>
                <ColorPicker value={color} onChange={handleColorChange} />
              </FormControl>
            </Grid>

            <Grid item xs={8} md={8}>
              <FormControl fullWidth>
                <FormLabel>Lydeffekt</FormLabel>
                <Select
                  value={sound}
                  variant='outlined'
                  onChange={(e) => setSound(e.target.value as Sound)}
                >
                  <MenuItem value={'default'}>Ingen valgt</MenuItem>
                  {Object.keys(AvailableSounds).map((s) => {
                    if (s !== 'default') {
                      return (
                        <MenuItem value={s} key={s}>
                          <ListItemText>{s}</ListItemText>
                        </MenuItem>
                      );
                    } else {
                      return null;
                    }
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid
              item
              xs={2}
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'flex-end',
              }}
            >
              <Tooltip title='Forhåndsvis lyd'>
                <IconButton
                  size='large'
                  color='primary'
                  onClick={previewSound}
                  disabled={!sound || sound === 'default' || soundIsPlaying}
                >
                  <VolumeUp sx={{ fontSize: 30 }} />
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid item xs={8} md={8}>
              <FormControl fullWidth>
                <FormLabel>Animasjon</FormLabel>
                <Select
                  value={animation}
                  variant='outlined'
                  onChange={(e) => setAnimation(e.target.value as Animation)}
                >
                  <MenuItem value={'default'}>Ingen valgt</MenuItem>
                  {Object.keys(AvailableAnimations).map((a) => {
                    if (a !== 'default') {
                      return (
                        <MenuItem value={a} key={a}>
                          <ListItemText>{a}</ListItemText>
                        </MenuItem>
                      );
                    } else {
                      return null;
                    }
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid
              item
              xs={2}
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'flex-end',
              }}
            >
              <Tooltip title='Forhåndsvis animasjon'>
                <IconButton
                  size='large'
                  color='primary'
                  onClick={previewAnimation}
                  disabled={
                    !animation || animation === 'default' || animationPreview
                  }
                >
                  <PlayArrow sx={{ fontSize: 30 }} />
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid item xs={12} md={10}>
              <Button variant='contained' onClick={handleEditUser}>
                Lagre
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};
