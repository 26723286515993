import { useMutation, useQuery } from '@apollo/client';
import {
  Add,
  Close,
  Delete,
  Edit,
  ExpandMore,
  Save,
} from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Button,
  CircularProgress,
  Divider,
  FormLabel,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Modal,
  Paper,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { TEAM_MEMBERS } from 'graphql/queries/Team/TeamMembers';
import React, { useEffect, useState } from 'react';
import { User } from 'types/user';
import { displayName } from 'utils/Stringparser';
import { EDIT_GOAL } from '../../../graphql/mutations';
import { DELETE_GOAL } from '../../../graphql/mutations/Team/deleteGoal';
import { useGlobalContext } from '../../../state/context/GlobalContext';
import {
  DateFilter,
  StatzVariant,
  TranslateDatefilterMap,
  TranslateVariantMap,
} from '../../../types/leaderboard';
import { Goal, Team, Unit } from '../../../types/team';
import NumberFormatter from '../../../utils/NumberFormatter';
import clsx from 'clsx';
import NumberFormat from 'react-number-format';
import { useNotificationContext } from 'state/context/NoticifationContext';

export const MyGoals: React.FC = () => {
  const { currentTeam, setTeam } = useGlobalContext();
  const [teamGoals, setTeamGoals] = useState<Goal[]>(
    currentTeam?.goals.filter((goal) => goal.common) ?? []
  );
  const [individualGoals, setIndividualGoals] = useState<Goal[]>(
    currentTeam?.goals.filter((goal) => !goal.common) ?? []
  );
  const [selectedMember, setSelectedMember] = useState<User | null>();

  const [goalEdit, setGoalEdit] = useState<Goal | null>();

  const [teamModalOpen, setTeamModalOpen] = useState<boolean>(false);
  const [individualModalOpen, setIndividualModalOpen] =
    useState<boolean>(false);

  const [editGoal] = useMutation(EDIT_GOAL);
  const [deleteGoal] = useMutation(DELETE_GOAL);
  const { data } = useQuery(TEAM_MEMBERS, {
    variables: {
      teamId: currentTeam?.id,
    },
    onCompleted(data) {
      let firstUser = data?.teamMembers[0] as User;
      setSelectedMember(firstUser);
    },
  });

  const handleEditGoal = (goal: Goal) => {
    return new Promise((resolve, reject) => {
      editGoal({
        variables: {
          teamId: currentTeam?.id,
          goal: goal,
        },
      })
        .then((res) => {
          let goals = res?.data?.editGoal;
          let teamUpdate = { ...currentTeam } as Team;
          teamUpdate.goals = goals;
          setTeam(teamUpdate);
          setGoalEdit(null);
          resolve(null);
        })
        .catch((error) => {
          console.error(error.message);
          setGoalEdit(null);
          reject();
        });
    });
  };

  const handleDeleteGoal = (goal: Goal) => {
    return new Promise((resolve, reject) => {
      deleteGoal({
        variables: {
          teamId: currentTeam?.id,
          goal: goal,
        },
      })
        .then((res) => {
          let goals = res?.data?.deleteGoal;
          let teamUpdate = { ...currentTeam } as Team;
          teamUpdate.goals = goals;
          setTeam(teamUpdate);
          resolve(null);
        })
        .catch((error) => {
          console.error(error);
          reject();
        });
    });
  };

  useEffect(() => {
    setTeamGoals(currentTeam?.goals.filter((goal) => goal.common) ?? []);
    setIndividualGoals(currentTeam?.goals.filter((goal) => !goal.common) ?? []);
  }, [currentTeam?.goals]);

  return (
    <Grid container className='admin-overview' spacing={3}>
      <Grid item xs={12}>
        <Grid container className='team_goals'>
          <Grid item xs={12} sx={{ textAlign: 'center' }}>
            <Typography variant='h4'>{`Mål for ${currentTeam?.name}`}</Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
            }}
          >
            {currentTeam?.units.map((unit) => {
              return (
                unit.name && (
                  <Accordion
                    key={unit?.id}
                    defaultExpanded={true}
                    className='goals_list'
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMore className='expand_icon' />}
                    >
                      {unit.name.charAt(0).toUpperCase() + unit.name.slice(1)}
                    </AccordionSummary>
                    <AccordionDetails>
                      <List className='goals_list'>
                        {teamGoals?.filter(
                          (goal) => goal.unit.name === unit.name
                        ).length > 0 ? (
                          teamGoals
                            .filter((goal) => goal.unit.name === unit.name)
                            .map((goal) => {
                              return (
                                <React.Fragment
                                  key={`${goal?.timeframe}+${goal?.unit?.name}+${goal?.measurement}`}
                                >
                                  <ListItem className='goal_item'>
                                    <ListItemText>{`${
                                      TranslateDatefilterMap[goal.timeframe]
                                    } - ${
                                      goal.measurement === 'SALES_AMOUNT'
                                        ? unit?.sumLabel ?? 'Verdi'
                                        : unit?.countLabel ?? 'Antall'
                                    }`}</ListItemText>
                                    <ListItemText>
                                      {goalEdit &&
                                      goal.timeframe === goalEdit.timeframe &&
                                      goal.measurement ===
                                        goalEdit.measurement &&
                                      goal.unit.name === goalEdit.unit?.name &&
                                      !goalEdit.userId ? (
                                        <div className='edit_container'>
                                          <NumberFormat
                                            value={goalEdit.value}
                                            onValueChange={(values) => {
                                              const { value } = values;
                                              let parsed = parseInt(value);
                                              let edited = {
                                                ...goalEdit,
                                                value: Number.isNaN(parsed)
                                                  ? 0
                                                  : parsed,
                                              };
                                              setGoalEdit(edited);
                                            }}
                                            suffix={` ${
                                              goalEdit.measurement ===
                                              'SALES_AMOUNT'
                                                ? goalEdit.unit.suffix
                                                : unit.name
                                            }`}
                                            onKeyDown={(e: any) =>
                                              e.key === 'Enter'
                                                ? handleEditGoal(goalEdit)
                                                : null
                                            }
                                            customInput={TextField}
                                            thousandSeparator=' '
                                            autoFocus
                                          />
                                          <IconButton
                                            color='error'
                                            onClick={() => setGoalEdit(null)}
                                          >
                                            <Close />
                                          </IconButton>
                                          <IconButton
                                            disabled={
                                              !goalEdit.value ||
                                              goalEdit.value === 0
                                            }
                                            color='success'
                                            onClick={() =>
                                              handleEditGoal(goalEdit)
                                            }
                                          >
                                            <Save />
                                          </IconButton>
                                        </div>
                                      ) : (
                                        <Typography className='goal_value'>
                                          {`${NumberFormatter.format(
                                            goal.value
                                          )} ${
                                            goal.measurement === 'SALES_AMOUNT'
                                              ? goal.unit.suffix
                                              : goal.unit.name
                                          }`}
                                        </Typography>
                                      )}
                                    </ListItemText>
                                    <ListItemIcon>
                                      <IconButton
                                        color='primary'
                                        onClick={() => setGoalEdit(goal)}
                                      >
                                        <Edit />
                                      </IconButton>
                                    </ListItemIcon>
                                    <ListItemIcon>
                                      <IconButton
                                        color='error'
                                        onClick={() => handleDeleteGoal(goal)}
                                      >
                                        <Delete />
                                      </IconButton>
                                    </ListItemIcon>
                                  </ListItem>
                                  <Divider />
                                </React.Fragment>
                              );
                            })
                        ) : (
                          <Typography className='placeholder-text'>
                            Foreløpig ingen mål
                          </Typography>
                        )}
                      </List>
                    </AccordionDetails>
                  </Accordion>
                )
              );
            })}

            <Button
              color='primary'
              variant='contained'
              endIcon={<Add />}
              onClick={() => setTeamModalOpen(true)}
            >
              Legg til mål
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container className='individual_goals'>
          <Grid item xs={12} sx={{ textAlign: 'center' }}>
            <Typography variant='h4'>Individuelle mål</Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
            }}
          >
            <Grid container className='avatar_list'>
              {data?.teamMembers?.map((teamMember: User) => {
                return (
                  <Grid
                    key={teamMember?.id}
                    item
                    xs={2}
                    onClick={() => setSelectedMember(teamMember)}
                    className={clsx('avatar_container', {
                      avatar_container_selected:
                        selectedMember?.email === teamMember.email,
                    })}
                  >
                    <Avatar
                      className={clsx('avatar', {
                        active_avatar:
                          selectedMember?.email === teamMember.email,
                      })}
                      src={teamMember.avatar ?? ''}
                      alt={teamMember.email}
                    />
                    <Typography variant='caption'>
                      {displayName(teamMember)}
                    </Typography>
                  </Grid>
                );
              })}
              <Grid
                item
                xs={12}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                {currentTeam?.units.map((unit) => {
                  return (
                    unit.name && (
                      <Accordion
                        key={unit?.id}
                        defaultExpanded={true}
                        className='goals_list'
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMore className='expand_icon' />}
                        >
                          {unit.name.charAt(0).toUpperCase() +
                            unit.name.slice(1)}
                        </AccordionSummary>
                        <AccordionDetails>
                          <List className='goals_list'>
                            {individualGoals?.filter(
                              (goal) =>
                                goal.unit.name === unit.name &&
                                goal.userId === selectedMember?.id
                            ).length > 0 ? (
                              individualGoals
                                .filter(
                                  (goal) =>
                                    goal.unit.name === unit.name &&
                                    goal.userId === selectedMember?.id
                                )
                                .map((goal) => {
                                  return (
                                    <React.Fragment
                                      key={`${goal?.userId}+${goal?.timeframe}+${goal?.unit?.name}+${goal?.measurement}`}
                                    >
                                      <ListItem className='goal_item'>
                                        <ListItemText>{`${
                                          TranslateDatefilterMap[goal.timeframe]
                                        } - ${
                                          TranslateVariantMap[goal.measurement]
                                        }`}</ListItemText>
                                        <ListItemText>
                                          {goalEdit &&
                                          goal.timeframe ===
                                            goalEdit.timeframe &&
                                          goal.measurement ===
                                            goalEdit.measurement &&
                                          goal.userId === goalEdit.userId ? (
                                            <div className='edit_container'>
                                              <NumberFormat
                                                value={goalEdit.value}
                                                onValueChange={(values) => {
                                                  const { value } = values;
                                                  let parsed = parseInt(value);
                                                  let edited = {
                                                    ...goalEdit,
                                                    value: Number.isNaN(parsed)
                                                      ? 0
                                                      : parsed,
                                                  };

                                                  setGoalEdit(edited);
                                                }}
                                                suffix={` ${
                                                  goalEdit.measurement ===
                                                  'SALES_AMOUNT'
                                                    ? goalEdit.unit.suffix
                                                    : unit.name
                                                }`}
                                                onKeyDown={(e: any) =>
                                                  e.key === 'Enter'
                                                    ? handleEditGoal(goalEdit)
                                                    : null
                                                }
                                                customInput={TextField}
                                                thousandSeparator=' '
                                                autoFocus
                                              />
                                              <IconButton
                                                color='error'
                                                onClick={() =>
                                                  setGoalEdit(null)
                                                }
                                              >
                                                <Close />
                                              </IconButton>
                                              <IconButton
                                                disabled={
                                                  !goalEdit.value ||
                                                  goalEdit.value === 0
                                                }
                                                color='success'
                                                onClick={() =>
                                                  handleEditGoal(goalEdit)
                                                }
                                              >
                                                <Save />
                                              </IconButton>
                                            </div>
                                          ) : (
                                            <Typography className='goal_value'>
                                              {`${NumberFormatter.format(
                                                goal.value
                                              )} ${
                                                goal.measurement ===
                                                'SALES_AMOUNT'
                                                  ? goal.unit.suffix
                                                  : goal.unit.name
                                              }`}
                                            </Typography>
                                          )}
                                        </ListItemText>
                                        <ListItemIcon>
                                          <IconButton
                                            color='primary'
                                            onClick={() => setGoalEdit(goal)}
                                          >
                                            <Edit />
                                          </IconButton>
                                        </ListItemIcon>
                                        <ListItemIcon>
                                          <IconButton
                                            color='error'
                                            onClick={() =>
                                              handleDeleteGoal(goal)
                                            }
                                          >
                                            <Delete />
                                          </IconButton>
                                        </ListItemIcon>
                                      </ListItem>
                                      <Divider />
                                    </React.Fragment>
                                  );
                                })
                            ) : (
                              <Typography className='placeholder-text'>
                                Foreløpig ingen mål
                              </Typography>
                            )}
                          </List>
                        </AccordionDetails>
                      </Accordion>
                    )
                  );
                })}
              </Grid>
            </Grid>

            <Button
              color='primary'
              variant='contained'
              endIcon={<Add />}
              onClick={() => setIndividualModalOpen(true)}
            >
              Legg til mål
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <AddGoalModal
        common={true}
        open={teamModalOpen}
        onClose={() => setTeamModalOpen(false)}
      />
      <AddGoalModal
        common={false}
        open={individualModalOpen}
        userId={selectedMember?.id}
        subtitle={selectedMember ? displayName(selectedMember) : undefined}
        onClose={() => setIndividualModalOpen(false)}
      />
    </Grid>
  );
};

interface ModalProps {
  common?: boolean;
  userId?: string | null | undefined;
  open: boolean;
  title?: string;
  subtitle?: string;
  onClose?: () => void;
}

const AddGoalModal: React.FC<ModalProps> = ({
  common,
  userId,
  open,
  title,
  subtitle,
  onClose,
}) => {
  const { currentTeam, setTeam } = useGlobalContext();
  const [value, setValue] = useState<number>(0);
  const [unit, setUnit] = useState<Unit>(currentTeam?.units[0] as Unit);
  const [timeframe, setTimeframe] = useState<DateFilter>('WEEK');
  const [variant, setVariant] = useState<StatzVariant>('SALES_AMOUNT');

  const [editGoal, { loading }] = useMutation(EDIT_GOAL);

  const notifier = useNotificationContext();

  const handleSave = () => {
    return new Promise((resolve, reject) => {
      editGoal({
        variables: {
          teamId: currentTeam?.id,
          goal: {
            value: value,
            unit: unit,
            common: common,
            timeframe: timeframe,
            measurement: variant,
            userId: userId,
          },
        },
      })
        .then((res) => {
          let goals = res?.data?.editGoal;
          let teamUpdate = { ...currentTeam } as Team;
          teamUpdate.goals = goals;
          setTeam(teamUpdate);
          notifier.addAlert('goal_added', {
            type: 'success',
            message: 'Målet ble lagt til',
            onClose: () => notifier.hideAlert('goal_added'),
            autoHide: true,
          });
          if (onClose) {
            onClose();
          }
          resolve(null);
        })
        .catch((error) => {
          console.error(error.message);
          notifier.addAlert('goal_add_error', {
            type: 'error',
            title: 'Noe gikk gal',
            message: 'Kunne ikke legge til mål',
            autoHide: true,
            onClose: () => notifier.hideAlert('goal_add_error'),
          });
          reject();
        });
    });
  };

  return (
    <Modal open={open} onBackdropClick={onClose}>
      <Paper className='add_goal_modal'>
        <IconButton className='close-button' onClick={onClose}>
          <Close />
        </IconButton>
        <Typography variant='h5'>{title ? title : `Legg til mål`}</Typography>
        <Typography variant='h6'>{subtitle ? subtitle : ''}</Typography>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormLabel>{`Velg periode`}</FormLabel>
            <Select
              value={timeframe}
              onChange={(e) => setTimeframe(e.target.value as DateFilter)}
            >
              {Object.keys(TranslateDatefilterMap)
                .filter(
                  (key) =>
                    key !== 'LAST_WEEK' &&
                    key !== 'LAST_MONTH' &&
                    key !== 'ALL_TIME'
                )
                .map((key) => {
                  return (
                    <MenuItem key={key} value={key as DateFilter}>
                      {TranslateDatefilterMap[key as DateFilter]}
                    </MenuItem>
                  );
                })}
            </Select>
          </Grid>
          <Grid item xs={6}>
            <FormLabel>{`Velg enhet`}</FormLabel>
            <Select
              value={unit.id}
              onChange={(e) => {
                let unit = currentTeam?.units.find(
                  (x) => x.id === e.target.value
                );
                if (unit) {
                  setUnit(unit);
                }
              }}
            >
              {currentTeam?.units?.map((unit) => {
                return (
                  <MenuItem key={unit.id} value={unit.id}>
                    {unit?.name}
                  </MenuItem>
                );
              })}
            </Select>
          </Grid>
          <Grid item xs={6}>
            <FormLabel>{`Velg målepunkt`}</FormLabel>
            <Select
              value={variant}
              onChange={(e) => setVariant(e.target.value as StatzVariant)}
            >
              <MenuItem value={'SALES_AMOUNT'}>
                {unit?.sumLabel ?? `Verdi`}
              </MenuItem>
              <MenuItem value={'SALES_COUNT'}>
                {unit?.countLabel ?? `Antall`}
              </MenuItem>
            </Select>
          </Grid>
          <Grid item xs={12}>
            <FormLabel>{`Mål`}</FormLabel>
            <NumberFormat
              fullWidth
              value={value}
              onValueChange={(values) => {
                const { value } = values;
                let parsed = parseInt(value);
                setValue(Number.isNaN(parsed) ? 0 : parsed);
              }}
              suffix={` ${
                variant === 'SALES_AMOUNT' ? unit.suffix : unit.name
              }`}
              onKeyDown={(e: any) => (e.key === 'Enter' ? handleSave() : null)}
              customInput={TextField}
              thousandSeparator=' '
              autoFocus
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              fullWidth
              variant='contained'
              disabled={value === 0}
              onClick={handleSave}
              endIcon={loading && <CircularProgress size={'sm'} />}
            >
              Lagre
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </Modal>
  );
};
