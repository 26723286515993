import { gql } from '@apollo/client';

export const RESET_PASSWORD = gql`
  mutation ResetPassword($password: String, $userId: ID, $token: String) {
    resetPassword(password: $password, userId: $userId, token: $token) {
      id
      firstName
      lastName
      email
      phone
      team
      teams
      createdAt
      username
      avatar
      sound
      animation
      admin
      trophies {
        _id
        name
        competition
        type
        timestamp
      }
    }
  }
`;
