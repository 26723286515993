import React, { useCallback, useMemo, useState } from 'react';
import { createGenericContext } from './createGenericContext';

interface FrameContextType {
  handleFullscreen: any;
  handleSidebarInjection: any;
  handleFooterInjection: any;
  handleNavbarInjection: any;
  handleSidebarStyle: any;
  handleFooterStyle: any;
  handleNavbarStyle: any;
  handleFrameStyle: any;
  NavbarInjection: any;
  SidebarInjection: any;
  FooterInjection: any;
  FooterStyle: object;
  SidebarStyle: object;
  NavbarStyle: object;
  FrameStyle: React.CSSProperties;
  Fullscreen: boolean;
}

export const [useFrameContext, BareFrameContextProvider] =
  createGenericContext<FrameContextType>();

export const FrameContextProvider: React.FC = ({ children }) => {
  const [SidebarInjection, setSidebarInjection] = useState<any>();
  const [FooterInjection, setFooterInjection] = useState<any>();
  const [NavbarInjection, setNavbarInjection] = useState<any>();
  const [SidebarStyle, setSidebarStyle] = useState<object>({});
  const [FooterStyle, setFooterStyle] = useState<object>({});
  const [NavbarStyle, setNavbarStyle] = useState<object>({});
  const [FrameStyle, setFrameStyle] = useState<React.CSSProperties>({
    paddingLeft: 0,
    paddingTop: 80,
    paddingBottom: 0,
    height: '100%',
    width: '100%',
  });
  const [Fullscreen, setFullscreen] = useState<boolean>(false);

  const handleFullscreen = useCallback((data: boolean) => {
    setFullscreen(data);
  }, []);

  const handleSidebarInjection = useCallback((data: any) => {
    setSidebarInjection(data);
  }, []);
  const handleFooterInjection = useCallback((data: any) => {
    setFooterInjection(data);
  }, []);
  const handleNavbarInjection = useCallback((data: any) => {
    setNavbarInjection(data);
  }, []);
  const handleNavbarStyle = useCallback((data: any) => {
    setNavbarStyle(data);
  }, []);
  const handleSidebarStyle = useCallback((data: any) => {
    setSidebarStyle(data);
  }, []);
  const handleFooterStyle = useCallback((data: any) => {
    setFooterStyle(data);
  }, []);

  const handleFrameStyle = useCallback(
    (data?: any) => {
      setFrameStyle({ ...FrameStyle, ...data });
    },
    [FrameStyle]
  );

  const value: FrameContextType = useMemo(
    () => ({
      handleFullscreen: handleFullscreen,
      handleSidebarInjection: handleSidebarInjection,
      handleFooterInjection: handleFooterInjection,
      handleNavbarInjection: handleNavbarInjection,
      handleSidebarStyle: handleSidebarStyle,
      handleNavbarStyle: handleNavbarStyle,
      handleFooterStyle: handleFooterStyle,
      handleFrameStyle: handleFrameStyle,
      SidebarInjection: SidebarInjection,
      NavbarInjection: NavbarInjection,
      FooterInjection: FooterInjection,
      SidebarStyle: SidebarStyle,
      FooterStyle: FooterStyle,
      NavbarStyle: NavbarStyle,
      FrameStyle: FrameStyle,
      Fullscreen: Fullscreen,
    }),
    [
      handleFullscreen,
      handleSidebarInjection,
      handleFooterInjection,
      handleNavbarInjection,
      handleSidebarStyle,
      handleNavbarStyle,
      handleFooterStyle,
      handleFrameStyle,
      SidebarInjection,
      NavbarInjection,
      FooterInjection,
      SidebarStyle,
      FooterStyle,
      NavbarStyle,
      FrameStyle,
      Fullscreen,
    ]
  );
  return (
    <BareFrameContextProvider value={value}>
      {children}
    </BareFrameContextProvider>
  );
};
