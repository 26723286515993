import { CircularProgress, Box } from '@mui/material';
import React from 'react';
import { Router } from 'pages/Router';
import { useGlobalContext } from 'state/context/GlobalContext';
import { useNotificationContext } from 'state/context/NoticifationContext';

import Frame from 'components/layout/Frame/frame';
import bg from 'assets/images/bg-home.jpg';

const App: React.FC = () => {
  const { loading } = useGlobalContext();
  const notifier = useNotificationContext();

  return (
    <div className='App'>
      {Array.from(notifier.alerts.values()).map((alert, indx) => {
        return <React.Fragment key={indx}>{alert}</React.Fragment>;
      })}
      <Frame>
        <Box className='Main' component='main' sx={{ flexGrow: 1 }}>
          {!loading ? (
            <Router />
          ) : (
            <div className='global-loader-container'>
              <CircularProgress size={60} className='global-loader' />
            </div>
          )}
        </Box>
      </Frame>
      <img src={bg} alt='background' id='bg' />
      {/* <Video /> */}
    </div>
  );
};

export default App;
