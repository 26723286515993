import { Delete, Edit } from '@mui/icons-material';
import {
  IconButton,
  Typography,
  TableRow,
  TableCell,
  Collapse,
  Box,
  Stack,
  Menu,
  MenuItem,
} from '@mui/material';
import React, { useState } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import PauseCircleOutlineIcon from '@mui/icons-material/PauseCircleOutline';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { capitalizeFirst } from 'utils/Stringparser';

export const CompetitionRow = (props: any) => {
  const { row, changeStatus, handleDelete, editCompetition } = props;

  const [open, setOpen] = React.useState(false);
  const [statusMenuAnchor, setStatusMenuAnchor] = useState<null | HTMLElement>(
    null
  );
  const statusMenuOpen = Boolean(statusMenuAnchor);

  const Variant = (variant: string | null | undefined) => {
    return variant === 'GOAL_BASED' ? 'Målbasert' : 'Tidsbasert';
  };

  const CompetitionType = (CompetitionType: string | null | undefined) => {
    return CompetitionType === 'TEAM' ? 'Lag' : 'Invidviduell';
  };

  const handleStatusMenuOpen = (e: React.MouseEvent<HTMLElement>) => {
    setStatusMenuAnchor(e.currentTarget);
  };

  const handleStatusMenuClose = () => {
    setStatusMenuAnchor(null);
  };

  return (
    <React.Fragment>
      <TableRow
        hover
        sx={{
          '& > *': { borderBottom: 'unset' },
        }}
      >
        <TableCell>
          <IconButton
            aria-label='expand row'
            size='small'
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component='th' scope='row' onClick={() => setOpen(!open)}>
          {row.name}
        </TableCell>

        <TableCell align='right'>
          <Menu
            anchorEl={statusMenuAnchor}
            open={statusMenuOpen}
            onClose={handleStatusMenuClose}
            onClick={handleStatusMenuClose}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: 'visible',
                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                color: '#fff',
                background: '#474444',
                mt: 1.5,
                '& .MuiSvgIcon-root': {
                  width: 26,
                  height: 26,
                  ml: -0.5,
                  mr: 1,
                },
                '&:before': {
                  content: '""',
                  display: 'block',
                  position: 'absolute',
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  background: '#474444',
                  transform: 'translateY(-50%) rotate(45deg)',
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          >
            <MenuItem onClick={changeStatus(row, 'ONGOING')}>
              <PlayCircleOutlineIcon style={{ color: 'green' }} /> Aktiv
            </MenuItem>
            <MenuItem onClick={changeStatus(row, 'INACTIVE')}>
              <PauseCircleOutlineIcon style={{ color: 'red' }} /> Inaktiv
            </MenuItem>
            <MenuItem onClick={changeStatus(row, 'FINISHED')}>
              <CheckCircleOutlineIcon color='primary' /> Avsluttet
            </MenuItem>
          </Menu>

          <IconButton
            color='primary'
            aria-label='upload picture'
            component='span'
            onClick={handleStatusMenuOpen}
          >
            {row.status === 'ONGOING' ? (
              <PlayCircleOutlineIcon style={{ color: 'green' }} />
            ) : row.status === 'FINISHED' ? (
              <CheckCircleOutlineIcon />
            ) : (
              <PauseCircleOutlineIcon style={{ color: 'red' }} />
            )}
          </IconButton>
        </TableCell>
        <TableCell align='right'>
          <IconButton color='primary' onClick={() => editCompetition(row)}>
            <Edit />
          </IconButton>
        </TableCell>
        <TableCell align='right'>
          <IconButton color='error' onClick={() => handleDelete(row.id ?? '')}>
            <Delete />
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow className='MyCompetitions'>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout='auto' unmountOnExit>
            <Box sx={{ margin: 4 }}>
              <Stack>
                <Box display='flex' justifyContent='space-between'>
                  <Typography variant='body1' gutterBottom component='div'>
                    Format
                  </Typography>
                  <Typography variant='body1' gutterBottom component='div'>
                    {CompetitionType(row.competitionType)}
                  </Typography>
                </Box>
                <Box display='flex' justifyContent='space-between'>
                  <Typography variant='body1' gutterBottom component='div'>
                    Konkurransetype
                  </Typography>
                  <Typography variant='body1' gutterBottom component='div'>
                    {Variant(row.variant)}
                  </Typography>
                </Box>
                <Box display='flex' justifyContent='space-between'>
                  <Typography variant='body1' gutterBottom component='div'>
                    Kategori
                  </Typography>
                  <Typography variant='body1' gutterBottom component='div'>
                    {capitalizeFirst(row?.category?.name ?? '')}
                  </Typography>
                </Box>
                <Box display='flex' justifyContent='space-between'>
                  <Typography variant='body1' gutterBottom component='div'>
                    Målemetode
                  </Typography>
                  <Typography variant='body1' gutterBottom component='div'>
                    {row?.goal?.goalType === 'SALES_AMOUNT'
                      ? row?.unit?.sumLabel ?? 'Verdi'
                      : row?.unit?.countLabel ?? 'Antall'}
                  </Typography>
                </Box>
                <Box display='flex' justifyContent='space-between'>
                  <Typography variant='body1' gutterBottom component='div'>
                    Måleenhet
                  </Typography>
                  <Typography variant='body1' gutterBottom component='div'>
                    {capitalizeFirst(row?.unit?.name ?? '')}
                  </Typography>
                </Box>
                {row?.variant === 'GOAL_BASED' && (
                  <Box display='flex' justifyContent='space-between'>
                    <Typography variant='body1' gutterBottom component='div'>
                      Mål
                    </Typography>
                    <Typography variant='body1' gutterBottom component='div'>
                      {row?.goal?.amount + ' ' + row?.goal?.suffix}
                    </Typography>
                  </Box>
                )}
                <Box display='flex' justifyContent='space-between'>
                  <Typography variant='body1' gutterBottom component='div'>
                    Starttidspunkt
                  </Typography>
                  <Typography variant='body1' gutterBottom component='div'>
                    {new Date(row.startDate).toLocaleString('no-NO', {
                      hour: '2-digit',
                      minute: '2-digit',
                      month: '2-digit',
                      day: '2-digit',
                      year: 'numeric',
                    })}
                  </Typography>
                </Box>

                <Box display='flex' justifyContent='space-between'>
                  <Typography variant='body1' gutterBottom component='div'>
                    Sluttidspunkt
                  </Typography>
                  <Typography variant='body1' gutterBottom component='div'>
                    {new Date(row.endDate).toLocaleString('no-NO', {
                      hour: '2-digit',
                      minute: '2-digit',
                      month: '2-digit',
                      day: '2-digit',
                      year: 'numeric',
                    })}
                  </Typography>
                </Box>
              </Stack>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};
