import { gql } from '@apollo/client';

export const EDIT_COMPETITION = gql`
  mutation EditCompetition($competition: CompetitionInput) {
    editCompetition(competition: $competition) {
      id
      teamId
      name
      startDate
      endDate
      category {
        id
        name
        suffix
      }
      unit {
        id
        name
        suffix
        sumLabel
        countLabel
        newRegistrationLabel
        icon
      }
      prizes {
        position
        description
      }
      competitionType
      goal {
        goalType
        suffix
        amount
      }
      status
    }
  }
`;
