import { gql } from '@apollo/client';

export const REGISTRATIONS = gql`
  query Registrations(
    $teamId: ID
    $userId: ID
    $unit: UnitInput
    $limit: Int
    $dateFrom: Date
    $dateTo: Date
  ) {
    registrations(
      teamId: $teamId
      userId: $userId
      unit: $unit
      limit: $limit
      dateFrom: $dateFrom
      dateTo: $dateTo
    ) {
      id
      value
      timestamp
      comment
      category {
        id
        name
        suffix
      }
    }
  }
`;
