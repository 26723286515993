import { useQuery } from '@apollo/client';
import { Box, CircularProgress, Paper, Typography } from '@mui/material';
import { LEADERBOARD } from 'graphql/queries/Registration/Leaderboard';
import React, { useEffect } from 'react';
import { useGlobalContext } from 'state/context/GlobalContext';
import { TeamMember } from 'types/team';
import { WidgetProps } from 'components/LayoutGrid/LayoutGrid';
import Lottie from 'react-lottie';
import { AvailableAnimations } from 'components/Animation/Animation';
import { displayName } from 'utils/Stringparser';
import { SportsScoreRounded } from '@mui/icons-material';
import NumberFormatter from 'utils/NumberFormatter';

export const CompetitionProgress: React.FC<WidgetProps> = ({
  competition,
  dateFilter,
  timeframe,
  widget,
  currentLayout,
}) => {
  const { currentTeam } = useGlobalContext();
  const getLimit = () => {
    let height = widget?.h ?? 0;
    let rows = currentLayout?.rows ?? 0;
    let heightFraction = height / rows;
    if (heightFraction < 0.3) {
      return 3;
    } else if (heightFraction < 0.4) {
      return 5;
    } else if (heightFraction < 0.6) {
      return 6;
    } else if (heightFraction < 0.8) {
      return 8;
    } else {
      return 10;
    }
  };

  const {
    loading,
    data: leaderboard,
    refetch: refreshLeaderboard,
  } = useQuery(LEADERBOARD, {
    variables: {
      teamId: currentTeam?.id,
      unit: competition?.unit,
      ...timeframe,
      sortBy: competition?.goal?.goalType ?? 'SALES_AMOUNT',
      limit: getLimit(),
    },
    pollInterval: 5000,
    onError(err) {
      console.error(err?.graphQLErrors);
    },
  });

  useEffect(() => {
    //setPreviousFilter(dateFilter);
    refreshLeaderboard();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentLayout]);

  const numbOfLanes = leaderboard?.leaderboard?.length ?? 0;
  const laneHeight = `${100 / numbOfLanes}%`;

  const renderWidth = (member: TeamMember) => {
    let goal = competition?.goal?.amount ?? 0;
    let maxValue = goal > 0 ? goal : leaderboard?.leaderboard?.[0]?.value ?? 0;
    let value = member.value ?? 0;
    return `${(value / maxValue) * 100}%`;
  };

  const isGoalReached = (value: number) => {
    let goal = competition?.goal?.amount;
    if (!goal || goal === 0) return false;
    else return value >= goal;
  };

  return (
    <Paper id={'test'} className='competition_progress'>
      <Typography className='competition_progress_title'>
        Status konkurranse
      </Typography>
      {!loading && leaderboard?.leaderboard ? (
        <>
          <Box
            sx={{
              height: '80%',
              display: 'flex',
            }}
          >
            <Box
              sx={{
                height: '100%',
                width: '100%',
                position: 'relative',
              }}
            >
              {[...leaderboard?.leaderboard]
                ?.sort((a: TeamMember, b: TeamMember) =>
                  ('' + displayName(a.userdata)).localeCompare(
                    displayName(b.userdata)
                  )
                )
                ?.map((x: TeamMember, index: number) => (
                  <Box
                    sx={{
                      height: laneHeight,
                      maxHeight: '33%',
                      p: 2,
                      display: 'flex',
                      /* maxHeight: 50, */
                      /*  mr: -2, */
                      borderTopStyle: 'solid',
                      borderWidth: 0.5,
                      borderColor: 'rgba(255, 231, 132, 0.5)',
                      width: '100%',
                    }}
                  >
                    <Box
                      sx={{
                        width: '20%',
                        pr: 2,
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <Typography
                        sx={{
                          color: /*  bgColors[index] ??  */ '#fff',
                          mr: 1,
                          fontWeight: 'bolder',
                        }}
                      >
                        {`${index + 1}. `}
                      </Typography>
                      <Typography
                        sx={{
                          color: '#fff',
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          fontSize: 12,
                        }}
                      >
                        {displayName(x.userdata)}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        height: '100%',
                        width: '70%',
                        display: 'flex',
                      }}
                    >
                      <Box
                        className={`${
                          isGoalReached(x.value ?? 0)
                            ? 'finished_animation'
                            : ''
                        }`}
                        sx={{
                          backgroundColor: x.userdata.color ?? '#ffe784',
                          width: renderWidth(x),
                          maxWidth: '100%',
                          height: '80%',
                          borderTopRightRadius: 5,
                          borderBottomRightRadius: 5,
                          position: 'relative',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <Lottie
                          height={50}
                          width={50}
                          style={{
                            position: 'absolute',
                            right: -40,
                            top: '50%',
                            transform: 'translateY(-50%)',
                          }}
                          options={{
                            loop: true,
                            autoplay: true,
                            animationData: AvailableAnimations['runner'],
                          }}
                        />
                      </Box>
                    </Box>
                    {isGoalReached(x.value ?? 0) && (
                      <Box
                        sx={{
                          width: '10%',
                          height: '100%',
                          display: 'flex',
                          justifyContent: 'flex-end',
                          alignItems: 'center',
                        }}
                      >
                        <Typography className='finished_text_animation'>
                          Mål nådd!
                        </Typography>
                      </Box>
                    )}
                  </Box>
                ))}
              {competition?.goal?.amount ? (
                <Box
                  sx={{
                    position: 'absolute',
                    /* width: '15%', */
                    height: '100%',
                    top: 0,
                    right: '9%',
                    borderLeftStyle: 'solid',
                    borderLeftColor: '#fff',
                    borderWidth: 0.5,
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'flex-start',
                    flexDirection: 'column',
                    alignSelf: 'center',
                  }}
                >
                  <SportsScoreRounded sx={{ color: '#fff' }} />
                  {/*  <EmojiEvents color='primary' sx={{ alignSelf: 'center' }} /> */}
                  <SportsScoreRounded sx={{ color: '#fff' }} />
                </Box>
              ) : null}
            </Box>
          </Box>
          <Box
            sx={{
              height: '10%',
              display: 'flex',
              width: '100%',
              justifyContent: 'flex-end',
              borderTopStyle: 'solid',
              borderColor: '#ffe784',
              borderWidth: 0.1,
              opacity: 0.5,
            }}
          >
            <Box
              sx={{
                width: '70%',
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
              }}
            >
              <Typography
                sx={{ fontSize: 12, color: '#fff', opacity: 0.5 }}
              >{`${NumberFormatter.format(
                competition?.goal?.amount !== 0
                  ? competition?.goal?.amount
                  : leaderboard?.leaderboard?.[0]?.value
              )}`}</Typography>
            </Box>
            <Box sx={{ width: '10%' }} />
          </Box>
        </>
      ) : (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            height: 300,
          }}
        >
          <CircularProgress color='secondary' />
        </div>
      )}
    </Paper>
  );
};
