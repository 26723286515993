import { Close, Preview, Refresh } from '@mui/icons-material';
import {
  Grid,
  Typography,
  IconButton,
  Box,
  Modal,
  MenuItem,
  Select,
  SelectChangeEvent,
  FormLabel,
  FormControl,
  Checkbox,
  Tooltip,
} from '@mui/material';
import React, { useCallback, useState, useMemo } from 'react';
import { useGlobalContext } from 'state/context/GlobalContext';
import { Competition, Layout, Settings } from 'types/team';
import { useMutation } from '@apollo/client';
import { DEFAULT_GRID, LayoutGrid } from 'components/LayoutGrid/LayoutGrid';
import { INCLUDE_LAYOUT } from 'graphql/mutations/Team/includeLayout';
import { EXCLUDE_LAYOUT } from 'graphql/mutations/Team/excludeLayout';
import { EDIT_SETTINGS } from 'graphql/mutations';
import {
  DataGridPro,
  GridActionsCellItem,
  GridColumns,
  GridRowOrderChangeParams,
} from '@mui/x-data-grid-pro';
import { useFrameContext } from 'state/context/FrameContext';
import { useAdminContext } from 'state/context/AdminContext';
import { useNotificationContext } from 'state/context/NoticifationContext';

type Row = {
  id: number;
  layoutId: string;
  layout: Layout;
  name: String;
  includeInLeaderboard: boolean;
};

export const MyLayouts: React.FC = () => {
  const { currentSettings, currentTeam, setSettings } = useGlobalContext();
  const { competitions } = useAdminContext();
  const { FrameStyle } = useFrameContext();
  const notifier = useNotificationContext();
  const [preview, setPreview] = useState<Layout | null | undefined>();
  const previewOpen = Boolean(preview);

  const [includeLayout] = useMutation(INCLUDE_LAYOUT);
  const [excludeLayout] = useMutation(EXCLUDE_LAYOUT);
  const [editSettings] = useMutation(EDIT_SETTINGS);

  const handlePreviewOpen = (layout: Layout) => () => {
    setPreview(layout);
  };

  const handlePreviewClose = () => {
    setPreview(null);
  };

  const handleIncludeLayout = (layoutId: string) => {
    includeLayout({
      variables: { teamId: currentTeam?.id, layoutId: layoutId },
    });
  };

  const handleExcludeLayout = (layoutId: string) => {
    excludeLayout({
      variables: { teamId: currentTeam?.id, layoutId: layoutId },
    });
  };

  const handleIncludeChecked =
    (layoutId: string) => (e: React.ChangeEvent, checked: boolean) => {
      if (checked) {
        setSettings({
          ...currentSettings,
          leaderboard: {
            ...currentSettings?.leaderboard,
            loop: [...(currentSettings?.leaderboard?.loop ?? []), layoutId],
          },
        });
        handleIncludeLayout(layoutId);
      } else {
        setSettings({
          ...currentSettings,
          leaderboard: {
            ...currentSettings?.leaderboard,
            loop: currentSettings?.leaderboard?.loop?.filter(
              (x) => x !== layoutId
            ),
          },
        });
        handleExcludeLayout(layoutId);
      }
    };

  const handleLoopTimeChange = (e: SelectChangeEvent<number | null>) => {
    let time = e.target.value;
    let newSettings = {
      ...currentSettings,
      leaderboard: { ...currentSettings?.leaderboard, loopTime: time },
    } as Settings;
    setSettings(newSettings);
    editSettings({
      variables: { teamId: currentTeam?.id, settings: newSettings },
    });
  };

  const handleLayoutOrderChange = useCallback(
    (params: GridRowOrderChangeParams) => {
      const newOrder = currentSettings?.layouts
        ? [...currentSettings?.layouts]
        : [];
      const layout = newOrder.splice(params.oldIndex, 1)[0];
      newOrder.splice(params.targetIndex, 0, layout);
      const newSettings: Settings = {
        ...currentSettings,
        layouts: newOrder,
        leaderboard: {
          ...currentSettings?.leaderboard,
          loop: newOrder
            .filter((x) => currentSettings?.leaderboard?.loop?.includes(x._id))
            ?.map((x) => x._id),
        },
      };

      setSettings(newSettings);
      editSettings({
        variables: { teamId: currentTeam?.id, settings: newSettings },
      });
    },
    [currentSettings, currentTeam?.id, editSettings, setSettings]
  );

  const Columns: GridColumns<Row> = [
    { field: 'name', headerName: 'Navn', flex: 2 },
    {
      field: 'show',
      headerName: 'Vis i leaderboard',
      type: 'actions',
      getActions: ({ row }) => [
        <Checkbox
          checked={row.includeInLeaderboard}
          onChange={handleIncludeChecked(row.layoutId)}
        />,
      ],
      flex: 1,
    },
    {
      field: 'preview',
      headerName: 'Forhåndsvis',
      type: 'actions',
      getActions: ({ row }) => [
        <GridActionsCellItem
          icon={<Preview color='primary' />}
          label='Forhåndsvis'
          onClick={handlePreviewOpen(row.layout)}
        />,
      ],
      flex: 1,
    },
  ];

  const Rows = useMemo(
    () =>
      currentSettings?.layouts?.map((layout: Layout, i: number) => ({
        id: i + 1,
        name: layout.name,
        layoutId: layout._id,
        layout: layout,
        includeInLeaderboard: Boolean(
          currentSettings?.leaderboard?.loop?.includes(layout?._id)
        ),
      })) ?? [],
    [currentSettings?.layouts, currentSettings?.leaderboard?.loop]
  );

  const handleRefreshLeaderboard = useCallback(() => {
    editSettings({
      variables: {
        teamId: currentTeam?.id,
        settings: {
          leaderboard: {
            ...currentSettings?.leaderboard,
            refresh: true,
          },
        },
      },
    })
      .then(() => {
        notifier.addAlert('refresh', {
          type: 'success',
          message: 'Leaderboardet er oppdatert',
          onClose: () => notifier.hideAlert('refresh'),
          autoHide: true,
          autoHideDuration: 3000,
        });
      })
      .catch((err) => {
        console.error(err);
        notifier.addAlert('refresh-failed', {
          type: 'error',
          title: 'Noe gikk galt',
          message: 'Kunne ikke oppdatere leaderboard',
          onClose: () => notifier.hideAlert('refresh-failed'),
          autoHide: true,
          autoHideDuration: 3000,
        });
      });
  }, [currentSettings?.leaderboard, currentTeam?.id, editSettings, notifier]);

  return (
    <Grid container spacing={3} className='admin-overview'>
      <Grid item xs={12}>
        <Box display='flex' alignItems='center'>
          <Typography variant='h4'>Tilpass layout</Typography>
          <Tooltip title='Oppdater layout/leaderboard'>
            <IconButton color='primary' onClick={handleRefreshLeaderboard}>
              <Refresh />
            </IconButton>
          </Tooltip>
        </Box>

        <Typography variant='caption'>
          Velg blant tilgjengelige layouts og legg dem til i ditt Leaderboard.
          <br />
          Dra og slipp for layouts for å endre rekkefølgen.
        </Typography>
      </Grid>
      <Grid item xs={12} sx={{ height: 'auto' }}>
        <DataGridPro
          rowReordering
          columns={Columns}
          rows={Rows}
          hideFooter
          autoHeight={true}
          isRowSelectable={() => false}
          onRowOrderChange={handleLayoutOrderChange}
          disableSelectionOnClick={true}
          disableColumnSelector={true}
        />
        <Modal
          open={previewOpen}
          onClose={handlePreviewClose}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          onBackdropClick={handlePreviewClose}
        >
          <Box
            style={{ marginLeft: FrameStyle.paddingLeft }}
            sx={{
              height: '90%',
              width: 'auto',
              borderColor: 'white',
              aspectRatio: '1098/1080',
            }}
            id='layout-container'
          >
            <IconButton
              sx={{ position: 'absolute', right: 10, top: 10 }}
              onClick={handlePreviewClose}
              color='primary'
              size='large'
            >
              <Close />
            </IconButton>
            <LayoutGrid
              widgets={
                preview?.widgets && preview?.widgets?.length > 0
                  ? preview?.widgets
                  : DEFAULT_GRID
              }
              rows={preview?.rows}
              cols={preview?.cols}
              data={{
                currentLayout: preview,
                empty: true,
                competition: competitions?.find(
                  (comp: Competition) => comp?.id === preview?.competition
                ),
              }}
            />
          </Box>
        </Modal>
      </Grid>
      <Grid item xs={12}>
        <FormControl>
          <FormLabel style={{ color: '#fff' }}>
            Visnignstid for hver layout
          </FormLabel>
          <Select
            value={currentSettings?.leaderboard?.loopTime}
            name='loopTime'
            onChange={handleLoopTimeChange}
          >
            <MenuItem value={15}>15 sek</MenuItem>
            <MenuItem value={30}>30 sek</MenuItem>
            <MenuItem value={60}>1min</MenuItem>
            <MenuItem value={60 * 5}>5min</MenuItem>
            <MenuItem value={60 * 15}>15min</MenuItem>
            <MenuItem value={60 * 30}>30min</MenuItem>
            <MenuItem value={60 * 60}>60min</MenuItem>
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );
};
