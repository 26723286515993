import { gql } from '@apollo/client';

export const EDIT_SETTINGS = gql`
  mutation EditSettings($teamId: ID, $settings: InputSettings) {
    editSettings(settings: $settings, teamId: $teamId) {
      layouts {
        name
      }
    }
  }
`;
