import {
  Badge,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from '@mui/material';
import { Add, ArrowDropDown, Edit } from '@mui/icons-material';
import React, { useState } from 'react';
import { useGlobalContext } from '../../state/context/GlobalContext';

import { useUserPageContext } from '../../state/context/UserPageContext';
import { RegistrationModal } from '../../components/widgets/DashboardWidgets/RegistrationModal';
import { DateFilter } from '../../types/leaderboard';

import { useNavigate } from 'react-router-dom';
import { routes } from '../routes';
import { ClassNames } from '@emotion/react';

import NumberFormatter from 'utils/NumberFormatter';
import { RecentSales } from 'components/widgets/DashboardWidgets/RecentSales';
import { TeamComparison } from 'components/widgets/DashboardWidgets/TeamComparison';
import { GoalProgress } from 'components/widgets/DashboardWidgets/GoalProgress';
import { Unit } from 'types/team';

import gold from 'assets/images/gold-medal.png';
import silver from 'assets/images/silver-medal.png';
import bronze from 'assets/images/bronze-medal.png';
import _ from 'lodash';
import { useFrameContext } from 'state/context/FrameContext';

export const DashboardPage: React.FC = () => {
  const { currentUser, displayName, currentTeam } = useGlobalContext();
  const { FrameStyle } = useFrameContext();
  const {
    setRegisterModal,
    dateFilter,
    setDateFilter,
    rank,
    total,
    loadingData,
    currentUnit,
    setCurrentUnit,
  } = useUserPageContext();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const navigate = useNavigate();

  const trophies = _.groupBy(currentUser?.trophies ?? [], 'name');
  return (
    <>
      <div className='dashboard'>
        <div
          className='dash--head'
          style={{ paddingTop: FrameStyle.paddingTop }}
        >
          <Typography className='name--of--username' variant='h1'>
            {displayName !== currentUser?.email
              ? displayName
              : 'Ingen brukernavn'}
            <IconButton
              color='primary'
              className='edit-button'
              size='small'
              onClick={() => navigate(routes.profile)}
            >
              <Edit />
            </IconButton>
          </Typography>
          <span className='user--email'>{currentUser?.email}</span>
          <div className='username--results'>
            <Grid item xs={4} className='left--results'>
              <h4 className='ranked'>Rangering</h4>
              <span className='ranged--nr'>
                {!loadingData ? (
                  rank && rank > 0 ? (
                    rank
                  ) : (
                    'ingen'
                  )
                ) : (
                  <CircularProgress color='primary' />
                )}
              </span>
            </Grid>
            <Grid item xs={4} className='center--results'></Grid>
            <Grid item xs={4} className='right--results'>
              <h4 className='points'>Totalt</h4>
              <span className='points--nr'>
                {!loadingData ? (
                  `${NumberFormatter.format(total)} ${currentUnit?.suffix}`
                ) : (
                  <CircularProgress color='primary' />
                )}
              </span>
            </Grid>
          </div>
        </div>
        <div className='dash--body'>
          <div className='user--body'>
            <Grid item xs={4}>
              <select
                className='totall--month'
                value={dateFilter ?? ''}
                onChange={(e) => {
                  setDateFilter(e.target.value as DateFilter);
                }}
              >
                <option value='MONTH'>Måned</option>
                <option value='WEEK'>Uke</option>
                <option value='LAST_MONTH'>Forrige måned</option>
                <option value='LAST_WEEK'>Forrige uke</option>
                <option value='YEAR'>Inneværende år</option>
                <option value='ALL_TIME'>Alle tider</option>
              </select>
              <div className='line'>
                <hr className='line1' />
                <hr className='line2' />
              </div>
            </Grid>
            <Grid item xs={4} className='square--grid'>
              <ClassNames>
                {({ css, cx }) => (
                  <div
                    className={cx(
                      'square',
                      css`
                        &:before {
                          content: '';
                          position: absolute;
                          width: 150%;
                          height: 150%;
                          top: -25%;
                          left: -25%;
                          z-index: -10000 !important;
                          background-image: url('${currentUser?.avatar ??
                          require('../../assets/images/user.jpg')}');
                          background-repeat: no-repeat;
                          background-size: cover;
                          background-position: center;
                          transform: rotate(-45deg);
                        }
                      `
                    )}
                  ></div>
                )}
              </ClassNames>

              {/* <Avatar alt="avatar" src={currentUser?.avatar ?? ""} /> */}
            </Grid>
            <Grid item xs={4}>
              <select
                className='totall--month'
                value={currentUnit?.name}
                onChange={(e) => {
                  let nextUnit = currentTeam?.units.find(
                    (unit) => unit.name === e.target.value
                  );
                  if (nextUnit) {
                    setCurrentUnit(nextUnit);
                  }
                }}
              >
                {currentTeam?.units?.map((unit) => {
                  return (
                    <option value={unit.name} key={unit?.id}>
                      {unit.name
                        ? unit?.name?.charAt(0).toUpperCase() +
                          unit?.name?.slice(1)
                        : ''}
                    </option>
                  );
                })}
              </select>
              <div className='line'>
                <hr className='line1' />
                <hr className='line2' />
              </div>
            </Grid>
          </div>
          <Grid container justifyContent='space-evenly' className='content'>
            <Grid item xs={12} md={8}>
              <Grid container justifyContent='space-evenly'>
                <Grid
                  item
                  md={4}
                  xs={5}
                  className='goal_progress_container_left'
                >
                  <GoalProgress
                    unit={currentUnit as Unit}
                    variant='SALES_AMOUNT'
                    datefilter={dateFilter ?? 'ALL_TIME'}
                  />
                </Grid>
                <Grid
                  item
                  xs={2}
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Button
                    variant='contained'
                    endIcon={<ArrowDropDown />}
                    onClick={(e) => setAnchorEl(e.currentTarget)}
                  >
                    {`Registrer`}
                  </Button>
                  <Menu
                    anchorEl={anchorEl}
                    open={anchorEl ? true : false}
                    onClose={() => setAnchorEl(null)}
                  >
                    {currentTeam?.units?.map((unit) => {
                      return (
                        <MenuItem
                          key={unit?.id}
                          onClick={() => {
                            setAnchorEl(null);
                            setRegisterModal(unit);
                          }}
                        >
                          <Add />
                          {unit.name
                            ? unit?.name?.charAt(0).toUpperCase() +
                              unit?.name?.slice(1)
                            : ''}
                        </MenuItem>
                      );
                    })}
                  </Menu>
                </Grid>
                <Grid
                  item
                  md={4}
                  xs={5}
                  className='goal_progress_container_right'
                >
                  <GoalProgress
                    unit={currentUnit as Unit}
                    variant='SALES_COUNT'
                    datefilter={dateFilter ?? 'ALL_TIME'}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={10}>
              <Grid container justifyContent='space-evenly'>
                <Grid item xs={6}>
                  <RecentSales />
                </Grid>
                <Grid item xs={6}>
                  <TeamComparison />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Typography variant='h4' color='primary' textAlign='center'>
                Utmerkelser
              </Typography>
              <Grid
                container
                justifyContent='center'
                sx={{ marginTop: 5, marginBottom: 5 }}
              >
                <Grid item xs={3} display='flex' justifyContent='center'>
                  <Tooltip
                    title={`${
                      trophies?.['gold-medal']?.length ?? 0
                    } førsteplasser`}
                  >
                    <Badge
                      badgeContent={trophies?.['gold-medal']?.length ?? 0}
                      color='primary'
                      sx={{ width: '50%' }}
                      showZero
                    >
                      <img
                        src={gold}
                        alt='gold'
                        style={{ width: '100%', height: 'auto' }}
                      />
                    </Badge>
                  </Tooltip>
                </Grid>
                <Grid item xs={3} display='flex' justifyContent='center'>
                  <Tooltip
                    title={`${
                      trophies?.['silver-medal']?.length ?? 0
                    } andreplasser`}
                  >
                    <Badge
                      badgeContent={trophies?.['silver_medal']?.length ?? 0}
                      color='primary'
                      sx={{ width: '50%' }}
                      showZero
                    >
                      <img
                        src={silver}
                        alt='silver'
                        style={{ width: '100%', height: 'auto' }}
                      />
                    </Badge>
                  </Tooltip>
                </Grid>
                <Grid item xs={3} display='flex' justifyContent='center'>
                  <Tooltip
                    title={`${
                      trophies?.['bronze-medal']?.length ?? 0
                    } tredjeplasser`}
                  >
                    <Badge
                      badgeContent={trophies?.['bronze_medal']?.length ?? 0}
                      color='primary'
                      sx={{ width: '50%' }}
                      showZero
                    >
                      <img
                        src={bronze}
                        alt='bronze'
                        style={{ width: '100%', height: 'auto' }}
                      />
                    </Badge>
                  </Tooltip>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
        <RegistrationModal />
      </div>
    </>
  );
};
